import { computed, Ref } from 'vue';
import { OrderSide } from '../types';
import { isNumber } from '../utils';

export const useTPSLValidation = ({
  side,
  tpAsPercentage,
  slAsPercentage,
  price,
}: {
  side: Ref<OrderSide>;
  tpAsPercentage: Ref<boolean>;
  slAsPercentage: Ref<boolean>;
  price: Ref<number>;
}) => {
  const tpMin = computed(() => {
    if (side.value === OrderSide.Buy) {
      return tpAsPercentage.value ? 0 : price.value;
    }

    return tpAsPercentage.value ? 0 : null;
  });

  const tpMax = computed(() => {
    if (side.value === OrderSide.Buy) {
      return null;
    }

    return tpAsPercentage.value ? null : price.value;
  });

  const slMax = computed(() => {
    if (side.value === OrderSide.Buy) {
      return slAsPercentage.value ? null : price.value;
    }

    return null;
  });

  const slMin = computed(() => {
    if (side.value === OrderSide.Buy) {
      return slAsPercentage.value ? 0 : null;
    }

    return slAsPercentage.value ? 0 : price.value;
  });

  const validate = (value: number, min: number, max: number) => {
    if (!isNumber(value)) {
      return true;
    }

    if (isNumber(min)) {
      return value >= min;
    }

    if (isNumber(max)) {
      return value <= max;
    }

    return true;
  };

  const validateTP = (value: number) => {
    return validate(value, tpMin.value, tpMax.value);
  };

  const validateSL = (value: number) => {
    return validate(value, slMin.value, slMax.value);
  };

  return {
    tpMin,
    tpMax,
    slMin,
    slMax,
    validateSL,
    validateTP,
  };
};
