<script setup lang="ts">
import { useElementVisibility } from '@vueuse/core';
import { ref, watch } from 'vue';

const target = ref<HTMLDivElement>();
const isVisible = useElementVisibility(target, {
  threshold: 1,
});

const visible = defineModel({
  type: Boolean,
  required: true,
});

watch(
  isVisible,
  (isVisible) => {
    visible.value = isVisible;
  },
  { immediate: true },
);
</script>

<template>
  <div
    ref="target"
    :class="{
      invisible: !isVisible,
    }"
  >
    <slot></slot>
  </div>
</template>
