import { AccountDTO, AccountInfo, AccountInfoDTO } from '@/margin/types';
import { getToken, post } from '@/margin/api';

export const getUserAccounts = () => {
  return post<AccountDTO[]>(
    '/margin/account/list/',
    {
      token: getToken(),
    },
    {
      retryCount: 10,
    },
  );
};

export const getAccountInfo = async (
  accountId: number,
): Promise<AccountInfo> => {
  const data = await post<AccountInfoDTO>(
    '/margin/account/info/',
    {
      token: getToken(),
      account_id: accountId?.toString(),
    },
    {
      retryCount: 10,
    },
  );

  return {
    id: data.id,
    marginLevel: data.margin_level,
    margin: data.margin,
    equity: data.equity,
    balance: data.balance,
    leverage: data.leverage,
    currency: data.currency,
    profit: data.profit,
    precision: data.precision,
    isFiatOrStable: ['USD', 'USDT', 'EUR'].includes(data.currency),
  };
};
