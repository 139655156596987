<template>
  <svg
    width="13"
    height="13"
    viewBox="0 0 13 13"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M6.5 0.833252C6.72059 0.833252 6.92204 0.949751 7.01968 1.13377L8.67576 4.25507L12.3793 4.75868C12.5975 4.78836 12.7787 4.9307 12.8467 5.12588C12.9148 5.32107 12.8578 5.53525 12.6998 5.67842L10.0205 8.1063L10.6528 11.5363C10.6901 11.7386 10.6007 11.943 10.4222 12.0636C10.2437 12.1843 10.0071 12.2001 9.81183 12.1046L6.5 10.4843L3.18817 12.1046C2.99291 12.2001 2.75629 12.1843 2.5778 12.0636C2.39931 11.943 2.30992 11.7386 2.34722 11.5363L2.97955 8.1063L0.300194 5.67842C0.1422 5.53525 0.0852464 5.32107 0.153272 5.12588C0.221297 4.9307 0.402509 4.78836 0.620742 4.75868L4.32424 4.25507L5.98032 1.13377C6.07796 0.949751 6.27941 0.833252 6.5 0.833252Z"
      fill="currentColor"
    />
  </svg>
</template>
