import { OrderBook } from '@/margin/types';
import { OrderBookCallback, orderBook } from '../utils/orderBook';

let lastCallback: OrderBookCallback;
let lastSymbol: string;

export const connectToOrderBookSocket = async (
  symbol: string,
  onValue: (data: OrderBook) => void,
) => {
  closeOrderBookSocket();

  lastCallback = onValue;
  lastSymbol = symbol;

  orderBook.on(symbol, onValue);
};

export const closeOrderBookSocket = () => {
  if (lastSymbol && lastCallback) {
    orderBook.off(lastSymbol, lastCallback);
  }
};
