import { Ref, ref, watch } from 'vue';
import { getPairPrecision } from '../utils/coin';

export const usePrecision = (symbol: Ref<string>) => {
  const precision = ref<number>(2);

  watch(
    symbol,
    async (symbol) => {
      if (!symbol) return;

      precision.value = (await getPairPrecision(symbol)).precision;
    },
    {
      immediate: true,
    },
  );

  return precision;
};
