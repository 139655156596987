import { LayoutPreference, WidgetType } from '@/margin/types';
import { Layout } from 'grid-layout-plus';
import { Module } from 'vuex';
import { toJSON } from '@/margin/utils';
import { StoreState } from '@/margin/store/index.ts';

export interface LayoutState {
  layouts: {
    xl: Layout;
    lg: Layout;
    md: Layout;
    sm: Layout;
    xs: Layout;
  };
}

const PREFERENCE_LAYOUT_KEY = 'user-layout';
const CUSTOMIZABLE_LAYOUT: Layout = [
  { i: WidgetType.Ticker, x: 0, y: 0, w: 24, h: 3, minH: 3, maxH: 3, minW: 12 },
  {
    i: WidgetType.Trading,
    x: 0,
    y: 3,
    w: 15,
    h: 20,
    minH: 12,
    maxH: 32,
    minW: 8,
  },
  {
    i: WidgetType.OrderBook,
    x: 15,
    y: 3,
    w: 4,
    h: 20,
    minH: 12,
    maxH: 32,
    minW: 4,
  },
  {
    i: WidgetType.MarketOrder,
    x: 19,
    y: 3,
    w: 5,
    h: 32,
    minH: 11,
    maxH: 32,
    minW: 5,
  },
  {
    i: WidgetType.Orders,
    x: 0,
    y: 35,
    w: 19,
    h: 12,
    minH: 12,
    maxH: 32,
    minW: 12,
  },
];

const getLayoutPreference = (): Layout => {
  const preferenceJSON = localStorage.getItem(PREFERENCE_LAYOUT_KEY);
  const preference = toJSON<LayoutPreference[]>(preferenceJSON);

  if (!preference) {
    return CUSTOMIZABLE_LAYOUT;
  }

  return CUSTOMIZABLE_LAYOUT.map((item) => {
    const layoutItem = preference.find((p) => p.i === item.i);
    return layoutItem ? { ...item, ...layoutItem } : item;
  });
};

export const LayoutModule: Module<LayoutState, StoreState> = {
  namespaced: true,
  state: {
    layouts: {
      xl: getLayoutPreference(),
      lg: [
        { i: WidgetType.Ticker, x: 0, y: 0, w: 24, h: 3, static: true },
        { i: WidgetType.OrderBook, x: 0, y: 3, w: 12, h: 20, static: true },
        { i: WidgetType.MarketOrder, x: 12, y: 3, w: 12, h: 20, static: true },
        { i: WidgetType.Trading, x: 0, y: 23, w: 24, h: 12, static: true },
        { i: WidgetType.Orders, x: 0, y: 35, w: 24, h: 12, static: true },
      ],
      md: [
        { i: WidgetType.Ticker, x: 0, y: 0, w: 24, h: 3, static: true },
        { i: WidgetType.OrderBook, x: 0, y: 3, w: 12, h: 20, static: true },
        { i: WidgetType.MarketOrder, x: 12, y: 3, w: 12, h: 20, static: true },
        { i: WidgetType.Trading, x: 0, y: 23, w: 24, h: 12, static: true },
        { i: WidgetType.Orders, x: 0, y: 35, w: 24, h: 12, static: true },
      ],
      sm: [
        { i: WidgetType.Ticker, x: 0, y: 0, w: 24, h: 3, static: true },
        { i: WidgetType.OrderBook, x: 0, y: 3, w: 12, h: 20, static: true },
        { i: WidgetType.MarketOrder, x: 12, y: 3, w: 12, h: 20, static: true },
        { i: WidgetType.Trading, x: 0, y: 23, w: 24, h: 12, static: true },
        { i: WidgetType.Orders, x: 0, y: 35, w: 24, h: 12, static: true },
      ],
      xs: [
        { i: WidgetType.Ticker, x: 0, y: 0, w: 24, h: 3, static: true },
        { i: WidgetType.OrderBook, x: 0, y: 3, w: 24, h: 10, static: true },
        { i: WidgetType.MarketOrder, x: 0, y: 13, w: 24, h: 20, static: true },
        { i: WidgetType.Trading, x: 0, y: 33, w: 24, h: 10, static: true },
        { i: WidgetType.Orders, x: 0, y: 43, w: 24, h: 16, static: true },
      ],
    },
  },
  actions: {
    updateLayout(_, layout: Layout) {
      const userLayout = layout.map<LayoutPreference>((item) => ({
        i: item.i as WidgetType,
        x: item.x,
        y: item.y,
        w: item.w,
        h: item.h,
      }));

      localStorage.setItem(PREFERENCE_LAYOUT_KEY, JSON.stringify(userLayout));
    },
  },
};
