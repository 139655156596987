<script lang="ts" setup>
import Dialog from 'primevue/dialog';
import { PropType } from 'vue';
import IconWarning from '../Icon/IconWarning.vue';

defineProps({
  title: {
    type: String,
    default: '',
  },
  variant: {
    type: String as PropType<'default' | 'confirmation'>,
    default: 'default',
  },
  contentPaddingSmaller: {
    type: Boolean,
    default: false,
  },
});

const visible = defineModel({
  type: Boolean,
  default: false,
});
</script>

<template>
  <Dialog
    v-model:visible="visible"
    modal
    block-scroll
    :draggable="false"
    :pt="{
      mask: 'bg-m-black/60',
      root: `bg-m-gray-700 ${variant === 'confirmation' ? 'border-2' : 'border-4'} border-m-gray-600 max-h-[90%] overflow-auto`,
      header: `md:px-9 md:pt-6 px-4 pt-4 flex items-center justify-between ${variant === 'confirmation' ? '' : 'border-b pb-4'} border-m-gray-600`,
      content:
        variant === 'confirmation'
          ? `md:px-8 px-4 pt-2 md:pb-8 pb-4`
          : `md:px-9 px-4 ${contentPaddingSmaller ? 'pt-2 pb-4' : 'py-4'}`,
      closeButton: `text-m-gray-400 ${variant === 'confirmation' ? 'hidden' : ''}`,
    }"
    class="w-[90%] max-w-[500px] font-encode"
  >
    <template #header>
      <div
        :class="[
          {
            'flex w-full flex-col items-center gap-3 text-center':
              variant === 'confirmation',
          },
        ]"
      >
        <div
          v-if="variant === 'confirmation'"
          class="flex h-16 w-16 items-center justify-center overflow-hidden rounded-full bg-m-gray-600"
        >
          <IconWarning class="h-6 w-6 text-m-white" />
        </div>
        <h4 class="max-w-[260px] font-bold">
          <slot name="title">{{ title }}</slot>
        </h4>
      </div>
    </template>
    <slot></slot>
  </Dialog>
</template>
