import { Ref, computed, watch } from 'vue';
import { OrderSide } from '../types';
import {
  toTPSLRoe,
  toTPSLPrice,
  toTPSLReturn,
  toTPSLRoeReturn,
} from '../utils/orderForm';
import { isNonZeroNumber, isNumber } from '../utils';

export const useOrderForm = ({
  tpOrRoe,
  tpAsPercentage,
  slOrRoe,
  slAsPercentage,
  price,
  size,
  side,
  margin,
}: {
  tpOrRoe: Ref<number>;
  tpAsPercentage: Ref<boolean>;
  slOrRoe: Ref<number>;
  slAsPercentage: Ref<boolean>;
  price: Ref<number>;
  size: Ref<number>;
  side: Ref<OrderSide>;
  margin: Ref<number>;
}) => {
  const tpExpectedReturn = computed(() =>
    tpAsPercentage.value
      ? toTPSLRoeReturn(tpOrRoe.value, margin.value, false)
      : toTPSLReturn(tpOrRoe.value, price.value, size.value, side.value),
  );

  const slExpectedReturn = computed(() =>
    slAsPercentage.value
      ? toTPSLRoeReturn(slOrRoe.value, margin.value, true)
      : toTPSLReturn(slOrRoe.value, price.value, size.value, side.value),
  );

  const tpPrice = computed(() =>
    tpAsPercentage.value
      ? toTPSLPrice(
          tpOrRoe.value,
          price.value,
          margin.value,
          size.value,
          side.value,
          false,
        )
      : tpOrRoe.value,
  );

  const slPrice = computed(() =>
    slAsPercentage.value
      ? toTPSLPrice(
          slOrRoe.value,
          price.value,
          margin.value,
          size.value,
          side.value,
          true,
        )
      : slOrRoe.value,
  );

  watch(tpAsPercentage, (asPercentage) => {
    if (!isNumber(tpOrRoe.value) || !isNonZeroNumber(price.value)) return;

    tpOrRoe.value = asPercentage
      ? toTPSLRoe(
          tpOrRoe.value,
          price.value,
          margin.value,
          size.value,
          side.value,
          false,
        )
      : toTPSLPrice(
          tpOrRoe.value,
          price.value,
          margin.value,
          size.value,
          side.value,
          false,
        );
  });

  watch(slAsPercentage, (asPercentage) => {
    if (!isNumber(slOrRoe.value) || !isNonZeroNumber(price.value)) return;

    slOrRoe.value = asPercentage
      ? toTPSLRoe(
          slOrRoe.value,
          price.value,
          margin.value,
          size.value,
          side.value,
          true,
        )
      : toTPSLPrice(
          slOrRoe.value,
          price.value,
          margin.value,
          size.value,
          side.value,
          true,
        );
  });

  return {
    tpExpectedReturn,
    slExpectedReturn,
    tpPrice,
    slPrice,
  };
};
