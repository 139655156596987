<script setup lang="ts">
import KitChange from '@/margin/components/Kit/KitChange.vue';
import { Pair } from '@/margin/utils/trading.ts';
import { computed, PropType, ref } from 'vue';
import KitFullyVisible from '@/margin/components/Kit/KitFullyVisible.vue';
import IconInfo from '@/margin/components/Icon/IconInfo.vue';
import KitOverlay from '@/margin/components/Kit/KitOverlay.vue';
import IconSettings from '@/margin/components/Icon/IconSettings.vue';

defineProps({
  pair: {
    type: Object as PropType<Pair>,
    required: true,
  },
  lastPrice: {
    type: Number,
    required: true,
  },
  change: {
    type: Number,
    required: true,
  },
  changePercentage: {
    type: Number,
    required: true,
  },
  high: {
    type: Number,
    required: true,
  },
  low: {
    type: Number,
    required: true,
  },
  volume: {
    type: Number,
    required: true,
  },
  swap: {
    type: Number,
    required: true,
  },
  precision: {
    type: Number,
    default: 2,
  },
  vertical: Boolean,
});

defineEmits(['settings']);

const overlay = ref<InstanceType<typeof KitOverlay>>();

const lastPriceVisible = ref(true);
const changeVisible = ref(true);
const highVisible = ref(true);
const lowVisible = ref(true);
const volumeVisible = ref(true);
const financingRateVisible = ref(true);

const showTooltip = computed(() =>
  [
    lastPriceVisible.value,
    changeVisible.value,
    highVisible.value,
    lowVisible.value,
    volumeVisible.value,
    financingRateVisible.value,
  ].includes(false),
);
</script>

<template>
  <div class="flex w-full items-center justify-between gap-2 overflow-hidden">
    <div
      :class="[
        'flex w-full overflow-hidden text-xs',
        {
          'flex-col gap-4': vertical,
          'gap-8': !vertical,
        },
      ]"
    >
      <KitFullyVisible v-model="lastPriceVisible" class="flex flex-col">
        <span>{{ $t('ticker.info.lastPrice') }}</span>
        <span
          :style="{
            width:
              `${$num(lastPrice, {
                minimumFractionDigits: precision,
                maximumFractionDigits: precision,
              })}${pair?.name.quote ?? ''}`.length + 'ch',
          }"
        >
          <b>{{
            $num(lastPrice, {
              minimumFractionDigits: precision,
              maximumFractionDigits: precision,
            })
          }}</b>
          {{ pair?.name.quote }}
        </span>
      </KitFullyVisible>

      <KitFullyVisible v-model="changeVisible" class="flex flex-col">
        <span>{{ $t('ticker.info.24hChange') }}</span>
        <KitChange
          class="font-bold"
          :value="changePercentage"
          :suffix="`% (${$num(change, precision)})`"
        />
      </KitFullyVisible>

      <KitFullyVisible v-model="highVisible" class="flex flex-col">
        <span>{{ $t('ticker.info.24hHigh') }}</span>
        <b>{{ $num(high, 'auto') }}</b>
      </KitFullyVisible>

      <KitFullyVisible v-model="lowVisible" class="flex flex-col">
        <span>{{ $t('ticker.info.24hLow') }}</span>
        <b>{{ $num(low, 'auto') }}</b>
      </KitFullyVisible>

      <KitFullyVisible v-model="volumeVisible" class="flex flex-col">
        <span>{{ $t('ticker.info.24hVolume') }} ({{ pair?.name.base }})</span>
        <b>{{ $num(volume, 'auto') }}</b>
      </KitFullyVisible>

      <KitFullyVisible v-model="financingRateVisible" class="flex flex-col">
        <span>{{ $t('ticker.info.financingRate') }}</span>
        <b>{{ $t('swap', { swap: $num(swap, 5) }) }}</b>
      </KitFullyVisible>
    </div>

    <div class="flex justify-end gap-2">
      <div
        v-if="showTooltip"
        @mouseenter="overlay.show($event)"
        @mouseleave="overlay.hide()"
      >
        <IconInfo class="size-5" />
      </div>

      <IconSettings class="size-5 cursor-pointer" @click="$emit('settings')" />
    </div>
  </div>

  <KitOverlay ref="overlay">
    <div class="flex flex-col gap-4 text-xs">
      <div v-if="!lastPriceVisible" class="flex flex-col">
        <span>{{ $t('ticker.info.lastPrice') }}</span>
        <span>
          <b>{{
            $num(lastPrice, {
              minimumFractionDigits: precision,
              maximumFractionDigits: precision,
            })
          }}</b>
          {{ pair?.name.quote }}
        </span>
      </div>

      <div v-if="!changeVisible" class="flex flex-col">
        <span>{{ $t('ticker.info.24hChange') }}</span>
        <KitChange
          class="font-bold"
          :value="changePercentage"
          :suffix="`% (${$num(change, precision)})`"
        />
      </div>

      <div v-if="!highVisible" class="flex flex-col">
        <span>{{ $t('ticker.info.24hHigh') }}</span>
        <b>{{ $num(high, 'auto') }}</b>
      </div>

      <div v-if="!lowVisible" class="flex flex-col">
        <span>{{ $t('ticker.info.24hLow') }}</span>
        <b>{{ $num(low, 'auto') }}</b>
      </div>

      <div v-if="!volumeVisible" class="flex flex-col">
        <span>{{ $t('ticker.info.24hVolume') }} ({{ pair?.name.base }})</span>
        <b>{{ $num(volume, 'auto') }}</b>
      </div>

      <div v-if="!financingRateVisible" class="flex flex-col">
        <span>{{ $t('ticker.info.financingRate') }}</span>
        <b>{{ $t('swap', { swap: $num(swap, 5) }) }}</b>
      </div>
    </div>
  </KitOverlay>
</template>
