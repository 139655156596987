<script setup lang="ts">
import { PropType, watch } from 'vue';
import KitSearch from '../Kit/KitSearch.vue';
import { Option } from '@/margin/types';
import KitDropdown from '../Kit/KitDropdown.vue';
import KitDatePicker from '../Kit/KitDatePicker.vue';
import { DateRangeType, getDateRange } from '@/margin/utils/dateRange.ts';
import { useI18n } from 'vue-i18n';

const { t } = useI18n();

defineProps({
  filterChanged: {
    type: Boolean,
    required: true,
  },
});

defineEmits(['reset']);

const search = defineModel('search', {
  type: String,
  required: true,
});

const range = defineModel('range', {
  type: Number as PropType<DateRangeType>,
  required: true,
});

const from = defineModel('from', {
  type: Date as PropType<Date>,
  required: true,
});

const to = defineModel('to', {
  type: Date as PropType<Date>,
  required: true,
});

const options: Option<DateRangeType>[] = [
  {
    label: t('orders.history.option.today'),
    value: DateRangeType.Today,
  },
  {
    label: t('orders.history.option.yesterday'),
    value: DateRangeType.Yesterday,
  },
  {
    label: t('orders.history.option.lastWeek'),
    value: DateRangeType.LastWeek,
  },
  {
    label: t('orders.history.option.lastMonth'),
    value: DateRangeType.LastMonth,
  },
  {
    label: t('orders.history.option.last3Months'),
    value: DateRangeType.Last3Months,
  },
  {
    label: t('orders.history.option.last6Months'),
    value: DateRangeType.Last6Months,
  },
  {
    label: t('orders.history.option.lastYear'),
    value: DateRangeType.LastYear,
  },
  {
    label: t('orders.history.option.allTime'),
    value: DateRangeType.AllTime,
  },
];

watch(range, (range) => {
  const dateRange = getDateRange(range);

  from.value = dateRange.from;
  to.value = dateRange.to;
});
</script>

<template>
  <div class="@container/filter">
    <div
      class="grid grid-cols-2 items-center gap-2 @xl/filter:grid-cols-[minmax(auto,180px)_minmax(auto,140px)_minmax(auto,140px)_minmax(auto,140px)_1fr] @2xl/filter:gap-4"
    >
      <KitSearch
        v-model="search"
        :placeholder="$t('orders.history.option.search')"
        size="small"
      />

      <KitDropdown
        v-model="range"
        :options="options"
        variant="secondary"
        size="small"
      />

      <KitDatePicker v-model="from" :label="$t('orders.history.option.from')" />
      <KitDatePicker v-model="to" :label="$t('orders.history.option.to')" />
      <button
        class="w-fit cursor-pointer whitespace-nowrap text-sm disabled:text-gray-400"
        :disabled="!filterChanged"
        @click="$emit('reset')"
      >
        {{ $t('orders.history.option.resetAll') }}
      </button>
    </div>
  </div>
</template>
