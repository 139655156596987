<script setup lang="ts">
import KitButton from '@/margin/components/Kit/KitButton.vue';
import IconArrowRight from '@/margin/components/Icon/IconArrowRight.vue';
import IconArrowLeft from '@/margin/components/Icon/IconArrowLeft.vue';
import { computed } from 'vue';

const props = defineProps({
  total: {
    type: Number,
    required: true,
  },
  shown: {
    type: Number,
    required: true,
  },
  page: {
    type: Number,
    required: true,
  },
  totalPages: {
    type: Number,
    required: true,
  },
});

defineEmits(['next', 'previous']);

const nextDisabled = computed(() => props.page >= props.totalPages);
const previousDisabled = computed(() => props.page <= 1);
</script>

<template>
  <div
    class="flex w-full items-center justify-between gap-2 border-t border-m-gray-600 p-2 text-xs text-m-gray-400 @container/main"
  >
    <div class="">
      Shown {{ shown }} records from <b>{{ total }}</b>
    </div>
    <div class="flex justify-end gap-2 @md/main:justify-center @md/main:gap-4">
      <button
        :disabled="previousDisabled"
        class="border border-m-gray-600 p-2 hover:bg-m-gray-600 disabled:cursor-not-allowed disabled:bg-m-gray-500"
        @click="$emit('previous')"
      >
        <IconArrowLeft />
      </button>

      <KitButton
        visible-arrow-right
        variant="primary"
        size="small"
        fit
        class="whitespace-nowrap"
        :disabled="nextDisabled"
        @click="$emit('next')"
      >
        Next Page
        <template #suffix>
          <IconArrowRight class="h-4 w-4" />
        </template>
      </KitButton>
    </div>
    <div class="hidden shrink-0 items-center justify-end gap-2 @md/main:flex">
      Page
      <div
        class="border-2 border-m-gray-600 bg-m-gray-500 px-3 py-1 text-sm font-semibold text-m-white"
      >
        {{ page }}
      </div>
      of {{ totalPages }}
    </div>
  </div>
</template>
