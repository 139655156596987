<template>
  <TheMargin ref="margin" :settings="settings" />
</template>

<script lang="ts" setup>
import TheMargin from '@/margin/TheMargin.vue';
import { onMounted, onUnmounted, ref } from 'vue';
import { MarginSettings } from './margin/types';

const settings = ref<MarginSettings>();
const margin = ref<InstanceType<typeof TheMargin>>();

const onParentMessage = (event: MessageEvent) => {
  const data = event.data?.data;
  const type = event.data?.type;

  if (type === 'locale') {
    margin.value.changeLocale(data);
  }

  // TODO: Remove after migration
  if (type === 'token') {
    console.warn('Type "token" is deprecated');
    settings.value = {
      token: data,
    };
  }

  if (type === 'init') {
    settings.value = {
      token: data?.token,
      preferAccount: data?.preferAccount,
      locale: data?.locale,
    };
  }
};

onMounted(() => {
  window.addEventListener('message', onParentMessage);

  const defaultToken = import.meta.env.VITE_DEFAULT_DEVELOPMENT_TOKEN;

  if (defaultToken) {
    window.postMessage(
      {
        type: 'init',
        data: {
          token: defaultToken,
        },
      },
      '*',
    );
  }
});

onUnmounted(() => {
  window.removeEventListener('message', onParentMessage);
});
</script>
