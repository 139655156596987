<script setup lang="ts">
import { computed, onMounted, ref, watch } from 'vue';
import { Tab } from '@/margin/types';
import KitTabs from '../Kit/KitTabs.vue';
import OrdersTablePositions from '../Orders/OrdersTablePositions.vue';
import OrdersTableOrders from '../Orders/OrdersTableOrders.vue';
import OrdersAnonymous from '@/margin/components/Orders/OrdersAnonymous.vue';
import OrdersTableHistory from '@/margin/components/Orders/OrdersTableHistory.vue';
import { useStore } from '@/margin/store';
import { useI18n } from 'vue-i18n';

const store = useStore();
const { t } = useI18n();

enum TabId {
  Position = 'position',
  Orders = 'orders',
  History = 'history',
}

const tabs = computed<Tab[]>(() => [
  {
    id: TabId.Position,
    title: `${t('orders.tab.positions')} (${store.state.positions.positions.length})`,
  },
  {
    id: TabId.Orders,
    title: `${t('orders.tab.orders')} (${store.state.orders.orders.length})`,
  },
  {
    id: TabId.History,
    title: t('orders.tab.history'),
  },
]);

const loadData = () => {
  if (store.state.auth.isAuthenticated) {
    store.dispatch('orders/loadOrders');
    store.dispatch('positions/loadPositions');
  } else {
    store.dispatch('orders/clearOrders');
    store.dispatch('positions/clearPositions');
  }
};

onMounted(loadData);
watch(() => store.state.auth.account?.id, loadData);

const tab = ref(TabId.Position);
</script>

<template>
  <div class="flex h-full flex-col px-2 py-2">
    <KitTabs v-model="tab" :tabs="tabs" />

    <div
      v-if="$store.state.auth.isAuthenticated"
      class="mt-4 h-full overflow-hidden"
    >
      <OrdersTablePositions v-if="tab === TabId.Position" />
      <OrdersTableOrders v-if="tab === TabId.Orders" />
      <OrdersTableHistory v-if="tab === TabId.History" />
    </div>
    <OrdersAnonymous v-else class="mt-12" />
  </div>
</template>
