<script setup lang="ts">
import Dialog from 'primevue/dialog';
import IconCheck from '@/margin/components/Icon/IconCheck.vue';
import { onUnmounted, PropType, ref, watch } from 'vue';
import IconCloseButton from '@/margin/components/Icon/IconCloseButton.vue';
import IconWarning from '@/margin/components/Icon/IconWarning.vue';

const props = defineProps({
  duration: {
    type: Number,
    default: 5_000,
  },
  title: {
    type: String,
    default: '',
  },
  description: {
    type: String,
    default: '',
  },
  type: {
    type: String as PropType<'success' | 'danger'>,
    default: 'success',
  },
});

const visible = defineModel({
  type: Boolean,
  default: false,
});

const timeoutId = ref<number>();

watch(visible, () => {
  if (visible.value) {
    timeoutId.value = setTimeout(() => {
      visible.value = false;
    }, props.duration);
  } else if (timeoutId.value) {
    clearTimeout(timeoutId.value);
  }
});

onUnmounted(() => {
  if (timeoutId.value) {
    clearTimeout(timeoutId.value);
  }
});
</script>

<template>
  <Dialog
    v-model:visible="visible"
    :show-header="false"
    :draggable="false"
    position="top"
    :pt="{
      mask: '',
      root: `${type === 'success' ? 'bg-m-success-100' : 'bg-m-danger'} border-m-gray-600 my-[7%]`,
      content: 'p-4',
    }"
    class="w-[90%] max-w-[415px] font-encode"
  >
    <div class="flex gap-2">
      <div>
        <div
          class="flex h-6 w-6 items-center justify-center rounded-full bg-m-white"
        >
          <IconCheck
            v-if="type === 'success'"
            class="h-3 w-3 text-m-success-100"
          />
          <IconWarning v-if="type === 'danger'" class="h-3 w-3 text-m-danger" />
        </div>
      </div>
      <div class="w-full">
        <div class="text-sm font-semibold">{{ title }}</div>
        <div v-if="description" class="text-xs">
          {{ description }}
        </div>
      </div>
      <button class="self-center" @click="visible = false">
        <IconCloseButton class="h-5 w-5" />
      </button>
    </div>
  </Dialog>
</template>
