<script setup lang="ts">
import { PropType, watch, ref, onMounted } from 'vue';
import KitChange from './KitChange.vue';
import IconCloseButton from '../Icon/IconCloseButton.vue';
import { Pair } from '@/margin/utils/trading.ts';

const props = defineProps({
  pair: {
    type: Object as PropType<Pair>,
    required: true,
  },
  lastPrice: {
    type: Number,
    required: true,
  },
  change: {
    type: Number,
    required: true,
  },
  active: {
    type: Boolean,
    default: false,
  },
  closable: {
    type: Boolean,
    default: true,
  },
  precision: {
    type: Number,
    default: 2,
  },
  accountName: {
    type: String,
    default: '',
  },
});

defineEmits(['select', 'close']);

const tab = ref<HTMLDivElement>();
const scrollIntoViewIfActive = () => {
  if (props.active) {
    tab.value?.scrollIntoView({
      block: 'center',
    });
  }
};

watch(() => props.active, scrollIntoViewIfActive);
onMounted(() => {
  if (props.active) {
    // Hack to scroll into view after all tabs are rendered
    setTimeout(scrollIntoViewIfActive, 500);
  }
});
</script>

<template>
  <div
    ref="tab"
    :class="[
      'group flex cursor-pointer items-center gap-0.5 border-t px-2 py-2 text-center text-xs',
      {
        'border-transparent': !active,
        'border-m-primary bg-m-gray-800': active,
      },
    ]"
    @click="!active ? $emit('select') : null"
  >
    <span class="mr-1.5"
      >{{ accountName }}: {{ pair.name.base }}/{{ pair.name.quote }}</span
    >
    <span
      class="text-[0.625rem] font-normal"
      :style="{
        width:
          $num(lastPrice, {
            maximumFractionDigits: precision,
            minimumFractionDigits: precision,
          })?.length + 'ch',
      }"
      >{{
        $num(lastPrice, {
          maximumFractionDigits: precision,
          minimumFractionDigits: precision,
        })
      }}</span
    >
    <KitChange class="text-[0.625rem] font-normal" :value="change" suffix="%" />
    <span
      v-if="closable"
      :class="[
        'rounded-full hover:bg-m-gray-500 group-hover:opacity-100',
        { 'opacity-0 transition-all': !active },
      ]"
      @click.stop="$emit('close')"
      ><IconCloseButton
        class="h-3.5 w-3.5 font-normal text-m-gray-500 transition-all hover:text-m-gray-200"
    /></span>
  </div>
</template>
