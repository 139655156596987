<script setup lang="ts">
import { ref } from 'vue';
import KitTabs from '../Kit/KitTabs.vue';
import { Tab } from '@/margin/types';
import TradingViewCoinOverview from '../TradingView/TradingViewCoinOverview.vue';
import { useI18n } from 'vue-i18n';
import TradingViewChart from '@/margin/components/TradingView/TradingViewChart.vue';

enum TabId {
  Chart,
  Overview,
}

const { t } = useI18n();

const tab = ref(TabId.Chart);
const tabs = ref<Tab[]>([
  {
    id: TabId.Chart,
    title: t('tradingView.chart'),
  },
  {
    id: TabId.Overview,
    title: t('tradingView.coinOverview'),
  },
]);
</script>

<template>
  <div class="flex h-full flex-col">
    <KitTabs
      v-model="tab"
      :tabs="tabs"
      class="mt-2"
      tab-class="pl-2"
      selected-tab-class="font-medium"
    />

    <TradingViewChart :hidden="tab !== TabId.Chart" />
    <TradingViewCoinOverview
      v-if="tab === TabId.Overview"
      :coin="$store.getters['currencyPair/currentPair']?.coin"
    />
  </div>
</template>
