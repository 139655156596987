import {
  CreateOrderParams,
  EditOrderParams,
  EditPositionParams,
  History,
  Order,
  OrderDTO,
  Position,
  PositionDTO,
  TradeHistoryDTO,
} from '@/margin/types';
import { toPairs } from '@/margin/utils/trading.ts';
import { api, getToken, post } from '@/margin/api';
import { AxiosError } from 'axios';
import { Precision, getPairPrecision } from '../utils/coin';
import { toFixedFractions } from '../utils';

export enum OrderException {
  BlockedAccount = 'blocked_account',
  NotEnoughMoney = 'not_enough_money',
  UnexpectedError = 'unexpected_error',
  InvalidCloseAmount = 'invalid_close_amount',
}

const getPrecisions = async (symbols: string[]) => {
  const uniqueSymbols = Array.from(new Set(symbols ?? []));

  const precisions: { [symbol: string]: Precision } = {};
  for (const symbol of uniqueSymbols) {
    precisions[symbol] = await getPairPrecision(symbol);
  }

  return precisions;
};

export const getOrderList = async (accountId: number): Promise<Order[]> => {
  const data = await post<OrderDTO[]>(
    '/margin/order/list/',
    {
      token: getToken(),
      account_id: accountId?.toString(),
    },
    {
      retryCount: 1,
    },
  );

  const symbols = data?.map(({ symbol }) => symbol);
  const precisions = await getPrecisions(symbols);
  const pairs = await toPairs(symbols);

  return (data ?? []).map<Order>((order) => ({
    id: order.id,
    type: order.type,
    side: order.side,
    pair: pairs[order.symbol],
    size: order.size,
    filledAmount: order.filled_amount,
    price: order.price,
    stopPrice: order.stop_price,
    stopLoss: order.stop_loss === -1 ? null : order.stop_loss,
    takeProfit: order.take_profit === -1 ? null : order.take_profit,
    margin: order.margin,
    time: new Date(order.time),
    precision: precisions[order.symbol]?.precision,
    sizePrecision: precisions[order.symbol]?.sizePrecision,
  }));
};

export const getPositionList = async (
  accountId: number,
): Promise<Position[]> => {
  const data = await post<PositionDTO[]>(
    '/margin/position/list/',
    {
      token: getToken(),
      account_id: accountId?.toString(),
    },
    {
      retryCount: 1,
    },
  );

  const symbols = data?.map(({ symbol }) => symbol);
  const precisions = await getPrecisions(symbols);
  const pairs = await toPairs(symbols);

  return (data ?? []).map<Position>((order) => ({
    id: order.id,
    type: order.type,
    side: order.side,
    pair: pairs[order.symbol],
    size: toFixedFractions(
      order.size - order.filled_amount,
      precisions[order.symbol]?.sizePrecision,
    ),
    commission: order.commission,
    rebate: order.rebate,
    profit: order.profit,
    currentPrice: order.current_price,
    entryPrice: order.entry_price,
    filledAmount: order.filled_amount,
    filledDate: order.filled_time ? new Date(order.filled_time) : null,
    stopLoss: order.stop_loss === -1 ? null : order.stop_loss,
    takeProfit: order.take_profit === -1 ? null : order.take_profit,
    margin: order.margin,
    profitROE: (100 / order.margin) * order.profit,
    precision: precisions[order.symbol]?.precision,
    sizePrecision: precisions[order.symbol]?.sizePrecision,
    swap: order.swap,
    unrealizedPnL:
      order.profit -
      Math.abs(order.commission ?? 0) -
      Math.abs(order.swap ?? 0),
  }));
};

export const getTradeHistoryList = async (
  accountId: number,
  from: Date,
  to: Date,
) => {
  if (!from) {
    from = new Date(0);
  }

  if (!to) {
    to = new Date();
    to.setHours(23, 59, 59, 999);
  }

  const data = await post<TradeHistoryDTO[]>(
    '/margin/history/trade-list/',
    {
      token: getToken(),
      account_id: accountId?.toString(),
      timestamp_from: from.getTime().toString(),
      timestamp_to: to.getTime().toString(),
    },
    {
      retryCount: 1,
    },
  );

  const symbols = data?.map(({ symbol }) => symbol);
  const precisions = await getPrecisions(symbols);
  const pairs = await toPairs(symbols);

  return (data ?? []).map<History>((trade) => ({
    id: trade.id,
    date: new Date(trade.date),
    side: trade.side,
    pair: pairs[trade.symbol],
    openPrice: trade.open_price,
    size: trade.size,
    initialSize: trade.initial_size,
    closePrice: trade.close_price,
    commission: trade.commission,
    rebate: trade.rebate,
    profit: trade.profit,
    precision: precisions[trade.symbol]?.precision,
    sizePrecision: precisions[trade.symbol]?.sizePrecision,
    swap: trade.swap,
  }));
};

export const closeOrder = async (accountId: number, orderId: number) => {
  const response = await api.delete('/margin/order/destroy/', {
    data: {
      token: getToken(),
      account_id: accountId?.toString(),
      id: orderId.toString(),
    },
  });

  if (response.data?.id) {
    return response.data;
  }

  throw new AxiosError('Unable to close position');
};

export const createOrder = async (
  accountId: number,
  params: CreateOrderParams,
): Promise<number> => {
  const response = await api.post('/margin/order/create/', {
    token: getToken(),
    account_id: accountId?.toString(),
    type: params.type,
    side: params.side.toUpperCase(),
    symbol: params.symbol,
    amount: params.size?.toString() ?? undefined,
    price: params.limitPrice?.toString() ?? undefined,
    stop_loss: params.stopLoss?.toString() ?? undefined,
    take_profit: params.takeProfit?.toString() ?? undefined,
    stop_price: params.stopPrice?.toString() ?? undefined,
  });

  const data = response.data;

  if (data.id) {
    return data.id;
  }

  throw new AxiosError();
};

export const editOrder = async (
  accountId: number,
  params: EditOrderParams,
): Promise<number> => {
  const response = await api.put('/margin/order/edit/', {
    token: getToken(),
    account_id: accountId?.toString(),
    id: params.id.toString(),
    price: params.limitPrice?.toString() ?? undefined,
    amount: params.size?.toString() ?? undefined,
    stop_loss: params.stopLoss?.toString() ?? undefined,
    take_profit: params.takeProfit?.toString() ?? undefined,
    stop_price: params.stopPrice?.toString() ?? undefined,
  });

  if (response.data?.id) {
    return response.data;
  }

  throw new AxiosError();
};

export const closePosition = async (
  accountId: number,
  orderId: number,
  size?: number,
) => {
  const response = await api.delete('/margin/position/destroy/', {
    data: {
      token: getToken(),
      account_id: accountId?.toString(),
      id: orderId.toString(),
      amount: size?.toString() ?? undefined,
    },
  });

  if (response.data?.id) {
    return response.data;
  }

  throw new AxiosError();
};

export const editPosition = async (
  accountId: number,
  params: EditPositionParams,
): Promise<number> => {
  const response = await api.put('/margin/position/edit/', {
    token: getToken(),
    account_id: accountId?.toString(),
    id: params.id.toString(),
    stop_loss: params.stopLoss?.toString() ?? undefined,
    take_profit: params.takeProfit?.toString() ?? undefined,
  });

  if (response.data?.id) {
    return response.data;
  }

  throw new AxiosError();
};

export const reversePosition = async (
  accountId: number,
  positionId: number,
): Promise<number> => {
  const response = await api.post('/margin/position/reverse/', {
    token: getToken(),
    account_id: accountId?.toString(),
    id: positionId.toString(),
  });

  if (response.data?.id) {
    return response.data;
  }

  throw new AxiosError();
};
