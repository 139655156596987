import { api, getToken, post } from '@/margin/api';

export const getFavoriteSymbols = async (accountId: number) => {
  return await post<string[]>(
    '/star/list/',
    {
      token: getToken(),
      account_id: accountId?.toString(),
    },
    {
      retryCount: 1,
    },
  );
};

export const addFavoriteSymbol = async (accountId: number, symbol: string) => {
  await api.post('/star/add/', {
    token: getToken(),
    account_id: accountId?.toString(),
    symbol,
  });
};

export const removeFavoriteSymbol = async (
  accountId: number,
  symbol: string,
) => {
  await api.delete('/star/remove/', {
    data: {
      token: getToken(),
      account_id: accountId?.toString(),
      symbol,
    },
  });
};
