<script lang="ts" setup generic="T">
import { TableColumn, SortOrder, SortField } from '@/margin/types';
import Column from 'primevue/column';
import DataTable from 'primevue/datatable';
import { computed, PropType, ref, watch } from 'vue';
import IconCaretDown from '../Icon/IconCaretDown.vue';
import KitSkeleton from '@/margin/components/Kit/KitSkeleton.vue';
import KitPagination from '@/margin/components/Kit/KitPagination.vue';

const props = defineProps({
  variant: {
    type: String as PropType<'default' | 'secondary'>,
    default: 'default',
  },
  columns: {
    type: Array as PropType<TableColumn[]>,
    required: true,
  },
  data: {
    type: Array as PropType<T[]>,
    required: true,
  },
  sortField: {
    type: String as PropType<SortField>,
    default: '',
  },
  sortOrder: {
    type: Number as PropType<SortOrder>,
    default: 0,
  },
  loading: {
    type: Boolean,
    default: false,
  },
  rows: {
    type: Number,
    default: 10,
  },
  loadingRows: {
    type: Number,
    default: 20,
  },
  pagination: {
    type: Boolean,
    default: false,
  },
  autoHidePagination: {
    type: Boolean,
    default: false,
  },
  dataKey: {
    type: String,
    default: '',
  },
  headerBackground: {
    type: String,
    default: '',
  },
  scrollHeight: {
    type: String,
    default: '',
  },
  itemSize: {
    type: Number,
    default: null,
  },
  itemsOutsideView: {
    type: Number,
    default: undefined,
  },
  preventPageResetOnDataChange: {
    type: Boolean,
    default: false,
  },
});

defineEmits(['rowClick']);

const currentPage = ref(1);
const sortField = ref(props.sortField);
const sortOrder = ref(props.sortOrder);

const totalPages = computed(() => Math.ceil(props.data.length / props.rows));

const loadingData = computed(() => Array(props.loadingRows).fill({} as T));

const showPagination = computed(() => {
  if (props.autoHidePagination && props.pagination) {
    return props.data.length > props.rows;
  }

  return props.pagination;
});

const getValueByField = (row: T, field: SortField) => {
  if (typeof field === 'function') {
    return field(row);
  }

  return field.split('.').reduce((acc, key) => acc?.[key], row);
};

const sortRows = (rows: T[], field: SortField, order: SortOrder) =>
  [...rows].sort((a, b) => {
    if (!field || order === 0) return 0;

    const left = getValueByField(a, field);
    const right = getValueByField(b, field);

    if (typeof left === 'string') {
      return order * left.localeCompare(right as string);
    }

    if (left > right) return order;

    if (left < right) return -order;
  });

const tableData = computed(() => {
  const data = sortRows(props.data, sortField.value, sortOrder.value);

  if (props.pagination) {
    return data.slice(
      (currentPage.value - 1) * props.rows,
      currentPage.value * props.rows,
    );
  }

  return data;
});

watch(
  () => props.data,
  () => {
    if (!props.preventPageResetOnDataChange) {
      currentPage.value = 1;
    }
  },
);

const align = {
  left: 'text-left',
  right: 'text-right',
  center: 'text-center',
};

const flexAlign = {
  left: 'justify-start',
  right: 'justify-end',
  center: 'justify-center',
};
</script>

<template>
  <div class="flex flex-col overflow-hidden">
    <DataTable
      :virtual-scroller-options="
        itemSize ? { itemSize, numToleratedItems: itemsOutsideView } : null
      "
      :value="loading ? loadingData : tableData"
      :scrollable="!!scrollHeight"
      :scroll-height="scrollHeight"
      class="h-full overflow-auto"
      removable-sort
      :sort-field="sortField"
      :sort-order="sortOrder"
      :default-sort-order="-1"
      :pt="{
        root: '',
        bodyRow: '',
        headerRow: headerBackground,
        virtualScrollerSpacer: {
          class: 'flex',
        },
        column: {
          headerCell:
            'text-xs text-m-gray-400 font-normal pb-2 whitespace-nowrap px-2 cursor-pointer',
          bodyCell: `${variant === 'default' ? 'bg-m-gray-700 border-m-gray-800' : 'border-m-gray-500'} border-b h-9 whitespace-nowrap text-xs`,
        },
      }"
      :data-key="dataKey"
      @row-click="$emit('rowClick', $event)"
      @update:sort-field="sortField = $event"
      @update:sort-order="sortOrder = $event as SortOrder"
    >
      <Column
        v-for="column in columns"
        :key="column.field"
        :field="column.field"
        :header="column.header"
        :header-class="column.class"
        :sortable="column.sortable"
        :sort-field="column.sortField ?? column.field"
        :pt="{
          bodyCell: `${align[column.align ?? 'left']} px-2`,
          headerTitle: '',
          headerContent: flexAlign[column.align ?? 'left'],
        }"
      >
        <template #sorticon="sort">
          <div class="ml-1 flex flex-col">
            <IconCaretDown
              v-if="sort.sortOrder === 1 || variant === 'default'"
              :class="[
                'rotate-180',
                variant === 'default' ? 'h-2 w-2' : 'h-3 w-3',
                sort.sortOrder === 1 && variant === 'default'
                  ? 'text-m-white'
                  : 'text-m-gray',
              ]"
            />
            <IconCaretDown
              v-if="sort.sortOrder === -1 || variant === 'default'"
              :class="[
                variant === 'default' ? 'h-2 w-2' : 'h-3 w-3',
                sort.sortOrder === -1 && variant === 'default'
                  ? 'text-m-white'
                  : 'text-m-gray',
              ]"
            />
          </div>
        </template>

        <template #body="body">
          <KitSkeleton v-if="loading" shape="long" />
          <slot
            v-else
            :name="`field-${column.field}`"
            v-bind="{ data: body.data as T }"
          >
            {{ body.data[column.field] }}
          </slot>
        </template>
      </Column>

      <template #empty>
        <div class="text-center text-sm font-medium text-m-gray">
          {{ $t('kit.table.noData') }}
        </div>
      </template>
    </DataTable>
    <KitPagination
      v-if="showPagination"
      :total-pages="totalPages"
      :page="currentPage"
      :shown="tableData.length"
      :total="data.length"
      @next="currentPage += 1"
      @previous="currentPage -= 1"
    />
  </div>
</template>

<style>
/* NOTE: There is bug in PrimeVue DataTable where the empty message cannot be styled */
[data-pc-section='emptymessagecell'] {
  border: none;
}
</style>
