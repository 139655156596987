import { toJSON } from '.';

const RECONNECT_AFTER_INACTIVITY = 30_000;
const RECONNECT_DELAY = 5_000;

export class Socket<D = unknown> {
  private socket!: WebSocket;
  private callbacks = {
    open: [],
    message: [],
  };

  private lastMessageReceived = new Date();
  private interval: number;

  constructor(url: string) {
    this.connect(url);
  }

  onData(callback: (data: D) => void) {
    this.callbacks.message.push(callback);
  }

  onOpen(callback: () => void) {
    this.callbacks.open.push(callback);
  }

  close() {
    if (this.interval) {
      clearInterval(this.interval);
    }

    if (!this.socket) return;

    this.socket.onclose = null;
    this.socket.onopen = null;
    this.socket.onmessage = null;

    this.socket.close();
  }

  send(data: unknown) {
    this.socket.send(JSON.stringify(data));
  }

  private connect(url: string) {
    this.close();

    this.socket = new WebSocket(url);

    this.socket.onerror = () => {
      setTimeout(() => this.connect(url), RECONNECT_DELAY);
    };

    this.socket.onmessage = (event) => {
      const data = toJSON<D>(event.data);

      if (!data) return;

      this.callbacks.message.forEach((callback) => callback(data));

      this.lastMessageReceived = new Date();
    };

    this.socket.onopen = () => {
      this.callbacks.open.forEach((callback) => callback());
    };

    if (this.interval) clearInterval(this.interval);

    this.interval = setInterval(() => {
      const now = new Date();

      if (
        now.getTime() - this.lastMessageReceived.getTime() >
        RECONNECT_AFTER_INACTIVITY
      ) {
        this.connect(url);
      }
    }, RECONNECT_AFTER_INACTIVITY);
  }
}
