<script setup lang="ts">
import { computed, PropType, ref } from 'vue';
import { useElementSize } from '@vueuse/core';
import { OrderBookType, OrderBookLevel } from '@/margin/types';
import { Pair } from '@/margin/utils/trading.ts';

const props = defineProps({
  orders: {
    type: Array as PropType<OrderBookLevel[]>,
    required: true,
  },
  pair: {
    type: Object as PropType<Pair>,
    required: true,
  },
  reverseOrders: {
    type: Boolean,
    default: false,
  },
  type: {
    type: String as PropType<OrderBookType>,
    required: true,
  },
  precision: {
    type: Number,
    default: 2,
  },
});

const ORDER_HEIGHT = 24 + 2;

const gridRef = ref<HTMLElement | null>(null);
const { height } = useElementSize(gridRef);

const numberOfOrders = computed(() => Math.floor(height.value / ORDER_HEIGHT));

const visibleOrders = computed(() => {
  const orders = props.orders.slice(0, numberOfOrders.value);

  return props.reverseOrders ? orders.reverse() : orders;
});
</script>

<template>
  <div class="flex flex-col overflow-hidden">
    <div class="mb-2 grid grid-cols-3 text-xs text-gray-400">
      <span>{{ $t('orderBook.list.price') }} ({{ pair?.name.quote }})</span>
      <span class="text-center"
        >{{ $t('orderBook.list.size') }} ({{ pair?.name.base }})</span
      >
      <span class="text-right"
        >{{ $t('orderBook.list.total') }} ({{ pair?.name.base }})</span
      >
    </div>

    <div
      ref="gridRef"
      :class="[
        'flex h-full flex-col overflow-hidden',
        { 'justify-end': reverseOrders },
      ]"
    >
      <div
        v-for="(order, index) in visibleOrders"
        :key="index"
        class="relative"
      >
        <div
          :class="[
            ' absolute right-0 h-6 rounded-sm',
            {
              'bg-m-success/20': type === OrderBookType.Bids,
              'bg-m-danger/20': type === OrderBookType.Asks,
            },
          ]"
          :style="{ width: order.depth + '%' }"
        ></div>
        <div
          class="relative z-10 mb-0.5 grid h-6 grid-cols-3 px-1 py-1 text-xs text-m-white"
        >
          <span
            :class="[
              {
                'text-m-success': type === OrderBookType.Bids,
                'text-m-danger': type === OrderBookType.Asks,
              },
            ]"
          >
            {{ $num(order.price, precision) }}
          </span>
          <span
            :title="$num(order.size, { maximumFractionDigits: 2 })"
            class="overflow-hidden text-ellipsis text-center text-m-white"
            >{{ $num(order.size, { maximumFractionDigits: 2 }) }}</span
          >
          <span
            :title="$num(order.total, { maximumFractionDigits: 2 })"
            class="overflow-hidden text-ellipsis text-right text-m-white"
            >{{ $num(order.total, { maximumFractionDigits: 2 }) }}</span
          >
        </div>
      </div>

      <template v-if="$store.state.orderBook.loading">
        <div
          v-for="i in numberOfOrders"
          :key="i"
          class="mb-0.5 h-6 animate-pulse bg-m-gray-500"
        ></div>
      </template>
    </div>
  </div>
</template>
