<template>
  <svg
    width="17"
    height="17"
    viewBox="0 0 17 17"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g id="basic / check_bold">
      <path
        d="M6.24609 14.0104L1.73828 9.50262L3.74286 7.49804L6.24609 10.0083L13.2571 2.99023L15.2617 4.99482L6.24609 14.0104Z"
        fill="currentColor"
      />
    </g>
  </svg>
</template>
