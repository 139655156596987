<template>
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M11.3333 14.6666H4.66667C3.93029 14.6666 3.33333 14.0697 3.33333 13.3333V4.66665H2V3.33331H4.66667V2.66665C4.66667 1.93027 5.26362 1.33331 6 1.33331H10C10.7364 1.33331 11.3333 1.93027 11.3333 2.66665V3.33331H14V4.66665H12.6667V13.3333C12.6667 14.0697 12.0697 14.6666 11.3333 14.6666ZM4.66667 4.66665V13.3333H11.3333V4.66665H4.66667ZM6 2.66665V3.33331H10V2.66665H6ZM10 12H8.66667V5.99998H10V12ZM7.33333 12H6V5.99998H7.33333V12Z"
      fill="currentColor"
    />
  </svg>
</template>
