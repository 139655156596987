import { createI18n } from 'vue-i18n';
import moment from 'moment/moment';
import { getFractions, isNumber } from '@/margin/utils';
import type { LanguageCode } from '../../public/tradingView/charting_library/charting_library.esm';
import en from '@/margin/locales/en.json';

export type numberFormat = 'decimal' | 'percent' | 'compactUSD' | 'auto';

export const i18n = createI18n({
  locale: 'en',
  fallbackLocale: 'en',
  legacy: false,
  messages: {
    en,
  },
  numberFormats: {
    en: {
      decimal: {
        style: 'decimal',
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
        useGrouping: true,
      },
      percent: {
        style: 'decimal',
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
        useGrouping: false,
      },
      compactUSD: {
        style: 'currency',
        currency: 'USD',
        useGrouping: true,
        notation: 'compact',
        compactDisplay: 'short',
      },
    },
  },
});

export const DATE_FORMAT = 'YYYY/MM/DD';
export const DATE_TIME_FORMAT = 'YYYY/MM/DD HH:mm';

export const formatDate = (date: Date | string | number, withTime = false) => {
  if (!date) return '';

  return moment(date).format(withTime ? DATE_TIME_FORMAT : DATE_FORMAT);
};

export const formatNumber = (
  number: number,
  format: numberFormat | number | Intl.NumberFormatOptions = 'auto',
  fallback = '-',
): string => {
  if (!isNumber(number)) return fallback;

  if (format === 'auto') {
    format = {
      style: 'decimal',
      maximumFractionDigits: getFractions(number),
      useGrouping: true,
    };
  }

  if (typeof format === 'number') {
    format = {
      style: 'decimal',
      maximumFractionDigits: format,
      minimumFractionDigits: format,
      useGrouping: true,
    };
  }

  return i18n.global.n(number, format) ?? fallback;
};

export const localeToTradingViewLocale = (locale: string): LanguageCode => {
  return locale as LanguageCode;
};
