<script setup lang="ts">
import SettingsPanel from '@/margin/components/Settings/SettingsPanel.vue';
import { computed, ref } from 'vue';
import KitTickerTab from '@/margin/components/Kit/KitTickerTab.vue';
import TickerPairInfo from '@/margin/components/Ticker/TickerPairInfo.vue';
import { useStore } from '@/margin/store';
import { CurrencyPair } from '@/margin/types';
import TickerDropdown from '@/margin/components/Ticker/TickerDropdown.vue';
import { arePairsSame } from '@/margin/store/currencyPairModule.ts';

const showSettings = ref(false);

const store = useStore();
const selectedPair = computed<CurrencyPair>(
  () => store.getters['currencyPair/currentPair'],
);

const onSelect = (symbol: string) => {
  store.dispatch('currencyPair/addPair', {
    symbol,
    accountId: store.state.auth.account?.id,
  });
  store.dispatch('currencyPair/selectPair', {
    symbol,
    accountId: store.state.auth.account?.id,
  });
};

const getAccountCurrency = (accountId: number) => {
  return store.state.auth.accounts?.find(({ id }) => id === accountId)
    ?.currency;
};
</script>

<template>
  <div class="flex h-full flex-col">
    <div
      class="scrollbar-hide flex w-full shrink-0 overflow-auto whitespace-nowrap bg-m-gray-900"
    >
      <KitTickerTab
        v-for="pair in $store.state.currencyPair.pairs"
        :key="pair.symbol"
        :pair="pair.pair"
        :account-name="getAccountCurrency(pair.accountId)"
        :last-price="
          $store.state.orderBook?.symbol === pair?.symbol
            ? $store.state.orderBook.lastPrice ?? pair.detail?.lastPrice
            : pair.detail?.lastPrice
        "
        :change="pair.detail?.change?.percentage"
        :active="arePairsSame(selectedPair, pair)"
        :closable="$store.state.currencyPair.pairs.length > 1"
        :precision="pair?.info?.precision ?? 2"
        @select="$store.dispatch('currencyPair/selectPair', pair)"
        @close="$store.dispatch('currencyPair/removePair', pair)"
      />
    </div>
    <div
      class="flex h-full items-center justify-between whitespace-nowrap border-t border-m-gray-600 p-2"
    >
      <div class="flex w-full items-center gap-2 overflow-hidden">
        <TickerDropdown
          :pair="selectedPair"
          :favorite="
            $store.state.favorite.symbols.includes(selectedPair?.symbol)
          "
          @select="onSelect"
          @favorite="
            $store.dispatch('favorite/updateFavorite', {
              symbol: selectedPair.symbol,
              favorite: $event,
            })
          "
        />
        <TickerPairInfo
          :pair="selectedPair?.pair"
          :last-price="
            $store.state.orderBook.lastPrice ?? selectedPair?.detail?.lastPrice
          "
          :precision="selectedPair?.info?.precision ?? 2"
          :swap="selectedPair?.info?.swap"
          :change="selectedPair?.detail?.change.absolute"
          :change-percentage="selectedPair?.detail?.change.percentage"
          :high="selectedPair?.detail?.high"
          :low="selectedPair?.detail?.low"
          :volume="selectedPair?.detail?.volume"
          @settings="showSettings = true"
        />
      </div>
    </div>

    <SettingsPanel v-model="showSettings" />
  </div>
</template>
