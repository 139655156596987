import { onUnmounted } from 'vue';

export const useEventEmitter = () => {
  const events: { [key: string]: () => void } = {};

  const on = (event: string, callback: () => void) => {
    events[event] = callback;
  };

  const off = (event: string) => {
    delete events[event];
  };

  const emit = (event: string) => {
    events[event]?.();
  };

  const emitAll = () => {
    Object.keys(events).forEach(emit);
  };

  const clear = () => {
    Object.keys(events).forEach(off);
  };

  onUnmounted(clear);

  return {
    on,
    off,
    emit,
    emitAll,
    clear,
  };
};

const globalEvents: { [key: string]: ((data?: unknown) => void)[] } = {};

export enum GlobalEvents {
  LimitPrice = 'limit-price',
}

export const useGlobalEvent = () => {
  let localCallbacks: ((data?: unknown) => void)[] = [];

  const on = (event: string, callback: (data?: unknown) => void) => {
    if (!globalEvents[event]) {
      globalEvents[event] = [];
    }

    globalEvents[event].push(callback);

    localCallbacks.push(callback);
  };

  const off = (event: string, callback: Function) => {
    globalEvents[event] = globalEvents[event]?.filter((cb) => cb !== callback);
    localCallbacks = localCallbacks.filter((cb) => cb !== callback);
  };

  const emit = (event: string, data: unknown) => {
    globalEvents[event]?.forEach((cb) => cb(data));
  };

  onUnmounted(() => {
    Object.entries(globalEvents).forEach(([event, callbacks]) => {
      callbacks
        .filter((cb) => localCallbacks.includes(cb))
        .forEach((cb) => off(event, cb));
    });
  });

  return {
    on,
    off,
    emit,
  };
};
