<template>
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M2.94666 13.7193C2.75965 13.719 2.58137 13.6401 2.45533 13.502C2.32696 13.365 2.26317 13.1797 2.28 12.9926L2.44333 11.1966L9.98866 3.65398L12.3467 6.01131L4.80333 13.5533L3.00733 13.7166C2.98666 13.7186 2.966 13.7193 2.94666 13.7193ZM12.8173 5.53998L10.46 3.18264L11.874 1.76864C11.999 1.64346 12.1687 1.57312 12.3457 1.57312C12.5226 1.57312 12.6923 1.64346 12.8173 1.76864L14.2313 3.18264C14.3565 3.30769 14.4269 3.47737 14.4269 3.65431C14.4269 3.83125 14.3565 4.00093 14.2313 4.12598L12.818 5.53931L12.8173 5.53998Z"
      fill="currentColor"
    />
  </svg>
</template>
