<script setup lang="ts">
import { usePrecision } from '@/margin/composeables/usePrecision';
import { toRef } from 'vue';

const props = defineProps({
  symbol: {
    type: String,
    required: true,
  },
  value: {
    type: Number,
    required: true,
  },
});

const symbol = toRef(props, 'symbol');
const precision = usePrecision(symbol);
</script>

<template>
  {{ $num(value, precision) }}
</template>
