<script lang="ts" setup>
import { priceChangeSymbol } from '@/margin/utils';
import { computed } from 'vue';
import IconCaretDown from '@/margin/components/Icon/IconCaretDown.vue';
import { formatNumber, numberFormat } from '@/margin/i18n.ts';

const props = defineProps({
  value: {
    type: Number,
    required: true,
  },
  numberStyle: {
    type: [Number, String, Object],
    default: 'decimal',
  },
  suffix: {
    type: String,
    default: '',
  },
  showSymbol: {
    type: Boolean,
    default: true,
  },
  hidePlus: {
    type: Boolean,
    default: false,
  },
  symbolAsIcon: {
    type: Boolean,
    default: false,
  },
});

const color = computed(() => {
  return props.value > 0 ? 'text-m-success' : 'text-m-danger';
});

const formattedValue = computed(() => {
  if (!props.value) return '-';
  const symbol =
    props.showSymbol && !props.symbolAsIcon
      ? priceChangeSymbol(props.value)
      : '';
  const number = formatNumber(
    Math.abs(props.value),
    props.numberStyle as numberFormat,
  );

  return `${symbol === '+' && props.hidePlus ? '' : symbol}${number}${props.suffix}`;
});
</script>

<template>
  <div :class="['inline-flex items-center', color]">
    <IconCaretDown
      v-if="symbolAsIcon && value !== 0"
      :class="[
        'h-3.5 w-3.5',
        {
          'rotate-180': value > 0,
        },
      ]"
    />
    {{ formattedValue }}
  </div>
</template>
