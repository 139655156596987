<script setup lang="ts">
import KitSidePanel from '@/margin/components/Kit/KitSidePanel.vue';
import KitButton from '@/margin/components/Kit/KitButton.vue';
import KitSwitch from '@/margin/components/Kit/KitSwitch.vue';
import { reactive, watch } from 'vue';
import {
  DEFAULT_SETTINGS,
  SettingsState,
} from '@/margin/store/settingsModule.ts';
import { useStore } from '@/margin/store';

const store = useStore();

const form = reactive<SettingsState>({ ...DEFAULT_SETTINGS });

const visible = defineModel({
  type: Boolean,
  default: false,
});

const updateSettings = (settings: SettingsState) => {
  form.showConfirmAction = settings.showConfirmAction;
  form.showOrderOnChart = settings.showOrderOnChart;
  form.showTPSLOnChart = settings.showTPSLOnChart;
  form.showPositionsOnChart = settings.showPositionsOnChart;
};

watch(store.state.settings, updateSettings, { immediate: true });
watch(visible, (value) => {
  if (value) {
    updateSettings(store.state.settings);
  }
});

const onSave = () => {
  store.dispatch('settings/setSettings', form);
  visible.value = false;
};
</script>

<template>
  <KitSidePanel v-model="visible" :title="$t('settings.title')">
    <div class="flex h-full flex-col justify-between overflow-auto">
      <div>
        <h4 class="text-sm font-semibold">{{ $t('settings.trading') }}</h4>

        <div class="mt-2 flex flex-col gap-1">
          <div
            class="flex items-center justify-between bg-m-gray-700 p-4 text-sm"
          >
            {{ $t('settings.showConfirm') }}
            <KitSwitch
              v-model="form.showConfirmAction"
              data-test-id="showConfirmAction"
            />
          </div>
        </div>

        <!-- TODO: Uncomment when we figure out how to display orders on the chart -->
        <h4 class="mt-8 text-sm font-semibold">{{ $t('settings.charts') }}</h4>

        <div class="mt-2 flex flex-col gap-1">
          <div
            class="flex items-center justify-between bg-m-gray-700 p-4 text-sm"
          >
            {{ $t('settings.showOrderCharts') }}
            <KitSwitch
              v-model="form.showOrderOnChart"
              data-test-id="showOrderOnChart"
            />
          </div>
          <div
            class="flex items-center justify-between bg-m-gray-700 p-4 text-sm"
          >
            {{ $t('settings.TPSL.charts') }}
            <KitSwitch
              v-model="form.showTPSLOnChart"
              data-test-id="showTPSLOnChart"
            />
          </div>
          <div
            class="flex items-center justify-between bg-m-gray-700 p-4 text-sm"
          >
            {{ $t('settings.positionCharts') }}
            <KitSwitch
              v-model="form.showPositionsOnChart"
              data-test-id="showPositionsOnChart"
            />
          </div>
        </div>

        <button
          class="mt-8 text-sm disabled:text-m-gray"
          data-test-id="settingsResetToDefault"
          :disabled="!$store.getters['settings/isChanged']"
          @click="$store.dispatch('settings/resetSettings')"
        >
          {{ $t('settings.resetToDefault') }}
        </button>
      </div>

      <KitButton
        variant="primary"
        class="mx-auto mt-8 max-w-[317px]"
        data-test-id="settingsSave"
        @click="onSave"
        >{{ $t('settings.save') }}</KitButton
      >
    </div>
  </KitSidePanel>
</template>
