import { post } from '@/margin/api';
import { BarlistDTO } from '../types';
import type { Bar } from '../../../public/tradingView/charting_library/charting_library.esm';

export const MAX_BAR_COUNT = Number(import.meta.env.VITE_MAX_BAR_COUNT) || 500;

const toPartialCounts = (count: number, maxCount: number) => {
  const rate = count / maxCount;

  const full = Math.floor(rate);
  const rest = count - full * maxCount;

  const counts = new Array(full).fill(maxCount);

  if (rest) {
    counts.push(rest);
  }

  return counts;
};

export const getBarList = async (
  symbol: string,
  periodicity: string,
  timestamp: number,
  count: number,
): Promise<Bar[]> => {
  const data = await post<BarlistDTO[]>(
    '/margin/bar/list/',
    {
      symbol,
      periodicity,
      timestamp: timestamp.toString(),
      count: count.toString(),
    },
    {
      retryCount: 1,
    },
  );

  return data.map((bar) => ({
    time: bar.timestamp,
    date: new Date(bar.timestamp),
    low: bar.low,
    high: bar.high,
    open: bar.open,
    close: bar.close,
    volume: bar.volume,
  }));
};

export const getBarListRecursively = async (
  symbol: string,
  periodicity: string,
  timestamp: number,
  count: number,
) => {
  const counts = toPartialCounts(count, MAX_BAR_COUNT);

  let lastTimestamp = timestamp;
  const bars: Bar[] = [];

  for (const count of counts) {
    const partialBars = await getBarList(
      symbol,
      periodicity,
      lastTimestamp,
      count,
    );

    bars.unshift(...partialBars);

    lastTimestamp = partialBars[0]?.time - 1;
  }

  return bars;
};
