import { Option } from '@/margin/types';
import { Ref, computed } from 'vue';

export const useSelectedDropdownOption = <T>(
  model: Ref<T>,
  options: Ref<Option<T>[]>,
  matchByKey: Ref<string>,
) => {
  const selectedOption = computed(() => {
    const value = matchByKey.value
      ? model.value?.[matchByKey.value]
      : model.value;

    return options.value.find(
      (option) =>
        (matchByKey.value ? option.value?.[matchByKey.value] : option.value) ===
        value,
    );
  });

  return selectedOption;
};
