<script setup lang="ts">
import KitDialog from '@/margin/components/Kit/KitDialog.vue';
import KitRow from '@/margin/components/Kit/KitRow.vue';
import KitBadge from '@/margin/components/Kit/KitBadge.vue';
import KitButton from '@/margin/components/Kit/KitButton.vue';
import { PropType, ref, watch, computed } from 'vue';
import { OrderSide, OrderType, CurrencyPair } from '@/margin/types';
import { toOrderTypeName } from '../../utils/helpers.ts';
import KitCheckbox from '@/margin/components/Kit/KitCheckbox.vue';
import { Pair } from '@/margin/utils/trading.ts';
import { isNumber } from '@/margin/utils';
import { useStore } from '@/margin/store';
import KitPrice from '../Kit/KitPrice.vue';

const store = useStore();

const props = defineProps({
  type: {
    type: String as PropType<OrderType>,
    required: true,
  },
  side: {
    type: String as PropType<OrderSide>,
    required: true,
  },
  limitPrice: {
    type: Number,
    required: false,
    default: null,
  },
  stopPrice: {
    type: Number,
    required: false,
    default: null,
  },
  orderSize: {
    type: Number,
    required: true,
  },
  pair: {
    type: Object as PropType<Pair>,
    required: true,
  },
  loading: {
    type: Boolean,
    required: true,
  },
});

defineEmits<{
  (e: 'confirm', dontShowConfirmationAction: boolean): void;
}>();

const selectedPair = computed<CurrencyPair>(
  () => store.getters['currencyPair/currentPair'],
);

const price = computed(() => {
  switch (props.type) {
    case OrderType.Limit:
    case OrderType.StopLimit:
      return props.limitPrice ?? 0;
    case OrderType.Market:
      return selectedPair.value?.detail?.lastPrice ?? 0;
    case OrderType.Stop:
      return props.stopPrice ?? 0;
    default:
      return 0;
  }
});

const visible = defineModel({
  type: Boolean,
  required: true,
});

watch(visible, (value) => {
  if (!value) {
    dontShowConfirmationAction.value = false;
  }
});

const dontShowConfirmationAction = ref(false);
</script>

<template>
  <KitDialog v-model="visible" :title="$t('dialog.confirmOrder')">
    <KitRow :label="$t('dialog.orderType')">
      <KitBadge :variant="side == OrderSide.Buy ? 'success' : 'danger'">{{
        toOrderTypeName(type, side)
      }}</KitBadge>
    </KitRow>

    <KitRow v-if="isNumber(limitPrice)" :label="$t('dialog.limitPrice')">
      <KitPrice :symbol="pair.symbol" :value="limitPrice ?? 0" />
      <span class="ml-1">{{ pair.name.quote }}</span>
    </KitRow>

    <KitRow v-if="isNumber(stopPrice)" :label="$t('dialog.stopPrice')">
      <KitPrice :symbol="pair.symbol" :value="stopPrice ?? 0" />
      <span class="ml-1">{{ pair.name.quote }}</span>
    </KitRow>

    <KitRow :label="$t('dialog.orderSize')">
      <b>{{ $num(orderSize ?? 0, 'auto') }}</b> {{ pair.name.base }}
    </KitRow>

    <KitRow :label="$t('dialog.orderValue')">
      <KitPrice :symbol="pair.symbol" :value="price * orderSize" />
      <span class="ml-1">{{ pair.name.quote }}</span>
    </KitRow>

    <KitCheckbox
      v-model="dontShowConfirmationAction"
      class="mt-6"
      :label="$t('dialog.dontShowAgain')"
      data-test-id="dontShowConfirmationAction"
    />

    <KitButton
      class="mt-6"
      data-test-id="confirmActionButton"
      variant="primary"
      visible-arrow-right
      :loading="loading"
      @click="$emit('confirm', dontShowConfirmationAction)"
    >
      {{ $t('dialog.confirm') }}
    </KitButton>
  </KitDialog>
</template>
