import { createStore, useStore as useUntypedStore } from 'vuex';
import { AuthModule, AuthState } from './authModule';
import { LayoutModule, LayoutState } from './layoutModule';
import { CurrencyPairModule, CurrencyPairState } from './currencyPairModule';
import { OrderBookModule, OrderBookState } from './orderBookModule';
import { SettingsModule, SettingsState } from './settingsModule';
import { OrdersModule, OrdersState } from './ordersModule.ts';
import {
  PositionsModule,
  PositionsState,
} from '@/margin/store/positionsModule.ts';
import { HistoryModule, HistoryState } from '@/margin/store/historyModule.ts';
import {
  FavoriteModule,
  FavoriteState,
} from '@/margin/store/favoriteModule.ts';
import {
  PairListingModule,
  PairListingState,
} from '@/margin/store/pairListingModule.ts';

export const MARGIN_STORE_INJECTION_KEY = Symbol();

export interface StoreState {
  auth: AuthState;
  layout: LayoutState;
  currencyPair: CurrencyPairState;
  orderBook: OrderBookState;
  settings: SettingsState;
  orders: OrdersState;
  positions: PositionsState;
  history: HistoryState;
  favorite: FavoriteState;
  pairListing: PairListingState;
}

export const store = createStore<StoreState>({
  modules: {
    auth: AuthModule,
    layout: LayoutModule,
    currencyPair: CurrencyPairModule,
    orderBook: OrderBookModule,
    settings: SettingsModule,
    orders: OrdersModule,
    positions: PositionsModule,
    history: HistoryModule,
    favorite: FavoriteModule,
    pairListing: PairListingModule,
  },
});

export const useStore = () =>
  useUntypedStore<StoreState>(MARGIN_STORE_INJECTION_KEY);
