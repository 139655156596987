<script lang="ts" setup>
import IconCheckBold from '../Icon/IconCheckBold.vue';
import { computed } from 'vue';

const props = defineProps({
  label: {
    type: String,
    required: true,
  },
  nullable: {
    type: Boolean,
    default: false,
  },
});

const model = defineModel({
  required: false,
  type: Boolean,
});

const changeValue = () => {
  if (props.nullable) {
    if (model.value === null) {
      model.value = true;
    } else if (model.value === true) {
      model.value = false;
    } else {
      model.value = null;
    }
  } else {
    model.value = !model.value;
  }
};

const isChecked = computed(() => {
  if (props.nullable) {
    return typeof model.value === 'boolean';
  }

  return !!model.value;
});
</script>

<template>
  <div
    class="flex w-fit cursor-pointer items-center gap-2"
    @click="changeValue"
  >
    <div
      :class="[
        'flex h-4 w-4 items-center justify-center text-m-gray-800',
        isChecked
          ? 'bg-gradient-to-r from-m-primary-400 to-m-primary-300'
          : 'border-2 border-m-gray-400',
      ]"
    >
      <IconCheckBold v-if="isChecked && !nullable" />
      <span
        v-if="isChecked && nullable"
        :class="[
          'select-none text-lg font-bold leading-4',
          {
            'translate-y-[-1.5px]': !model, // NOTE: Font is pushing minus sign down, so we need to adjust it
          },
        ]"
        >{{ model ? '+' : '-' }}</span
      >
    </div>
    <div class="text-sm leading-4">
      {{ label }}
    </div>
  </div>
</template>
