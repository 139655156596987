import { Module } from 'vuex';
import { StoreState } from '@/margin/store/index.ts';
import { PairListingItem } from '@/margin/types';
import { getSymbolList } from '@/margin/api/currencyPair.ts';
import { captureException } from '@sentry/vue';

export interface PairListingState {
  pairs: PairListingItem[];
  loading: boolean;
}

export const PairListingModule: Module<PairListingState, StoreState> = {
  namespaced: true,
  state: {
    pairs: [],
    loading: false,
  },
  mutations: {
    SET_PAIRS(state, pairs: PairListingItem[]) {
      state.pairs = pairs;
    },
    UPDATE_PAIR(
      state,
      {
        symbol,
        partial,
      }: { symbol: string; partial: Partial<PairListingItem> },
    ) {
      state.pairs = state.pairs.map((pair) =>
        pair.symbol === symbol ? { ...pair, ...partial } : pair,
      );
    },
    SET_LOADING(state, loading: boolean) {
      state.loading = loading;
    },
  },
  actions: {
    async loadPairs({ commit }) {
      const accountId = this.state.auth.account?.id;
      commit('SET_LOADING', true);

      try {
        const pairs = await getSymbolList(accountId);

        commit('SET_PAIRS', pairs);
      } catch (e) {
        commit('SET_PAIRS', []);
        captureException(e);
      }

      commit('SET_LOADING', false);
    },
  },
};
