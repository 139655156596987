import { EditPositionParams, Position } from '@/margin/types';
import { Module } from 'vuex';
import {
  closePosition,
  editPosition,
  getPositionList,
  reversePosition,
} from '@/margin/api';
import { StoreState } from '@/margin/store/index.ts';
import { interval, removeInterval } from '@/margin/utils/interval.ts';
import { Interval, UPDATE_POSITIONS_INTERVAL } from '@/margin/const.ts';
import { captureException } from '@sentry/vue';
import { toChunks } from '../utils';

export interface PositionsState {
  positions: Position[];
  loading: boolean;
}

export const PositionsModule: Module<PositionsState, StoreState> = {
  namespaced: true,
  state: {
    positions: [],
    loading: true,
  },
  mutations: {
    SET_POSITIONS(state, positions: Position[]) {
      state.positions = positions;
    },
    SET_LOADING(state, loading: boolean) {
      state.loading = loading;
    },
  },
  actions: {
    async loadPositions({ commit }, ignoreLoading = false) {
      const accountId = this.state.auth.account?.id;

      removeInterval(Interval.Positions);

      if (!accountId) return;

      if (!ignoreLoading) {
        commit('SET_LOADING', true);
      }

      const setPositions = async () =>
        commit('SET_POSITIONS', await getPositionList(accountId));

      try {
        await setPositions();
        interval(setPositions, UPDATE_POSITIONS_INTERVAL, Interval.Positions);
      } catch (e) {
        commit('SET_POSITIONS', []);
        captureException(e);
      }

      if (!ignoreLoading) {
        commit('SET_LOADING', false);
      }
    },
    clearPositions({ commit }) {
      commit('SET_POSITIONS', []);
    },
    async closeAllPositions({ state }) {
      const accountId = this.state.auth.account?.id;

      if (!accountId) return;

      let lastError: Error;

      for (const positions of toChunks(state.positions, 10)) {
        try {
          await Promise.all(
            positions.map((position) => closePosition(accountId, position.id)),
          );
        } catch (e) {
          lastError = e;
          captureException(e);
        }
      }

      this.dispatch('positions/loadPositions', true);

      if (lastError) throw lastError;
    },
    async closePosition(
      _,
      { orderId, size }: { orderId: number; size?: number },
    ) {
      const accountId = this.state.auth.account?.id;

      if (!accountId) return;

      await closePosition(accountId, orderId, size);

      this.dispatch('positions/loadPositions', true);
    },
    async reversePosition(_, id: number) {
      const accountId = this.state.auth.account?.id;

      if (!accountId) return;

      await reversePosition(accountId, id);

      this.dispatch('positions/loadPositions', true);
    },
    async editPosition(_, position: EditPositionParams) {
      const accountId = this.state.auth.account?.id;

      if (!accountId) return;

      await editPosition(accountId, {
        id: position.id,
        takeProfit: position.takeProfit,
        stopLoss: position.stopLoss,
      });

      this.dispatch('positions/loadPositions', true);
    },
  },
  getters: {
    totalUnrealizedPnL: (state) => {
      return state.positions.reduce(
        (acc, position) => acc + (position.unrealizedPnL ?? 0),
        0,
      );
    },
  },
};
