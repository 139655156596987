import { OrderSide, OrderType } from '@/margin/types';
import { i18n } from '../i18n';

export const toOrderTypeName = (type: OrderType, side?: OrderSide) => {
  const types = {
    [OrderType.Market]: i18n.global.t('orders.market'),
    [OrderType.Limit]: i18n.global.t('orders.limit'),
    [OrderType.Stop]: i18n.global.t('orders.stop'),
    [OrderType.StopLimit]: i18n.global.t('orders.stopLimit'),
  };

  const sides = {
    [OrderSide.Buy]: i18n.global.t('orders.buy'),
    [OrderSide.Sell]: i18n.global.t('orders.sell'),
  };

  const typesWidthSide = {
    [OrderSide.Buy]: {
      [OrderType.Market]: i18n.global.t('orders.buyMarket'),
      [OrderType.Limit]: i18n.global.t('orders.buyLimit'),
      [OrderType.Stop]: i18n.global.t('orders.buyStop'),
      [OrderType.StopLimit]: i18n.global.t('orders.buyStopLimit'),
    },
    [OrderSide.Sell]: {
      [OrderType.Market]: i18n.global.t('orders.sellMarket'),
      [OrderType.Limit]: i18n.global.t('orders.sellLimit'),
      [OrderType.Stop]: i18n.global.t('orders.sellStop'),
      [OrderType.StopLimit]: i18n.global.t('orders.sellStopLimit'),
    },
  };

  if (type && side) return typesWidthSide[side][type] ?? `${side} ${type}`;

  if (type && !side) return types[type] ?? type;

  if (!type && side) return sides[side] ?? side;

  return '';
};
