import { ref } from 'vue';

export interface Notification {
  title: string;
  description?: string;
  type?: 'success' | 'danger';
}
export const notification = ref<Notification>(null);

export const useNotification = () => {
  const show = (options: Notification) => {
    notification.value = options;
  };

  const showSuccess = (title: string, description?: string) => {
    show({ title, description, type: 'success' });
  };

  const showError = (title: string, description?: string) => {
    show({ title, description, type: 'danger' });
  };

  return {
    show,
    showSuccess,
    showError,
  };
};
