<template>
  <svg
    width="26"
    height="26"
    viewBox="0 0 26 26"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M22.8836 22.5978H3.1151C2.73402 22.5978 2.3819 22.3945 2.19136 22.0645C2.00083 21.7345 2.00083 21.3279 2.19137 20.9978L12.0751 3.93117C12.2658 3.60169 12.6176 3.3988 12.9983 3.3988C13.379 3.3988 13.7308 3.60169 13.9215 3.93117L23.8052 20.9978C23.9957 21.3277 23.9958 21.7341 23.8055 22.064C23.6152 22.394 23.2635 22.5974 22.8826 22.5978H22.8836ZM12.9999 6.59783L4.96684 20.4645H21.0351L12.9999 6.59783ZM14.0612 16.1989H11.9279V10.8645H14.0612V16.1989Z"
      fill="currentColor"
    />
    <path
      d="M11.9332 17.2666H14.0666V19.4H11.9332V17.2666Z"
      fill="currentColor"
    />
  </svg>
</template>
