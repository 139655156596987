<script setup lang="ts" generic="T">
import { PropType, ref, toRef } from 'vue';
import Dropdown from 'primevue/dropdown';
import IconCaretDown from '../Icon/IconCaretDown.vue';
import { Option } from '@/margin/types';
import { useSelectedDropdownOption } from '@/margin/composeables/useSelectedDropdownOption';

const props = defineProps({
  options: {
    type: Array as PropType<Option<T>[]>,
    required: true,
  },
  label: {
    type: String,
    required: true,
  },
  matchByKey: {
    type: String,
    default: null,
  },
});

const model = defineModel({
  required: true,
  type: null as unknown as PropType<T>,
});

const dropdownOpen = ref(false);

const options = toRef(props, 'options');
const matchByKey = toRef(props, 'matchByKey');
const selectedOption = useSelectedDropdownOption(model, options, matchByKey);
</script>

<template>
  <Dropdown
    v-model="model"
    :options="options"
    option-label="label"
    option-value="value"
    :pt="{
      root: 'w-fit h-8 pr-2 flex items-center justify-between bg-transparent text-m-white border-m-gray-600 border-[1px] rounded',
      input:
        'pl-3 font-sans text-sm block flex-auto bg-transparent border-none text-m-white dark:text-m-white placeholder:text-m-gray dark:placeholder:text-m-gray py-2.5 rounded-none transition duration-200 focus:outline-none focus:shadow-none relative cursor-pointer overflow-hidden overflow-ellipsis whitespace-nowrap appearance-none',
      panel: 'bg-m-black border bg-m-gray-800 border-m-gray-600 rounded',
      list: 'py-0',
      trigger: `flex items-center justify-center shrink-0 bg-transparent rounded-none ${dropdownOpen ? 'rotate-180' : ''}`,
      item: ({ context }) => ({
        class: context.focused ? 'bg-m-gray-600 px-2.5' : 'px-2.5',
      }),
    }"
    @show="dropdownOpen = true"
    @hide="dropdownOpen = false"
  >
    <template #dropdownicon>
      <IconCaretDown class="h-2.5 w-2.5 text-m-gray" />
    </template>

    <template #value="{ value }">
      <span class="text-sm text-m-gray">{{ label }}:</span>
      {{ selectedOption?.label ?? value }}
    </template>
  </Dropdown>
</template>
