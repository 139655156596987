<script setup lang="ts">
import KitDialog from '@/margin/components/Kit/KitDialog.vue';
import KitButton from '@/margin/components/Kit/KitButton.vue';
import { toOrderTypeName } from '@/margin/utils/helpers.ts';
import KitRow from '@/margin/components/Kit/KitRow.vue';
import KitBadge from '@/margin/components/Kit/KitBadge.vue';
import KitCoin from '@/margin/components/Kit/KitCoin.vue';
import { computed, onMounted, PropType, ref, toRef, watch } from 'vue';
import {
  ExpirationType,
  EditPositionForm,
  Position,
  OrderSide,
  SymbolInfo,
} from '@/margin/types';
import { useField, useForm } from 'vee-validate';
import { boolean, number, object } from 'yup';
import MarketOrderFieldTPSL from '@/margin/components/MarketOrder/Field/MarketOrderFieldTPSL.vue';
import { fractionsToPlaceholder, toFixedFractions } from '@/margin/utils';
import { canCalculateROE } from '@/margin/utils/orderForm.ts';
import { getSymbolInfo } from '@/margin/api';
import KitLastPrice from '../Kit/KitLastPrice.vue';
import KitPrice from '../Kit/KitPrice.vue';
import { useStore } from '@/margin/store';
import { useOrderForm } from '@/margin/composeables/useOrderForm';
import KitNumberField from '../Kit/KitNumberField.vue';

const props = defineProps({
  selectedPosition: {
    type: Object as PropType<Position>,
    required: true,
  },
  loading: {
    type: Boolean,
    required: false,
  },
});

const visible = defineModel({
  type: Boolean,
  required: true,
});

const isModify = defineModel('isModify', {
  type: Boolean,
  required: true,
});

const emit = defineEmits<{
  (e: 'edit', takeProfit: number, takeLoss: number): void;
  (e: 'close', size?: number): void;
}>();

const symbolInfo = ref<SymbolInfo>();
const store = useStore();

onMounted(async () => {
  symbolInfo.value = await getSymbolInfo(props.selectedPosition.pair.symbol);
});

watch(visible, (visible) => {
  if (visible) {
    takeProfit.value = props.selectedPosition.takeProfit;
    stopLoss.value = props.selectedPosition.stopLoss;
  }
});

const showRoe = computed(() =>
  canCalculateROE(
    store.state.auth.account?.currency,
    props.selectedPosition?.pair.quote,
  ),
);

const validationSchema = computed(() => ({
  size: number()
    .nullable()
    .min(symbolInfo.value?.minTradeAmount ?? 0)
    .max(props.selectedPosition.size),
  expirationType: number(),
  takeProfit: object({
    value: number().nullable(),
    asPercentage: boolean(),
  }),
  stopLoss: object({
    value: number().nullable(),
    asPercentage: boolean(),
  }),
}));

const { handleSubmit, errors } = useForm<EditPositionForm>({
  validationSchema,
  initialValues: {
    expirationType: ExpirationType.GoodTillCancel,
    takeProfit: {
      value: props.selectedPosition?.takeProfit,
      asPercentage: false,
    },
    stopLoss: {
      value: props.selectedPosition?.stopLoss,
      asPercentage: false,
    },
    size: props.selectedPosition?.size,
  },
});

const { value: takeProfit } = useField<number>('takeProfit.value');
const { value: stopLoss } = useField<number>('stopLoss.value');
const { value: tpAsPercentage } = useField<boolean>('takeProfit.asPercentage');
const { value: slAsPercentage } = useField<boolean>('stopLoss.asPercentage');
const { value: size } = useField<number>('size');

const submit = handleSubmit(async () => {
  if (isModify.value) {
    emit(
      'edit',
      tpPrice.value > 0
        ? toFixedFractions(tpPrice.value, props.selectedPosition.precision)
        : 0,
      slPrice.value > 0
        ? toFixedFractions(slPrice.value, props.selectedPosition.precision)
        : 0,
    );
  } else {
    emit(
      'close',
      size.value
        ? toFixedFractions(size.value, props.selectedPosition.sizePrecision)
        : undefined,
    );
  }
});

const { slExpectedReturn, tpExpectedReturn, slPrice, tpPrice } = useOrderForm({
  tpAsPercentage,
  slAsPercentage,
  tpOrRoe: takeProfit,
  slOrRoe: stopLoss,
  price: toRef(props.selectedPosition, 'entryPrice'),
  size: toRef(props.selectedPosition, 'size'),
  side: toRef(props.selectedPosition, 'side'),
  margin: toRef(props.selectedPosition, 'margin'),
});
</script>

<template>
  <KitDialog
    v-if="selectedPosition"
    v-model="visible"
    :title="$t('dialog.modifyPosition')"
    content-padding-smaller
    data-set="modifyPositionsDialog"
  >
    <div class="mb-4 flex">
      <KitButton
        :variant="isModify ? 'primary' : 'default'"
        data-set="modifyPositionsDialogModifyPositionButton"
        @click="isModify = true"
        >{{ $t('dialog.positionModify') }}</KitButton
      >
      <KitButton
        :variant="isModify ? 'default' : 'primary'"
        data-set="modifyPositionsDialogClosePositionButton"
        @click="isModify = false"
        >{{ $t('dialog.positionClose') }}</KitButton
      >
    </div>

    <KitRow :label="$t('dialog.id')" data-set="modifyPositionsDialogPositionId">
      <b>{{ selectedPosition.id }}</b></KitRow
    >
    <KitRow
      :label="$t('dialog.orderType')"
      data-set="modifyPositionsDialogOrderType"
    >
      <KitBadge
        :variant="
          selectedPosition.side === OrderSide.Buy ? 'success' : 'danger'
        "
        >{{
          toOrderTypeName(selectedPosition.type, selectedPosition.side)
        }}</KitBadge
      >
    </KitRow>
    <KitRow :label="$t('dialog.symbol')" data-set="modifyPositionsDialogSymbol">
      <KitCoin :pair="selectedPosition.pair" />
    </KitRow>
    <KitRow
      :label="isModify ? $t('dialog.entryPrice') : $t('dialog.openPrice')"
      data-set="modifyPositionsDialogEntryOrOpenPrice"
    >
      <b
        ><KitPrice
          :value="selectedPosition.entryPrice"
          :symbol="selectedPosition.pair?.symbol"
      /></b>
      <span class="ml-1">{{ selectedPosition.pair?.name.quote }}</span>
    </KitRow>
    <KitRow
      :label="$t('dialog.currentPrice')"
      data-set="modifyPositionsDialogCurrentPrice"
    >
      <KitLastPrice
        class="font-bold"
        :symbol="selectedPosition.pair.symbol"
        :precision="symbolInfo?.precision ?? 2"
        :fallback-price="selectedPosition.currentPrice"
        :suffix="selectedPosition.pair.name.quote"
      />
    </KitRow>

    <KitRow
      v-if="!isModify"
      :label="$t('dialog.stopLoss')"
      data-set="modifyPositionsDialogStopLoss"
    >
      <b
        ><KitPrice
          :value="selectedPosition.stopLoss"
          :symbol="selectedPosition.pair?.symbol"
      /></b>
      <span class="ml-1">{{ selectedPosition.pair?.name.quote }}</span>
    </KitRow>

    <KitRow
      v-if="!isModify"
      :label="$t('dialog.takeProfit')"
      data-set="modifyPositionsDialogTakeProfit"
    >
      <b>
        <KitPrice
          :value="selectedPosition.takeProfit"
          :symbol="selectedPosition.pair?.symbol"
        />
      </b>
      <span class="ml-1">{{ selectedPosition.pair?.name.quote }}</span>
    </KitRow>

    <KitRow
      v-if="isModify"
      :label="$t('dialog.size')"
      data-set="modifyPositionsDialogSize"
    >
      <b>{{ $num(selectedPosition.size, 'auto') }}</b>
      {{ selectedPosition.pair?.name.base }}
    </KitRow>

    <KitNumberField
      v-if="!isModify"
      v-model="size"
      class="mt-4"
      :label="$t('dialog.size')"
      data-test-id="modifyPositionsDialogSize"
      :placeholder="fractionsToPlaceholder(selectedPosition.sizePrecision)"
      :suffix="selectedPosition.pair?.name.base"
      :invalid="errors.size"
      :min="symbolInfo?.minTradeAmount"
      :max="props.selectedPosition.size"
      :step="symbolInfo?.tradeAmountStep ?? 0.1"
      :max-fraction="selectedPosition.sizePrecision"
    />

    <MarketOrderFieldTPSL
      v-if="isModify"
      v-model:takeProfit="takeProfit"
      v-model:tpAsPercentage="tpAsPercentage"
      v-model:stopLoss="stopLoss"
      v-model:slAsPercentage="slAsPercentage"
      :pair="selectedPosition?.pair"
      :precision="symbolInfo?.precision"
      :actual-sl="slPrice"
      :actual-tp="tpPrice"
      class="mt-4"
      data-set="modifyPositionsDialogTpSl"
      :take-profit-expected-return="tpExpectedReturn"
      :stop-loss-expected-return="slExpectedReturn"
      :show-roe="showRoe"
    />

    <div>
      <div class="mt-8 flex gap-6">
        <KitButton
          :disabled="loading"
          data-set="modifyPositionsDialogCancelButton"
          @click="visible = false"
          >{{ $t('dialog.cancel') }}</KitButton
        >
        <KitButton
          variant="primary"
          visible-arrow-right
          :loading="loading"
          data-set="modifyPositionsDialogConfirmButton"
          @click="submit()"
          >{{ $t('dialog.confirm') }}</KitButton
        >
      </div>
    </div>
  </KitDialog>
</template>
