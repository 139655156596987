<script setup lang="ts">
import { computed } from 'vue';

const id = `linear_gradient_${Math.random()}`;

const props = defineProps({
  gradient: {
    type: Boolean,
    default: true,
  },
});

const fillColor = computed(() => {
  return props.gradient ? `url(#${id})` : 'currentColor';
});
</script>

<template>
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M6.00004 13.3333L2.66671 10.6666L6.00004 7.99996V9.99996H14.6667V11.3333H6.00004V13.3333ZM10 7.99996V5.99996H1.33337V4.66663H10V2.66663L13.3334 5.33329L10 7.99996Z"
      :fill="fillColor"
    />
    <defs v-if="props.gradient">
      <linearGradient
        :id="id"
        x1="1.33337"
        y1="7.99991"
        x2="14.6934"
        y2="7.99991"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#FEC82F" />
        <stop offset="1" stop-color="#F49600" />
      </linearGradient>
    </defs>
  </svg>
</template>
