import { Ref, computed, onUnmounted, ref, watch } from 'vue';
import { OrderBookCallback, orderBook } from '../utils/orderBook';

export const useLastPrice = (symbol: Ref<string>) => {
  const lastPrice = ref<number>(null);
  const previousPrice = ref<number>(null);
  const lastPriceMoveUp = computed(() => lastPrice.value > previousPrice.value);

  const onData: OrderBookCallback = (data) => {
    previousPrice.value = lastPrice.value;
    lastPrice.value = data.lastPrice;
  };

  watch(
    symbol,
    (newSymbol, oldSymbol) => {
      if (oldSymbol) {
        orderBook.off(oldSymbol, onData);
      }

      if (newSymbol) {
        orderBook.on(newSymbol, onData);
      }
    },
    { immediate: true },
  );

  onUnmounted(() => {
    orderBook.off(symbol.value, onData);
  });

  return {
    lastPrice,
    lastPriceMoveUp,
  };
};
