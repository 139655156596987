<script setup lang="ts">
import { onMounted, onUnmounted, ref, watch } from 'vue';
import { MAX_BAR_COUNT, getBarList } from '@/margin/api/tradingView';
import { useStore } from '@/margin/store';
import {
  IChartingLibraryWidget,
  ResolutionString,
  widget,
  Bar,
} from '@/../public/tradingView/charting_library/charting_library.esm.js';
import { useI18n } from 'vue-i18n';
import { localeToTradingViewLocale } from '@/margin/i18n.ts';
import { DEFAULT_PAIR_SYMBOL } from '@/margin/store/currencyPairModule.ts';
import {
  TWResolutionToAPI,
  roundToClosestTime,
} from '@/margin/utils/resolution';
import { useEventEmitter } from '@/margin/composeables/useEvent';
import { useNetwork } from '@vueuse/core';
import { Order, OrderSide, Position } from '@/margin/types';
import { toOrderTypeName } from '@/margin/utils/helpers';
import { getPairPrecision } from '@/margin/utils/coin';
import { toPair } from '@/margin/utils/trading';

const RESOLUTIONS = [
  '1',
  '15',
  '60',
  '1D',
  '2D',
  '1W',
  '1M',
] as ResolutionString[];
const DEFAULT_RESOLUTION = '15' as ResolutionString;
const BORDER_COLOR = '#31333B';
const GREEN_COLOR = '#27AE60';
const RED_COLOR = '#EC5656';

const store = useStore();
const { locale } = useI18n();
const { isOnline } = useNetwork();

const chartWidget = ref<IChartingLibraryWidget>();
const priceChanged = useEventEmitter();
const backToOnline = useEventEmitter();

const currentBar = ref<Bar & { symbol: string }>();
const lowestPrice = ref(0);
const highestPrice = ref(0);

let orderLines = [];
let positionLines = [];
let isChartReady = false;

const createOrderLine = (
  id: number,
  price: number,
  size: number,
  side: OrderSide,
) => {
  const color = side === OrderSide.Buy ? GREEN_COLOR : RED_COLOR;

  try {
    return chartWidget.value
      .activeChart()
      .createOrderLine()
      .setTooltip(id.toString())
      .setPrice(price)
      .setText(`${id} ${toOrderTypeName(null, side)}`)
      .setQuantity(size.toString())
      .setLineStyle(1)
      .setQuantityBackgroundColor(color)
      .setQuantityBorderColor(BORDER_COLOR)
      .setBodyBorderColor(BORDER_COLOR)
      .setBodyTextColor('black')
      .setLineColor(color)
      .setLineLength(80);
  } catch (error) {
    console.error(error);
  }
};

const createTPSLLine = (
  id: number,
  price: number,
  side: OrderSide,
  name: string,
  color: string,
  lineHeight: number,
) => {
  try {
    return chartWidget.value
      .activeChart()
      .createOrderLine()
      .setTooltip(id.toString())
      .setPrice(price)
      .setText(`${id} ${toOrderTypeName(null, side)}`)
      .setQuantity(name)
      .setLineStyle(1)
      .setBodyBackgroundColor(color)
      .setQuantityBackgroundColor('white')
      .setQuantityBorderColor(BORDER_COLOR)
      .setBodyBorderColor(BORDER_COLOR)
      .setQuantityTextColor(BORDER_COLOR)
      .setLineColor(color)
      .setBodyTextColor('white')
      .setLineLength(lineHeight);
  } catch (error) {
    console.error(error);
  }
};
const renderTPSL = (id: number, side: OrderSide, tp: number, sl: number) =>
  [
    tp && createTPSLLine(id, tp, side, 'TP', RED_COLOR, 30),
    sl && createTPSLLine(id, sl, side, 'SL', GREEN_COLOR, 5),
  ].filter(Boolean);

const renderOrders = (orders: Order[]) => {
  if (!isChartReady) return;

  orderLines.forEach((line) => line?.remove());
  orderLines = [];

  if (!store.state.settings.showOrderOnChart) return;

  orders
    .filter(
      (order) => order.pair.symbol === store.state.currencyPair.selected.symbol,
    )
    .forEach((order) => {
      const line = createOrderLine(
        order.id,
        order.price,
        order.size,
        order.side,
      );
      orderLines.push(line);

      if (store.state.settings.showTPSLOnChart) {
        const lines = renderTPSL(
          order.id,
          order.side,
          order.takeProfit,
          order.stopLoss,
        );
        orderLines.push(...lines);
      }
    });
};

const renderPositions = (positions: Position[]) => {
  if (!isChartReady) return;

  positionLines.forEach((line) => line?.remove());
  positionLines = [];

  if (!store.state.settings.showPositionsOnChart) return;

  positions
    .filter(
      (position) =>
        position.pair.symbol === store.state.currencyPair.selected.symbol,
    )
    .forEach((position) => {
      const positionLine = createOrderLine(
        position.id,
        position.entryPrice,
        position.size,
        position.side,
      );
      positionLines.push(positionLine);

      if (store.state.settings.showTPSLOnChart) {
        const lines = renderTPSL(
          position.id,
          position.side,
          position.takeProfit,
          position.stopLoss,
        );
        positionLines.push(...lines);
      }
    });
};

const refreshOrdersAndPositions = () => {
  renderOrders(store.state.orders.orders);
  renderPositions(store.state.positions.positions);
};

const addIndicators = () => {
  const chart = chartWidget.value.activeChart();
  chart.createStudy('Moving Average', false, false, { length: 7 });
  chart.createStudy('Moving Average', false, false, { length: 25 });
  chart.createStudy('Moving Average', false, false, { length: 99 });
  chart.createStudy('Volume', false, false);
};

const createWidget = () => {
  chartWidget.value?.remove();

  // NOTE: We are using requestAnimationFrame to suppress warnings from TradingView about callbacks being called synchronously

  chartWidget.value = new widget({
    container: 'margin-trading-view',
    locale: localeToTradingViewLocale(locale.value),
    library_path: '/tradingView/charting_library/',
    symbol: store.state.currencyPair.selected?.symbol || DEFAULT_PAIR_SYMBOL,
    interval: DEFAULT_RESOLUTION,
    debug: false,
    autosize: true,
    theme: 'dark',
    timezone: 'Etc/UTC',
    disabled_features: [
      'header_symbol_search',
      'header_compare',
      'edit_buttons_in_legend',
    ],
    datafeed: {
      onReady: (callback) => {
        requestAnimationFrame(() =>
          callback({
            supported_resolutions: RESOLUTIONS,
            exchanges: [],
            symbols_types: [{ name: 'crypto', value: 'crypto' }],
          }),
        );
      },
      searchSymbols: (
        userInput,
        exchange,
        symbolType,
        onResultReadyCallback,
      ) => {
        requestAnimationFrame(() => onResultReadyCallback([]));
      },
      resolveSymbol: async (symbolName, onSymbolResolvedCallback) => {
        const precision = await getPairPrecision(symbolName);
        const pair = await toPair(symbolName);

        onSymbolResolvedCallback({
          ticker: symbolName,
          name: pair?.name.symbol,
          description: pair.name.symbol,
          type: 'crypto',
          session: '24x7',
          timezone: 'Etc/UTC',
          exchange: 'crypto',
          has_seconds: true,
          minmov: 1,
          pricescale: Math.pow(10, precision.precision),
          has_intraday: true,
          visible_plots_set: 'ohlc',
          has_weekly_and_monthly: true,
          supported_resolutions: RESOLUTIONS,
          volume_precision: 2,
          data_status: 'streaming',
          listed_exchange: 'crypto',
          format: 'price',
        });
      },
      getBars: async (
        symbolInfo,
        resolution,
        periodParams,
        onHistoryCallback,
        onErrorCallback,
      ) => {
        const from = periodParams.from * 1000;
        const to = Math.min(
          roundToClosestTime(Date.now(), resolution),
          periodParams.to * 1000,
        );

        try {
          const bars = await getBarList(
            symbolInfo.ticker,
            TWResolutionToAPI(resolution),
            to,
            Math.min(periodParams.countBack, MAX_BAR_COUNT),
          );

          const validBars = bars.filter(
            (bar) => bar.time >= from && bar.time <= to,
          );

          onHistoryCallback(validBars, { noData: validBars.length === 0 });
        } catch (error) {
          onErrorCallback(error.message);
        }
      },
      subscribeBars: async (
        symbolInfo,
        resolution,
        onRealtimeCallback,
        subscriberUID,
        onResetCacheNeededCallback,
      ) => {
        const symbol = symbolInfo.ticker;
        const lastHistoryTime = roundToClosestTime(
          roundToClosestTime(Date.now(), resolution) - 1,
          resolution,
        );
        const lastHistoryBar =
          (
            await getBarList(
              symbolInfo.ticker,
              TWResolutionToAPI(resolution),
              lastHistoryTime,
              1,
            )
          )?.[0] ?? null;

        priceChanged.on(subscriberUID, () => {
          const time = roundToClosestTime(Date.now(), resolution);
          const lastPrice =
            store.state.orderBook.symbol === symbol
              ? store.state.orderBook.lastPrice
              : null;

          if (!lastPrice) return;

          const current =
            currentBar.value?.symbol === symbol ? currentBar.value : null;
          const isNewBar = current?.time !== time;
          const previousUpdate = isNewBar ? null : currentBar.value;

          // Reset bars from history on last bar closed
          if (isNewBar && current) {
            onResetCacheNeededCallback();
            chartWidget.value?.chart()?.resetData();
          }

          lowestPrice.value = isNewBar
            ? lastPrice
            : Math.min(lowestPrice.value, lastPrice);
          highestPrice.value = isNewBar
            ? lastPrice
            : Math.max(highestPrice.value, lastPrice);

          const bar = {
            time,
            open:
              previousUpdate?.open ??
              current?.close ??
              lastHistoryBar?.close ??
              lastPrice,
            close: lastPrice,
            high: highestPrice.value,
            low: lowestPrice.value,
          };
          onRealtimeCallback(bar);
          currentBar.value = { ...bar, symbol };
        });

        backToOnline.on(subscriberUID, () => {
          onResetCacheNeededCallback();
          chartWidget.value?.chart()?.resetData();
        });
      },
      unsubscribeBars: (subscriberUID) => {
        priceChanged.off(subscriberUID);
        backToOnline.off(subscriberUID);
      },
    },
  });

  chartWidget.value.onChartReady(() => {
    isChartReady = true;
    refreshOrdersAndPositions();
    addIndicators();
  });
};

onMounted(() => createWidget());
onUnmounted(() => chartWidget.value?.remove());

watch(locale, () => createWidget());
watch(() => store.state.orders.orders, renderOrders);
watch(() => store.state.positions.positions, renderPositions);
watch(
  () =>
    [
      store.state.settings?.showOrderOnChart,
      store.state.settings?.showPositionsOnChart,
      store.state.settings?.showTPSLOnChart,
    ].join(''),
  refreshOrdersAndPositions,
);

watch(
  () => store.state.currencyPair.selected?.symbol,
  (symbol) => {
    chartWidget.value.setSymbol(
      symbol,
      DEFAULT_RESOLUTION,
      refreshOrdersAndPositions,
    );
  },
);

watch(
  () => store.state.orderBook.lastPrice,
  () => priceChanged.emitAll(),
);

watch(isOnline, (online) => {
  if (online) backToOnline.emitAll();
});
</script>

<template>
  <div id="margin-trading-view" class="h-full w-full"></div>
</template>
