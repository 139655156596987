import moment from 'moment/moment';

export enum DateRangeType {
  Today,
  Yesterday,
  LastWeek,
  LastMonth,
  Last3Months,
  Last6Months,
  LastYear,
  AllTime,
}

export interface DateRange {
  from: Date;
  to: Date;
}

export const getDateRange = (range: DateRangeType): DateRange => {
  if (range === DateRangeType.Today) {
    return {
      from: moment().startOf('day').toDate(),
      to: moment().endOf('day').toDate(),
    };
  }

  if (range === DateRangeType.Yesterday) {
    return {
      from: moment().subtract(1, 'days').startOf('day').toDate(),
      to: moment().subtract(1, 'days').endOf('day').toDate(),
    };
  }

  if (range === DateRangeType.LastWeek) {
    return {
      from: moment().subtract(1, 'weeks').startOf('day').toDate(),
      to: moment().endOf('day').toDate(),
    };
  }

  if (range === DateRangeType.LastMonth) {
    return {
      from: moment().subtract(1, 'months').startOf('day').toDate(),
      to: moment().endOf('day').toDate(),
    };
  }

  if (range === DateRangeType.Last3Months) {
    return {
      from: moment().subtract(3, 'months').startOf('day').toDate(),
      to: moment().endOf('day').toDate(),
    };
  }

  if (range === DateRangeType.Last6Months) {
    return {
      from: moment().subtract(6, 'months').startOf('day').toDate(),
      to: moment().endOf('day').toDate(),
    };
  }

  if (range === DateRangeType.LastYear) {
    return {
      from: moment().subtract(1, 'years').startOf('day').toDate(),
      to: moment().endOf('day').toDate(),
    };
  }

  if (range === DateRangeType.AllTime) {
    return {
      from: null,
      to: moment().endOf('day').toDate(),
    };
  }
};
