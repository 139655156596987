<script lang="ts" setup>
import IconCheck from '../Icon/IconCheck.vue';

const model = defineModel({
  type: Boolean,
  default: false,
});
</script>
<template>
  <div
    :class="[
      'flex h-[24px] w-[38px] cursor-pointer items-center rounded-full border p-[1px]',
      {
        'border-m-success-900 bg-m-success-900': model,
        'border-m-gray-150 bg-m-gray-100': !model,
      },
    ]"
    @click="model = !model"
  >
    <div
      :class="[
        'flex h-5 w-5 items-center justify-center rounded-full border-[0.5px] border-m-gray-150 bg-m-white shadow transition-all',
        {
          'translate-x-[14px]': model,
        },
      ]"
    >
      <IconCheck v-if="model" class="h-2 w-2 text-m-success-900" />
    </div>
  </div>
</template>
