<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
  >
    <path
      d="M13 7.83L16.59 11.41L18 10L12 4L6 10L7.41 11.41L11 7.83V20H13V7.83Z"
      fill="currentColor"
    />
  </svg>
</template>
