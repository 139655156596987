<script setup lang="ts">
import KitCheckbox from '../Kit/KitCheckbox.vue';
import IconTrashBin from '@/margin/components/Icon/IconTrashBin.vue';

defineProps({
  disabledCloseAll: Boolean,
});

defineEmits(['closeAll']);

const buy = defineModel('buy', {
  type: Boolean,
  required: false,
});

const sell = defineModel('sell', {
  type: Boolean,
  required: false,
});

const sl = defineModel('sl', {
  type: Boolean,
  required: false,
});

const tp = defineModel('tp', {
  type: Boolean,
  required: false,
});
</script>

<template>
  <div
    class="flex flex-wrap items-center justify-between gap-2 @container/filter"
  >
    <div
      class="card justify-content-center flex flex-wrap gap-3 border border-solid border-m-gray-600 bg-m-gray-800 p-2 @md/filter:w-full @md/filter:max-w-[330px]"
    >
      <div class="flex items-center text-sm leading-4 text-m-gray">
        {{ $t('orders.filter') }}:
      </div>
      <div class="flex items-center gap-2 @md/filter:gap-4">
        <KitCheckbox v-model="buy" :label="$t('orders.filter.buy')" />
        <KitCheckbox v-model="sell" :label="$t('orders.filter.sell')" />
        <KitCheckbox v-model="sl" :label="$t('orders.filter.sl')" nullable />
        <KitCheckbox v-model="tp" :label="$t('orders.filter.tp')" nullable />
      </div>
    </div>

    <button
      class="flex shrink-0 items-center gap-1 border border-m-gray-600 p-2 text-xs text-m-danger hover:bg-m-gray-700 disabled:cursor-not-allowed disabled:bg-m-gray-700 disabled:text-m-gray"
      :disabled="disabledCloseAll"
      @click="$emit('closeAll')"
    >
      <IconTrashBin class="h-4 w-4" />
      {{ $t('orders.filter.closeAll') }}
    </button>
  </div>
</template>
