<script setup lang="ts">
import { markRaw, ref, onMounted, computed, watch } from 'vue';
import KitSearch from '../Kit/KitSearch.vue';
import KitTabs from '../Kit/KitTabs.vue';
import { Tab, TableColumn } from '@/margin/types';
import StarFullIcon from '../Icon/IconStarFull.vue';
import KitTable from '../Kit/KitTable.vue';
import KitCoin from '../Kit/KitCoin.vue';
import KitChange from '../Kit/KitChange.vue';
import KitAreaChart from '../Kit/KitAreaChart.vue';
import { useStore } from '@/margin/store';
import { toSearchText } from '@/margin/utils';
import { useDebounceFn } from '@vueuse/core';
import KitStar from '@/margin/components/Kit/KitStar.vue';
import { useI18n } from 'vue-i18n';

enum TabId {
  Favorites = 'favorites',
  All = 'all',
}

defineEmits<{
  (e: 'select', symbol: string);
}>();

const store = useStore();
const { t } = useI18n();

const columns: TableColumn[] = [
  {
    field: 'name',
    header: t('ticker.table.name'),
    sortable: true,
    sortField: 'symbol',
  },
  {
    field: 'lastPrice',
    header: t('ticker.table.lastPrice'),
    sortable: true,
    sortField: 'detail.lastPrice',
  },
  {
    field: 'volume',
    header: t('ticker.table.volume'),
    sortable: true,
    sortField: 'detail.volume',
  },
  {
    field: 'change24',
    header: t('ticker.table.change'),
    sortable: true,
    sortField: 'detail.change.percentage',
  },
];

const tabs = computed<Tab[]>(() =>
  [
    store.state.auth.isAuthenticated
      ? {
          id: TabId.Favorites,
          title: t('ticker.table.tab.favorites'),
          icon: markRaw(StarFullIcon),
        }
      : null,
    {
      id: TabId.All,
      title: t('ticker.table.tab.all'),
    },
  ].filter(Boolean),
);

const search = ref('');
const searchText = ref('');
const favoriteSymbols = ref(store.state.favorite.symbols);
const tab = ref(
  favoriteSymbols.value.length && store.state.auth.isAuthenticated
    ? TabId.Favorites
    : TabId.All,
);

const onSearch = useDebounceFn(() => {
  searchText.value = search.value;
}, 500);
watch(search, onSearch);

const pairs = computed(() => {
  const search = toSearchText(searchText.value);

  return store.state.pairListing.pairs.filter((pair) => {
    if (
      !search &&
      tab.value === TabId.Favorites &&
      !favoriteSymbols.value.includes(pair.symbol)
    ) {
      return false;
    }

    if (search) {
      const text = `${pair.pair.name?.base}${pair.pair.name?.quote} ${pair.pair.name?.symbol ?? pair.symbol}`;
      return toSearchText(text).includes(search);
    }

    return true;
  });
});

onMounted(() => {
  store.dispatch('pairListing/loadPairs');
});

watch(tab, () => {
  favoriteSymbols.value = store.state.favorite.symbols;
});
</script>

<template>
  <div class="flex h-full w-full flex-col overflow-hidden">
    <KitSearch
      v-model="search"
      :placeholder="$t('ticker.table.search')"
      autofocus
    />
    <KitTabs
      v-model="tab"
      :tabs="tabs"
      class="mt-4"
      border-color="bg-m-gray-500"
    />

    <KitTable
      :data="pairs"
      :columns="columns"
      :loading="$store.state.pairListing.loading"
      :loading-rows="13"
      :item-size="36"
      :items-outside-view="30"
      scroll-height="500px"
      header-background="bg-m-gray-600"
      sort-field="symbol"
      :sort-order="1"
      data-key="symbol"
      class="h-full cursor-pointer overflow-auto bg-m-gray-600 pt-4"
      variant="secondary"
      @row-click="$emit('select', $event.data.symbol)"
    >
      <template #field-name="{ data }">
        <div class="flex cursor-pointer items-center gap-2">
          <KitStar
            v-if="$store.state.auth.isAuthenticated"
            class="text-m-primary"
            :selected="$store.state.favorite.symbols.includes(data.symbol)"
            @toggle="
              $store.dispatch('favorite/updateFavorite', {
                symbol: data.symbol,
                favorite: $event,
              })
            "
          />

          <div>
            <KitCoin :pair="data.pair" />
          </div>
        </div>
      </template>
      <template #field-lastPrice="{ data }">
        <div v-if="data.detail">
          {{ $num(data.detail?.lastPrice, 'auto') }}
          <span class="text-m-gray-400">{{ data.pair.name.quote }}</span>
        </div>
      </template>
      <template #field-volume="{ data }">
        <div v-if="data.detail">
          {{ $num(data.detail?.volume, 'auto') }}
          <span class="text-m-gray-400">{{ data.pair.name.base }}</span>
        </div>
      </template>
      <template #field-change24="{ data }">
        <div v-if="data.detail" class="flex cursor-pointer items-center gap-2">
          <KitAreaChart
            :data="data.detail?.chart ?? []"
            :width="30"
            :height="20"
            :variant="
              data.detail?.change?.percentage > 0 ? 'success' : 'danger'
            "
          />
          <KitChange :value="data.detail?.change?.percentage" suffix="%" />
        </div>
      </template>
    </KitTable>
  </div>
</template>
