import { getToken, post } from '@/margin/api';
import {
  CurrencyPairDetailDTO,
  CurrencyPairInfoDTO,
  PairDetail,
  PairInfo,
  PairListingItem,
  SymbolInfo,
  SymbolInfoDTO,
} from '@/margin/types';
import {
  EXPIRE_AFTER_5_MINUTES,
  EXPIRE_AFTER_DAY,
  EXPIRE_AFTER_HOUR,
  cache,
} from '@/margin/utils/cache';
import { toPairs } from '../utils/trading';
import { cachePrecision, symbolInfoToPrecision } from '../utils/coin';
import { captureException } from '@sentry/vue';

export const getPairDetail = async (
  symbol: string,
  ignoreRetrievingCache = false,
): Promise<PairDetail> => {
  const data = await post<CurrencyPairDetailDTO>(
    '/margin/symbol/data/',
    {
      symbol: symbol,
    },
    {
      retryCount: 1,
      cache: {
        dependencies: [symbol],
        expiration: EXPIRE_AFTER_5_MINUTES,
        ignoreRetrievingCache,
      },
    },
  );

  return {
    change: data.change,
    volume: data.volume,
    low: data.low,
    high: data.high,
    lastPrice: data.last_price,
    chart: data.prices.map((value, index) => ({
      x: index,
      y: value,
    })),
  };
};

export const getSymbolInfo = async (symbol: string): Promise<SymbolInfo> => {
  const data = await post<SymbolInfoDTO>(
    '/margin/symbol/info/',
    {
      symbol: symbol,
    },
    {
      retryCount: 10,
      cache: {
        dependencies: [symbol],
        expiration: EXPIRE_AFTER_HOUR,
      },
    },
  );

  if (!data) return null;

  const info: SymbolInfo = {
    symbol: data.symbol,
    precision: data.precision,
    maxTradeAmount: data.max_trade_amount,
    minTradeAmount: data.min_trade_amount,
    tradeAmountStep: data.trade_amount_step,
    commission: data.commission,
    defaultSlippage: data.default_slippage,
    swap: data.swap,
  };

  cachePrecision(symbol, symbolInfoToPrecision(info));

  return info;
};

export const getSymbolList = async (
  accountId: number,
): Promise<PairListingItem[]> => {
  const data = await post<{ [key: string]: CurrencyPairDetailDTO }>(
    '/margin/symbol/data/list/',
    {
      token: getToken(),
      account_id: accountId?.toString(),
    },
    {
      retryCount: 2,
      cache: {
        expiration: EXPIRE_AFTER_5_MINUTES,
        dependencies: [accountId],
      },
    },
  );

  const pairs = await toPairs(Object.keys(data));

  return Object.entries(data).map<PairListingItem>(([symbol, detail]) => ({
    symbol: symbol,
    pair: pairs[symbol],
    detail: {
      change: detail.change,
      volume: detail.volume,
      low: detail.low,
      high: detail.high,
      lastPrice: detail.last_price,
      chart: (detail.prices ?? []).map((value, index) => ({
        x: index,
        y: value,
      })),
    },
  }));
};

export const getSymbolStaticInfoList = async (): Promise<PairInfo[]> => {
  try {
    const data = await post<{ [key: string]: CurrencyPairInfoDTO }>(
      '/margin/symbol/list/',
      {},
      {
        retryCount: 1,
      },
    );

    return Object.entries(data ?? {}).map<PairInfo>(([symbol, detail]) => ({
      symbol: symbol,
      name: detail.extended_name,
    }));
  } catch (error) {
    captureException(error);
    return [];
  }
};

export const getSymbolNames = async (): Promise<{ [key: string]: string }> => {
  const cached = cache.get<{ [key: string]: string }>('symbolNames');

  if (cached) return cached;

  const list = await getSymbolStaticInfoList();

  const names = Object.fromEntries(
    list.map(({ symbol, name }) => [symbol, name]),
  );

  cache.set('symbolNames', names, {
    expiration: EXPIRE_AFTER_DAY,
  });

  return names;
};
