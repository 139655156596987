<script lang="ts" setup>
import { isNumber } from '@/margin/utils';
import InputNumber from 'primevue/inputnumber';
import { PropType, computed, ref, watch } from 'vue';

defineOptions({
  inheritAttrs: false,
});

const props = defineProps({
  class: {
    type: [String, Array, Object] as PropType<
      string | string[] | Record<string, boolean>
    >,
    default: '',
  },
  label: {
    required: true,
    type: String,
  },
  suffix: {
    type: String,
    default: null,
  },
  prefix: {
    type: String,
    default: null,
  },
  disabled: {
    type: Boolean,
    default: false,
  },
  invalid: {
    type: [Boolean, String],
    default: false,
  },
  minFraction: {
    type: Number,
    default: 1,
  },
  maxFraction: {
    type: Number,
    default: 6,
  },
  locale: {
    type: String,
    default: 'en-US',
  },
  min: {
    type: Number,
    default: null,
  },
  max: {
    type: Number,
    default: null,
  },
  step: {
    type: Number,
    default: 1,
  },
  dataTestId: {
    type: String,
    default: null,
  },
  initialValueOnArrow: {
    type: Number,
    default: null,
  },
});

const model = defineModel({
  required: true,
  type: Number,
});

const wrapper = ref<HTMLElement>();
const previousValue = ref<number | null>(null);

watch(model, (_, previous) => {
  previousValue.value = previous;
});

const validMaxFraction = computed(() => {
  if (isNumber(props.maxFraction)) {
    return Math.max(props.maxFraction, props.minFraction);
  }

  return undefined;
});

const decimalSeparators = computed(() => {
  const currentDecimalSeparator =
    new Intl.NumberFormat(props.locale)
      ?.formatToParts(0.1)
      ?.find((part) => part.type === 'decimal')?.value ?? '.';

  const alternativeDecimalSeparator =
    currentDecimalSeparator === '.' ? ',' : '.';

  return {
    currentDecimalSeparator,
    alternativeDecimalSeparator,
  };
});

const focus = () => {
  wrapper.value?.querySelector('input')?.focus();
};

const onInput = (value: string | number) => {
  model.value = isNumber(value) ? (value as number) : null;
};

const input = ref<InstanceType<typeof InputNumber>>();
const isFocused = ref(false);

const onArrow = () => {
  if (!previousValue.value && props.initialValueOnArrow) {
    model.value = props.initialValueOnArrow;
  }
};

const onKeydown = (event: KeyboardEvent) => {
  // Allow user typing alternative decimal separator even if current decimal separator is different
  if (event.key === decimalSeparators.value.alternativeDecimalSeparator) {
    const target = event.target as HTMLInputElement;

    const decimalIndex = target.value.indexOf(
      decimalSeparators.value.currentDecimalSeparator,
    );

    if (decimalIndex > 0 && decimalIndex === target.selectionEnd) {
      target.setSelectionRange(decimalIndex + 1, decimalIndex + 1);
    }
  }
};
</script>

<template>
  <div
    ref="wrapper"
    :class="[
      'flex p-[1px]',
      {
        'bg-gradient-to-r from-m-primary-400 to-m-primary-300':
          isFocused && !invalid,
        'bg-m-danger': invalid,
        'bg-m-gray-500': !invalid && !isFocused,
      },
      props.class,
    ]"
    :data-test-id="dataTestId"
    @click="focus"
  >
    <div
      class="flex w-full cursor-pointer items-center gap-1 bg-m-gray-500 p-2 text-sm"
    >
      <span class="whitespace-nowrap">{{ label }}</span>
      <div class="flex w-full items-center gap-1">
        <InputNumber
          ref="input"
          v-model="model"
          :prefix="prefix ? prefix + ' ' : null"
          unstyled
          :pt="{
            root: {
              class: 'h-full inline-flex flex-1 ',
            },
            input: {
              root: {
                class: `disabled:text-m-gray w-full h-full text-right focus:outline-none bg-transparent`,
              },
            },
          }"
          :disabled="disabled"
          :min="min"
          :max="max"
          :step="step"
          v-bind="$attrs"
          :min-fraction-digits="minFraction"
          :max-fraction-digits="validMaxFraction"
          :locale="locale"
          @focus="isFocused = true"
          @blur="isFocused = false"
          @input="onInput($event.value)"
          @keydown.arrow-up="onArrow()"
          @keydown.arrow-down="onArrow()"
          @keydown="onKeydown($event)"
        />
        <div v-if="suffix" :class="{ 'text-m-gray': disabled }">
          {{ suffix }}
        </div>
      </div>
    </div>
    <div
      v-if="$slots.suffix"
      class="border-l border-m-gray-600 bg-m-gray-500"
      @click.stop
    >
      <slot name="suffix"></slot>
    </div>
  </div>
</template>
