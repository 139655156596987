<template>
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M14.6584 4.16667L10.0001 8.82501L5.34175 4.16667L4.16675 5.34167L8.82508 10L4.16675 14.6583L5.34175 15.8333L10.0001 11.175L14.6584 15.8333L15.8334 14.6583L11.1751 10L15.8334 5.34167L14.6584 4.16667Z"
      fill="currentColor"
    />
  </svg>
</template>
