import { Pair } from '@/margin/utils/trading.ts';
import { AreaChartData } from '@/margin/types/ui.ts';

export * from './ui';
export * from './api';

export enum OrderBookType {
  Bids = 'bids',
  Asks = 'asks',
}

export interface OrderBookLevel {
  price: number;
  size: number;
  total: number;
  depth: number;
}

export enum WidgetType {
  Ticker = 'Ticker',
  Trading = 'Trading',
  OrderBook = 'OrderBook',
  MarketOrder = 'MarketOrder',
  Orders = 'Orders',
}

export interface LayoutPreference {
  i: WidgetType;
  x: number;
  y: number;
  w: number;
  h: number;
}

export enum OrderSide {
  Buy = 'Buy',
  Sell = 'Sell',
}

export enum OrderType {
  Market = 'Market',
  Limit = 'Limit',
  Stop = 'Stop',
  StopLimit = 'StopLimit',
}

export interface Position {
  id: number;
  type: OrderType;
  pair: Pair;
  side: OrderSide;
  size: number;
  filledAmount: number;
  filledDate: Date;
  entryPrice: number;
  currentPrice: number;
  commission: number;
  rebate: number;
  profit: number;
  unrealizedPnL: number;
  margin: number;
  stopLoss: number;
  takeProfit: number;
  profitROE: number;
  precision: number;
  sizePrecision: number;
  swap: number;
}

export interface Order {
  id: number;
  type: OrderType;
  side: OrderSide;
  pair: Pair;
  size: number;
  filledAmount: number;
  price: number;
  stopPrice: number;
  stopLoss: number;
  takeProfit: number;
  margin: number;
  time: Date;
  precision: number;
  sizePrecision: number;
}

export interface History {
  id: number;
  date: Date;
  side: OrderSide;
  pair: Pair;
  openPrice: number;
  size: number;
  initialSize: number;
  closePrice: number;
  commission: number;
  rebate: number;
  profit: number;
  precision: number;
  sizePrecision: number;
  swap: number;
}

export enum OrderBookSortOptions {
  All = 'All',
  Bids = 'Bids',
  Asks = 'Asks',
}

export enum ExpirationType {
  GoodTillCancel,
}

export interface MarketOrderForm {
  limitPrice?: number;
  stopPrice?: number;
  size: number;
  expirationType: ExpirationType;
  showTPSL: boolean;
  takeProfit: {
    value: number;
    asPercentage: boolean;
  };
  stopLoss: {
    value: number;
    asPercentage: boolean;
  };
}

export interface CreateOrderParams {
  type: OrderType;
  side: OrderSide;
  symbol: string;
  size: number;
  limitPrice?: number;
  stopLoss?: number;
  takeProfit?: number;
  stopPrice?: number;
}

export interface EditOrderForm {
  limitPrice: number;
  stopPrice: number;
  size: number;
  expirationType: ExpirationType;
  takeProfit: {
    value: number;
    asPercentage: boolean;
  };
  stopLoss: {
    value: number;
    asPercentage: boolean;
  };
}

export interface EditOrderParams {
  id: number;
  limitPrice: number;
  stopPrice: number;
  size: number;
  takeProfit: number;
  stopLoss: number;
}

export interface EditPositionForm {
  expirationType: ExpirationType;
  takeProfit: {
    value: number;
    asPercentage: boolean;
  };
  stopLoss: {
    value: number;
    asPercentage: boolean;
  };
  size: number;
}

export interface EditPositionParams {
  id: number;
  takeProfit: number;
  stopLoss: number;
}

export interface PairListingItem {
  symbol: string;
  pair: Pair;
  detail: PairDetail;
}

export interface PairInfo {
  symbol: string;
  name: string;
}

export interface PairDetail {
  change: {
    percentage: number;
    absolute: number;
  };
  volume: number;
  low: number;
  high: number;
  lastPrice: number;
  chart: AreaChartData[];
}

export interface CoinInfo {
  symbol: string;
  name: string;
  rank: number;
  marketCap: number;
  marketShare: number;
  maxSupply: number;
  totalSupply: number;
  circulatingSupply: number;
  listingDate: string;
  website: string;
  whitepaper: string;
  description: string;
}

export interface SymbolInfo {
  symbol: string;
  precision: number;
  maxTradeAmount: number;
  minTradeAmount: number;
  tradeAmountStep: number;
  commission: number;
  defaultSlippage: number;
  swap: number;
}

export interface SelectedCurrencyPair {
  symbol: string;
  accountId: number;
}

export interface CurrencyPair {
  symbol: string;
  accountId: number;
  pair: Pair;
  detail: PairDetail | null;
  coin: CoinInfo | null;
  info: SymbolInfo | null;
}

export interface BaseCurrencyPair {
  symbol: string;
  accountId: number;
}

export interface AccountInfo {
  id: number;
  marginLevel: number;
  margin: number;
  equity: number;
  balance: number;
  leverage: number;
  currency: string;
  profit: number;
  precision: number;
  isFiatOrStable: boolean;
}

export interface OrderBook {
  symbol: string;
  bids: number[][];
  asks: number[][];
  lastPrice: number;
}

export interface Account {
  id: number;
  currency: string;
  info: AccountInfo | null;
}

export interface MarginSettings {
  token: string;
  locale?: string;
  preferAccount?: string;
}
