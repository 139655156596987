<script lang="ts" setup>
import KitNumberField from '@/margin/components/Kit/KitNumberField.vue';
import KitPrice from '../../Kit/KitPrice.vue';
import { Pair } from '@/margin/utils/trading';
import { PropType } from 'vue';

defineProps({
  marginRequirement: {
    type: Number,
    required: true,
  },
  pair: {
    type: Object as PropType<Pair>,
    required: true,
  },
});

const model = defineModel({
  type: Number,
  required: true,
});
</script>

<template>
  <div>
    <KitNumberField
      v-model="model"
      :label="$t('marketOrder.total')"
      :suffix="pair?.name.quote"
      prefix="≈"
      placeholder="≈ 0"
      disabled
    />
    <div class="mt-1 flex items-center justify-between text-xs font-medium">
      <span>{{ $t('marketOrder.marginRequirement') }}</span>
      <span>
        <KitPrice :value="marginRequirement" :symbol="pair?.symbol" />
        {{ pair?.name.quote }}</span
      >
    </div>
  </div>
</template>
