<script lang="ts" setup>
import IconSearch from '../Icon/IconSearch.vue';
import { PropType, ref } from 'vue';

defineOptions({
  inheritAttrs: false,
});

const props = defineProps({
  size: {
    type: String as PropType<'default' | 'small'>,
    default: 'default',
  },
  class: {
    type: [String, Object, Array],
    default: '',
  },
});

const model = defineModel({
  type: String,
  required: true,
});

const focused = ref(false);
</script>

<template>
  <div
    :class="[
      props.class,
      'p-[1px]',
      {
        'bg-gradient-to-r from-m-primary-400 to-m-primary-300': focused,
        'bg-m-gray-500': !focused,
      },
    ]"
  >
    <div
      :class="[
        'flex items-center gap-1 bg-m-gray-500',
        {
          'p-2': size === 'default',
          'px-2 py-[5px]': size === 'small',
        },
      ]"
    >
      <IconSearch class="h-4 w-4 text-m-gray" />
      <input
        v-model="model"
        class="w-full bg-transparent text-sm text-m-white outline-none placeholder:text-m-gray"
        v-bind="$attrs"
        @focus="focused = true"
        @blur="focused = false"
      />
    </div>
  </div>
</template>
