<script setup lang="ts">
import KitButton from '../Kit/KitButton.vue';
import KitDialog from '@/margin/components/Kit/KitDialog.vue';

defineProps({
  title: {
    type: String,
    required: true,
  },
  loading: {
    type: Boolean,
    required: true,
  },
});

defineEmits(['closeAll']);

const visible = defineModel({
  type: Boolean,
  required: true,
});
</script>

<template>
  <KitDialog v-model="visible" variant="confirmation" :title="title">
    <div class="mt-4 flex gap-2">
      <KitButton
        variant="default"
        :disabled="loading"
        @click="visible = false"
        >{{ $t('dialog.no') }}</KitButton
      >

      <KitButton
        variant="danger"
        :loading="loading"
        @click="$emit('closeAll')"
        >{{ $t('dialog.closeAll') }}</KitButton
      >
    </div>
  </KitDialog>
</template>
