<script setup lang="ts">
import KitButton from '../Kit/KitButton.vue';
import KitDialog from '@/margin/components/Kit/KitDialog.vue';
import KitRow from '@/margin/components/Kit/KitRow.vue';
import KitBadge from '@/margin/components/Kit/KitBadge.vue';
import KitCoin from '@/margin/components/Kit/KitCoin.vue';
import { PropType } from 'vue';
import { Pair } from '@/margin/utils/trading.ts';
import { OrderSide, OrderType } from '@/margin/types';
import { toOrderTypeName } from '../../utils/helpers.ts';

defineProps({
  title: {
    type: String,
    required: true,
  },
  type: {
    type: Object as PropType<OrderType>,
    required: true,
  },
  side: {
    type: Object as PropType<OrderSide>,
    required: true,
  },
  pair: {
    type: Object as PropType<Pair>,
    required: true,
  },
  size: {
    type: Number,
    required: true,
  },
  loading: {
    type: Boolean,
    required: true,
  },
});

defineEmits(['close']);

const visible = defineModel({
  type: Boolean,
  required: true,
});
</script>

<template>
  <KitDialog v-model="visible" variant="confirmation" :title="title">
    <div>
      <KitRow label="Order type">
        <KitBadge :variant="side === OrderSide.Buy ? 'success' : 'danger'">{{
          toOrderTypeName(type, side)
        }}</KitBadge>
      </KitRow>

      <KitRow label="Symbol">
        <KitCoin :pair="pair" />
      </KitRow>

      <KitRow label="Size">
        <b>{{ $num(size, 'auto') }}</b> {{ pair?.name.base }}
      </KitRow>
    </div>

    <div class="mt-6 flex gap-2">
      <KitButton
        variant="default"
        :disabled="loading"
        @click="visible = false"
        >{{ $t('dialog.no') }}</KitButton
      >

      <KitButton variant="primary" :loading="loading" @click="$emit('close')">{{
        $t('dialog.yes')
      }}</KitButton>
    </div>
  </KitDialog>
</template>
