export const UPDATE_ACCOUNT_INTERVAL =
  Number(import.meta.env.VITE_UPDATE_ACCOUNT_INTERVAL) || 60_000;
export const UPDATE_ORDERS_INTERVAL =
  Number(import.meta.env.VITE_UPDATE_ORDERS_INTERVAL) || 60_000;
export const UPDATE_POSITIONS_INTERVAL =
  Number(import.meta.env.VITE_UPDATE_POSITIONS_INTERVAL) || 60_000;
export const UPDATE_PAIR_INTERVAL =
  Number(import.meta.env.VITE_UPDATE_PAIR_INTERVAL) || 60_000;

export enum Interval {
  AccountInfo = 'account-info',
  Orders = 'orders',
  Positions = 'positions',
  Pair = 'pair-',
}
