<script lang="ts" setup>
import { computed, ref } from 'vue';
import KitDropdown from '../Kit/KitDropdown.vue';
import { OrderSide, Option, Tab, OrderType } from '@/margin/types';
import KitTabs from '../Kit/KitTabs.vue';
import KitButton from '../Kit/KitButton.vue';
import MarketOrderForm from '../MarketOrder/MarketOrderForm.vue';
import { useStore } from '@/margin/store';
import { useI18n } from 'vue-i18n';
import { DEFAULT_PAIR_SYMBOL } from '@/margin/store/currencyPairModule';
import { getSymbolList } from '@/margin/api';
import { formatNumber } from '@/margin/i18n';
import { isNumber } from '@/margin/utils';

const { t } = useI18n();

const store = useStore();

const accountOptions = computed(() =>
  store.state.auth.accounts.map<Option<number>>((account) => ({
    value: account.id,
    label: account.currency,
    description: isNumber(account.info?.balance)
      ? formatNumber(account.info?.balance, account.info.precision)
      : '',
    flag: `/crypto/${account.currency.toLowerCase()}.svg`,
  })),
);

const tabs = ref<Tab[]>([
  {
    id: OrderType.Limit,
    title: t('marketOrder.limit'),
  },
  {
    id: OrderType.Market,
    title: t('marketOrder.market'),
  },
  {
    id: OrderType.Stop,
    title: t('marketOrder.stop'),
  },
  {
    id: OrderType.StopLimit,
    title: t('marketOrder.stopLimit'),
  },
]);

const type = ref(OrderType.Limit);
const side = ref(OrderSide.Buy);

const disableAccount = ref(false);

const account = computed({
  get: () => store.state.auth.account?.id,
  set: async (accountId) => {
    if (!accountId) return;

    disableAccount.value = true;
    try {
      const accountSymbols = await getSymbolList(accountId);
      const availableSymbols = accountSymbols.map(({ symbol }) => symbol);
      const selectedSymbol = store.state.currencyPair.selected?.symbol;
      const isSelectedAvailable = availableSymbols.includes(selectedSymbol);

      const symbol =
        (isSelectedAvailable ? selectedSymbol : DEFAULT_PAIR_SYMBOL) ??
        DEFAULT_PAIR_SYMBOL;

      store.dispatch('currencyPair/addPair', { symbol, accountId });
      store.dispatch('currencyPair/selectPair', { symbol, accountId });
    } finally {
      disableAccount.value = false;
    }
  },
});
</script>

<template>
  <div class="h-full overflow-auto p-2">
    <KitDropdown
      v-if="$store.state.auth.isAuthenticated"
      v-model="account"
      :options="accountOptions"
      :disabled="disableAccount"
      class="mb-4"
      data-test-id="marketOrderAccount"
      :label="$t('marketOrder.isolatedWallet')"
    />

    <div class="flex">
      <KitButton
        v-for="orderSide in [OrderSide.Buy, OrderSide.Sell]"
        :key="orderSide"
        :variant="
          side === orderSide
            ? side === OrderSide.Buy
              ? 'success'
              : 'danger'
            : 'default'
        "
        :class="[
          'uppercase',
          side === orderSide ? 'font-semibold' : 'font-normal text-m-gray',
        ]"
        :data-test-id="
          orderSide === OrderSide.Buy
            ? 'marketOrderButtonBuy'
            : 'marketOrderButtonSell'
        "
        @click="side = orderSide"
        >{{
          orderSide === OrderSide.Buy
            ? $t('marketOrder.buy')
            : $t('marketOrder.sell')
        }}</KitButton
      >
    </div>

    <KitTabs v-model="type" class="mt-4" :tabs="tabs" />
    <MarketOrderForm :type="type" :side="side" class="mt-4" />
  </div>
</template>
