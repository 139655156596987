<script setup lang="ts">
import Sidebar from 'primevue/sidebar';

defineProps({
  title: {
    type: String,
    required: true,
  },
});

const visible = defineModel({
  type: Boolean,
  default: false,
});
</script>

<template>
  <Sidebar
    v-model:visible="visible"
    :header="title"
    position="right"
    class="font-encode"
    block-scroll
    :pt="{
      mask: 'bg-m-black/60',
      root: 'bg-m-gray-600 h-full max-w-[500px] w-full flex flex-col',
      header:
        'flex justify-between sm:pt-6 sm:pb-4 sm:px-6 px-4 pt-4 pb-4  border-b border-m-gray-500',
      title: 'font-bold',
      closeIcon: 'text-m-gray',
      closeButton: 'w-6 h-6 flex items-center justify-center outline-none',
      content: 'sm:px-6 sm:pt-6 sm:pb-8 px-4 pt-4 pb-4 h-full overflow-hidden',
    }"
  >
    <slot></slot>
  </Sidebar>
</template>
