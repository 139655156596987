import { History } from '@/margin/types';
import { Module } from 'vuex';
import { getTradeHistoryList } from '@/margin/api';
import { StoreState } from '@/margin/store/index.ts';
import { captureException } from '@sentry/vue';

export interface HistoryState {
  history: History[];
  loading: boolean;
}

export interface LoadHistoryPayload {
  from: Date;
  to: Date;
}

export const HistoryModule: Module<HistoryState, StoreState> = {
  namespaced: true,
  state: {
    history: [],
    loading: true,
  },
  mutations: {
    SET_HISTORY(state, history: History[]) {
      state.history = history;
    },
    SET_LOADING(state, loading: boolean) {
      state.loading = loading;
    },
  },
  actions: {
    async loadHistory({ commit }, payload: LoadHistoryPayload) {
      const accountId = this.state.auth.account?.id;

      if (!accountId) return;

      commit('SET_LOADING', true);

      try {
        const history = await getTradeHistoryList(
          accountId,
          payload.from,
          payload.to,
        );
        commit('SET_HISTORY', history);
      } catch (e) {
        commit('SET_HISTORY', []);
        captureException(e);
      }

      commit('SET_LOADING', false);
    },
  },
};
