<script setup lang="ts">
import { Tab } from '@/margin/types';
import { PropType } from 'vue';

defineProps({
  tabs: {
    type: Array as PropType<Tab[]>,
    required: true,
  },
  tabClass: {
    type: [Array, String, Object],
    default: '',
  },
  selectedTabClass: {
    type: [Array, String, Object],
    default: 'font-semibold',
  },
  borderColor: {
    type: String,
    default: 'bg-m-gray-600',
  },
});

const model = defineModel({
  required: true,
  type: [String, Number] as PropType<string | number>,
});
</script>

<template>
  <div>
    <div class="scrollbar-hide overflow-auto">
      <div :class="['flex gap-6 whitespace-nowrap md:gap-8', tabClass]">
        <div
          v-for="(tab, index) in tabs"
          ref="tabsRefs"
          :key="index"
          :class="[
            'flex cursor-pointer items-center gap-1 pb-2 text-sm',
            {
              'text-m-gray': model !== tab.id,
              [`mb-[-1px] border-b border-m-primary ${selectedTabClass}`]:
                model === tab.id,
            },
          ]"
          data-test-id="tab"
          :data-test-tab-id="tab.id"
          @click="model = tab.id"
        >
          <component :is="tab.icon" v-if="tab.icon" />
          {{ tab.title }}
        </div>
      </div>
      <div :class="['h-[1px]', borderColor]"></div>
    </div>

    <slot></slot>
  </div>
</template>
