import { Module } from 'vuex';
import { StoreState } from '@/margin/store/index.ts';
import {
  addFavoriteSymbol,
  getFavoriteSymbols,
  removeFavoriteSymbol,
} from '@/margin/api/favorite.ts';
import { captureException } from '@sentry/vue';

export interface FavoriteState {
  symbols: string[];
}

export const FavoriteModule: Module<FavoriteState, StoreState> = {
  namespaced: true,
  state: {
    symbols: [],
  },
  mutations: {
    SET_FAVORITE_SYMBOLS(state, symbols: string[]) {
      state.symbols = symbols;
    },
    REMOVE_SYMBOL(state, symbol: string) {
      state.symbols = state.symbols.filter(
        (favoriteSymbol) => favoriteSymbol !== symbol,
      );
    },
    ADD_SYMBOL(state, symbol: string) {
      state.symbols = [...state.symbols, symbol];
    },
  },
  actions: {
    async loadFavoriteSymbols({ commit }) {
      const accountId = this.state.auth.account?.id;

      if (!this.state.auth.isAuthenticated || !accountId) return;

      try {
        const symbols = await getFavoriteSymbols(accountId);
        commit('SET_FAVORITE_SYMBOLS', symbols);
      } catch (e) {
        commit('SET_FAVORITE_SYMBOLS', []);
        captureException(e);
      }
    },
    async updateFavorite(
      { commit, dispatch },
      { symbol, favorite }: { symbol: string; favorite: boolean },
    ) {
      const accountId = this.state.auth.account?.id;

      if (!accountId) return;

      try {
        if (favorite) {
          commit('ADD_SYMBOL', symbol);
          await addFavoriteSymbol(accountId, symbol);
        } else {
          commit('REMOVE_SYMBOL', symbol);
          await removeFavoriteSymbol(accountId, symbol);
        }
      } finally {
        await dispatch('loadFavoriteSymbols');
      }
    },
  },
};
