import { createApp } from 'vue';
import './style.css';
import App from './App.vue';
import { createMargin } from '@/margin';
import * as Sentry from '@sentry/vue';

const app = createApp(App);

if (import.meta.env.VITE_DEVELOPMENT !== 'on') {
  app.config.warnHandler = () => null;
}

const margin = createMargin();

app.use(margin);
app.mount('#app');

if (import.meta.env.VITE_SENTRY_DSN) {
  Sentry.init({
    app,
    initialScope: {
      tags: {
        version: `${VERSION} ${BUILD}`,
      },
    },
    dsn: import.meta.env.VITE_SENTRY_DSN,
    environment: import.meta.env.VITE_SENTRY_ENV,
    integrations: [
      Sentry.replayIntegration(),
      Sentry.browserTracingIntegration(),
    ],
    tracesSampleRate: 0.2,
    replaysSessionSampleRate: 0.1,
    replaysOnErrorSampleRate: 1.0,
  });
}
