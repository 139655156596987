<script lang="ts" setup>
import { AreaChartData } from '@/margin/types';
import type { ApexOptions } from 'apexcharts';
import { PropType, computed } from 'vue';

const props = defineProps({
  data: {
    type: Array as PropType<AreaChartData[]>,
    required: true,
  },
  width: {
    type: Number,
    default: 30,
  },
  height: {
    type: Number,
    default: 20,
  },
  variant: {
    type: String as PropType<'success' | 'danger'>,
    default: 'success',
  },
});

const colors = {
  success: '#27AE60',
  danger: '#E83355',
};

const options = computed<ApexOptions>(() => ({
  chart: {
    type: 'area',
    animations: { enabled: false },
    sparkline: {
      enabled: true,
    },
    toolbar: {
      show: false,
    },
    zoom: {
      enabled: false,
    },
  },
  tooltip: {
    enabled: false,
  },
  grid: {
    show: false,
  },
  dataLabels: {
    enabled: false,
  },
  marker: {
    show: false,
  },
  stroke: {
    curve: 'straight',
    width: 1.5,
    colors: [colors[props.variant]],
  },
  fill: {
    type: 'solid',
    colors: ['transparent'],
  },
  xaxis: {
    type: 'datetime',
    labels: {
      show: false,
    },
    position: 'bottom',
    tickPlacement: 'between',
    axisTicks: {
      show: false,
    },
    axisBorder: {
      show: false,
    },
  },
  yaxis: {
    labels: {
      show: false,
    },
  },
}));
</script>

<template>
  <div
    :style="{
      width: width + 'px',
      height: height + 'px',
    }"
  >
    <VueApexCharts
      type="area"
      :height="height"
      :width="width"
      :options="options"
      :series="[{ data: data ?? [] }]"
    />
  </div>
</template>
