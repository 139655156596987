<script setup lang="ts" generic="T">
import { PropType, ref, toRef } from 'vue';
import Dropdown from 'primevue/dropdown';
import ChevronDown from '../Icon/IconChevronDown.vue';
import { Option } from '@/margin/types';
import KitDropdownOption from './KitDropdownOption.vue';
import { useSelectedDropdownOption } from '@/margin/composeables/useSelectedDropdownOption';

const props = defineProps({
  options: {
    type: Array as PropType<Option<T>[]>,
    required: true,
  },
  label: {
    type: String,
    default: null,
  },
  matchByKey: {
    type: String,
    default: null,
  },
  variant: {
    type: String as PropType<'default' | 'secondary'>,
    default: 'default',
  },
  size: {
    type: String as PropType<'default' | 'small'>,
    default: 'default',
  },
  disabled: {
    type: Boolean,
    default: false,
  },
});

const model = defineModel({
  type: null as PropType<T>,
  required: true,
});

const options = toRef(props, 'options');
const matchByKey = toRef(props, 'matchByKey');

const dropdownOpen = ref(false);
const selectedOption = useSelectedDropdownOption(model, options, matchByKey);
</script>

<template>
  <Dropdown
    v-model="model"
    :options="options"
    option-label="label"
    option-value="value"
    scroll-height="300px"
    :disabled="disabled"
    :pt="{
      root: `w-full flex items-center justify-between ${variant === 'secondary' ? 'bg-m-gray-500' : 'bg-m-gray-600'} ${size === 'small' ? 'px-2 py-1.5' : 'p-2'} cursor-pointer ${disabled ? 'opacity-75' : ''}`,
      input: 'leading-none outline-none w-full',
      panel: 'bg-m-black px-0 border-2 bg-m-gray-800 border-m-gray-600',
      list: 'py-0',
      item: 'px-0 cursor-pointer hover:bg-m-gray-600',
      trigger: `flex items-center justify-center shrink-0 bg-transparent ${dropdownOpen ? 'rotate-180' : ''}`,
    }"
    @show="dropdownOpen = true"
    @hide="dropdownOpen = false"
  >
    <template #dropdownicon>
      <ChevronDown class="text-m-gray" />
    </template>

    <template #value>
      <div
        :class="[
          'flex items-center justify-between',
          {
            'text-xs': variant === 'default',
            'text-sm': variant === 'secondary',
          },
        ]"
      >
        <div
          v-if="label"
          :class="{
            'font-semibold': variant === 'default',
          }"
        >
          {{ label }}
        </div>

        <div
          v-if="selectedOption"
          :class="[
            'flex items-center',
            {
              'mr-4': variant === 'default',
            },
          ]"
        >
          <div
            :class="[
              'flex items-center gap-1',
              {
                'font-bold': variant === 'default',
              },
            ]"
          >
            <div
              v-if="selectedOption?.flag"
              class="h-3.5 w-3.5 overflow-hidden rounded-full bg-m-gray-600"
            >
              <img
                :src="selectedOption.flag"
                loading="lazy"
                :alt="selectedOption.label"
                class="h-full w-full object-cover"
              />
            </div>
            {{ selectedOption?.label }}
          </div>
        </div>
      </div>
    </template>
    <template #option="{ option }">
      <KitDropdownOption
        :option="option"
        :selected="option === selectedOption"
      />
    </template>
  </Dropdown>
</template>
