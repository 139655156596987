<script setup lang="ts">
import { notification } from '@/margin/composeables/useNotification.ts';
import KitNotification from '@/margin/components/Kit/KitNotification.vue';
import { computed } from 'vue';

const visible = computed({
  get: () => !!notification.value,
  set: () => (notification.value = null),
});
</script>

<template>
  <KitNotification
    v-model="visible"
    :title="notification?.title"
    :description="notification?.description"
    :type="notification?.type"
  />
</template>
