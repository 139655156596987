<template>
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M8.64951 10.7903C8.34956 11.0759 8.33798 11.5507 8.62364 11.8506C8.90931 12.1506 9.38404 12.1621 9.68399 11.8765L8.64951 10.7903ZM13.184 8.54314C13.4839 8.25748 13.4955 7.78275 13.2099 7.4828C12.9242 7.18285 12.4495 7.17127 12.1495 7.45694L13.184 8.54314ZM12.1495 8.54314C12.4495 8.82881 12.9242 8.81723 13.2099 8.51728C13.4955 8.21733 13.4839 7.7426 13.184 7.45694L12.1495 8.54314ZM9.68399 4.1236C9.38404 3.83794 8.90931 3.84952 8.62364 4.14947C8.33798 4.44941 8.34956 4.92415 8.64951 5.20981L9.68399 4.1236ZM12.6667 8.75004C13.081 8.75004 13.4167 8.41425 13.4167 8.00004C13.4167 7.58583 13.081 7.25004 12.6667 7.25004V8.75004ZM3.33341 7.25004C2.9192 7.25004 2.58341 7.58583 2.58341 8.00004C2.58341 8.41425 2.9192 8.75004 3.33341 8.75004V7.25004ZM9.68399 11.8765L13.184 8.54314L12.1495 7.45694L8.64951 10.7903L9.68399 11.8765ZM13.184 7.45694L9.68399 4.1236L8.64951 5.20981L12.1495 8.54314L13.184 7.45694ZM12.6667 7.25004L3.33341 7.25004V8.75004L12.6667 8.75004V7.25004Z"
      fill="currentColor"
    />
  </svg>
</template>
