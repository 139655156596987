<script lang="ts" setup>
import { useLastPrice } from '@/margin/composeables/useLastPrice';
import { toRef, watch } from 'vue';
import IconCaretDown from '../Icon/IconCaretDown.vue';

const props = defineProps({
  symbol: {
    type: String,
    required: true,
  },
  precision: {
    type: Number,
    required: true,
  },
  fallbackPrice: {
    type: Number,
    required: false,
    default: 0,
  },
  suffix: {
    type: String,
    default: '',
  },
});

const model = defineModel({
  type: Number,
  required: false,
  default: 0,
});

const symbol = toRef(props, 'symbol');

const { lastPrice, lastPriceMoveUp } = useLastPrice(symbol);

watch(lastPrice, () => {
  model.value = lastPrice.value;
});
</script>

<template>
  <div
    :class="[
      'inline-flex items-center',
      {
        'text-green-500': lastPriceMoveUp,
        'text-red-500': !lastPriceMoveUp,
      },
    ]"
  >
    <IconCaretDown
      v-if="(lastPrice ?? fallbackPrice) !== 0"
      :class="[
        'h-3.5 w-3.5',
        {
          'rotate-180': lastPriceMoveUp,
        },
      ]"
    />
    {{ $num(lastPrice ?? fallbackPrice, precision) }}
    {{ suffix }}
  </div>
</template>
