<script setup lang="ts">
import { computed, markRaw } from 'vue';
import { GridItem, GridLayout } from 'grid-layout-plus';
import { WidgetType } from '@/margin/types';
import WidgetTradingView from './Widget/WidgetTradingView.vue';
import WidgetTicker from './Widget/WidgetTicker.vue';
import WidgetOrderBook from './Widget/WidgetOrderBook.vue';
import WidgetMarketOrder from './Widget/WidgetMarketOrder.vue';
import WidgetOrders from './Widget/WidgetOrders.vue';
import { useStore } from '@/margin/store';
import { breakpointsTailwind, useBreakpoints } from '@vueuse/core';

const widgets = {
  [WidgetType.Ticker]: markRaw(WidgetTicker),
  [WidgetType.Trading]: markRaw(WidgetTradingView),
  [WidgetType.OrderBook]: markRaw(WidgetOrderBook),
  [WidgetType.MarketOrder]: markRaw(WidgetMarketOrder),
  [WidgetType.Orders]: markRaw(WidgetOrders),
};

const store = useStore();
const breakpoints = useBreakpoints({
  xs: 0,
  sm: breakpointsTailwind.sm,
  md: breakpointsTailwind.md,
  lg: breakpointsTailwind.lg,
  xl: breakpointsTailwind.xl,
});

const breakpoint = breakpoints.active();

const layout = computed(
  () =>
    store.state.layout.layouts[breakpoint.value] ??
    store.state.layout.layouts.xs,
);
</script>

<template>
  <GridLayout
    v-model:layout="layout"
    :col-num="24"
    :row-height="32"
    :margin="[4, 4]"
    vertical-compact
    use-css-transforms
    @layout-updated="
      breakpoints.xl ? $store.dispatch('layout/updateLayout', $event) : null
    "
  >
    <GridItem
      v-for="item in layout"
      :key="item.i"
      :x="item.x"
      :y="item.y"
      :w="item.w"
      :h="item.h"
      :i="item.i"
      :min-w="item.minW"
      :min-h="item.minH"
      :max-h="item.maxH"
      :max-w="item.maxW"
      :static="item.static"
      drag-allow-from=".vue-draggable-handle"
      drag-ignore-from=".no-drag"
      class="m-0 border-spacing-0 overflow-hidden border-0 bg-m-gray-800"
    >
      <div
        v-if="!item.static"
        class="vue-draggable-handle absolute right-0 top-0 z-10 box-border h-3 w-1/3 max-w-[200px] cursor-move bg-origin-content"
      ></div>
      <component
        :is="widgets[item.i]"
        v-if="$store.state.currencyPair.selected && $store.state.auth.account"
        class="grid-item"
      ></component>
      <div v-else class="h-full w-full animate-pulse bg-m-gray-500" />
    </GridItem>
  </GridLayout>
</template>

<style scoped>
.vgl-item--resizing .grid-item {
  pointer-events: none;
}
</style>
