interface CacheOptions {
  expiration: number;
}

interface CacheValue<V = any> {
  value: V;
  expiration: number;
}

export const EXPIRE_AFTER_DAY = 1000 * 60 * 60 * 24;
export const EXPIRE_AFTER_5_MINUTES = 1000 * 60 * 60 * 24;
export const EXPIRE_AFTER_HOUR = 1000 * 60 * 60;

class MemoryCache {
  data: Record<string, CacheValue> = {};

  set(key: string, value: any, options?: CacheOptions) {
    this.data[key] = {
      value,
      expiration: Date.now() + (options?.expiration || EXPIRE_AFTER_HOUR),
    };
  }

  get<D = unknown>(key: string): D | null {
    const value = this.data[key];

    if (!value) return null;

    if (!this.isValid(value)) {
      this.remove(key);
      return null;
    }

    return value.value;
  }

  remove(key: string) {
    delete this.data[key];
  }

  generateKey(prefix: string, dependencies: unknown[]) {
    return prefix + JSON.stringify(dependencies ?? []);
  }

  private isValid(value: CacheValue) {
    if (!value) return false;

    const expiration = value?.expiration;

    if (!expiration) return true;

    return expiration > Date.now();
  }
}

export const cache = new MemoryCache();
