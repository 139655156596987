<script lang="ts" setup>
import KitNumberField from '@/margin/components/Kit/KitNumberField.vue';
import MarketOrderFieldExpiration from './Field/MarketOrderFieldExpiration.vue';
import MarketOrderFieldTPSL from './Field/MarketOrderFieldTPSL.vue';
import MarketOrderFieldTotal from './Field/MarketOrderFieldTotal.vue';
import { computed, PropType, ref, toRef, watch } from 'vue';
import MarketOrderActions from './MarketOrderActions.vue';
import MarketOrderDetail from './MarketOrderDetail.vue';
import {
  ExpirationType,
  MarketOrderForm,
  OrderSide,
  OrderType,
  CurrencyPair,
} from '@/margin/types';
import { useStore } from '@/margin/store';
import KitCheckbox from '@/margin/components/Kit/KitCheckbox.vue';
import DialogConfirmOrder from '@/margin/components/Dialog/DialogConfirmOrder.vue';
import { useOrderFormDialog } from '@/margin/composeables/useOrderFormDialog.ts';
import MarketOrderBalance from '@/margin/components/MarketOrder/MarketOrderBalance.vue';
import { useNotification } from '@/margin/composeables/useNotification.ts';
import { useField, useForm } from 'vee-validate';
import { boolean, number, object } from 'yup';
import {
  fractionsToPlaceholder,
  fractionsToStep,
  getFractions,
  isNumber,
  toFixedFractions,
} from '@/margin/utils';
import { createOrder } from '@/margin/api';
import { toOrderTypeName } from '@/margin/utils/helpers.ts';
import { formatNumber } from '@/margin/i18n.ts';
import { useI18n } from 'vue-i18n';
import {
  getErrorMessage,
  canCalculateROE,
  calculateMarginRequirement,
  calculateTotal,
} from '@/margin/utils/orderForm.ts';
import { captureException } from '@sentry/vue';
import { useOrderForm } from '@/margin/composeables/useOrderForm';
import { GlobalEvents, useGlobalEvent } from '@/margin/composeables/useEvent';
import { useTPSLValidation } from '@/margin/composeables/useTPSLValidation';

const { t } = useI18n();

const props = defineProps({
  type: {
    type: String as PropType<OrderType>,
    required: true,
  },
  side: {
    type: String as PropType<OrderSide>,
    required: true,
  },
});

const notification = useNotification();
const store = useStore();
const globalEvent = useGlobalEvent();

const processing = ref(false);

const selectedPair = computed<CurrencyPair>(
  () => store.getters['currencyPair/currentPair'],
);

globalEvent.on(GlobalEvents.LimitPrice, (price: number) => {
  if (isNumber(price)) {
    limitPrice.value = price;
  }
});

const showRoe = computed(() =>
  canCalculateROE(
    store.state.auth.account?.currency,
    selectedPair.value?.pair.quote,
  ),
);

const maxFraction = computed(() =>
  getFractions(selectedPair?.value.info?.tradeAmountStep ?? 0),
);

const isLimitOrder = computed(() =>
  [OrderType.Limit, OrderType.StopLimit].includes(props.type),
);

const isStopOrder = computed(() =>
  [OrderType.Stop, OrderType.StopLimit].includes(props.type),
);

const price = computed(() => {
  switch (props.type) {
    case OrderType.Limit:
    case OrderType.StopLimit:
      return limitPrice?.value ?? 0;
    case OrderType.Market:
      return (
        store.state.orderBook.lastPrice ?? selectedPair.value?.detail?.lastPrice
      );
    case OrderType.Stop:
      return stopPrice?.value ?? 0;
    default:
      return 0;
  }
});

const minSize = computed(() => selectedPair.value?.info?.minTradeAmount ?? 0);

const validationSchema = computed(() => ({
  limitPrice: isLimitOrder.value ? number().required() : null,
  stopPrice: isStopOrder.value ? number().required() : null,
  size: number()
    .required()
    .min(minSize.value)
    .max(selectedPair.value?.info?.maxTradeAmount ?? 0),
  expirationType: number(),
  showTPSL: boolean(),
  takeProfit: object({
    value: number()
      .nullable()
      .test((value) => validateTP(value)),
    asPercentage: boolean(),
  }),
  stopLoss: object({
    value: number()
      .nullable()
      .test((value) => validateSL(value)),
    asPercentage: boolean(),
  }),
}));

const { errors, meta, handleSubmit, resetForm } = useForm<MarketOrderForm>({
  validationSchema,
  initialValues: {
    limitPrice: null,
    stopPrice: null,
    size: null,
    expirationType: ExpirationType.GoodTillCancel,
    showTPSL: false,
    takeProfit: {
      value: null,
      asPercentage: false,
    },
    stopLoss: {
      value: null,
      asPercentage: false,
    },
  },
});

const submit = handleSubmit(async (data) => {
  processing.value = true;

  try {
    const orderId = await createOrder(store.state.auth.account?.id, {
      type: props.type,
      side: props.side,
      symbol: selectedPair.value?.symbol,
      size: size.value,
      limitPrice: isLimitOrder.value ? data.limitPrice : null,
      stopPrice: isStopOrder.value ? data.stopPrice : null,
      takeProfit:
        data.showTPSL && tpPrice.value > 0
          ? toFixedFractions(tpPrice.value, selectedPair.value.info?.precision)
          : null,
      stopLoss:
        data.showTPSL && slPrice.value > 0
          ? toFixedFractions(slPrice.value, selectedPair.value.info?.precision)
          : null,
    });

    notification.showSuccess(
      t('marketOrder.successOrderTitle', {
        orderType: toOrderTypeName(props.type),
        orderId,
      }),
      t('marketOrder.successOrderDescription', {
        symbol: `${selectedPair.value.pair.base}/ ${selectedPair.value.pair.quote}`,
        side: toOrderTypeName(null, props.side),
        size: formatNumber(size.value, 'auto'),
        price: formatNumber(
          limitPrice.value,
          selectedPair.value.info?.precision,
        ),
      }),
    );

    resetForm();

    store.dispatch('orders/loadOrders');
    store.dispatch('positions/loadPositions');
    store.dispatch('auth/refreshAccount');
  } catch (error) {
    captureException(error);
    notification.showError(
      getErrorMessage(error, t('marketOrder.failedPlaceOrder')),
    );
  }

  processing.value = false;
});

const { value: limitPrice } = useField<number>('limitPrice');
const { value: stopPrice } = useField<number>('stopPrice');
const { value: size } = useField<number>('size');
const { value: expirationType } = useField<ExpirationType>('expirationType');
const { value: showTPSL } = useField<boolean>('showTPSL');
const { value: takeProfit } = useField<number>('takeProfit.value');
const { value: stopLoss } = useField<number>('stopLoss.value');
const { value: tpAsPercentage } = useField<boolean>('takeProfit.asPercentage');
const { value: slAsPercentage } = useField<boolean>('stopLoss.asPercentage');

const { showConfirmation, requireConfirmation, processOrder } =
  useOrderFormDialog(submit);

watch(
  () =>
    [
      props.type,
      selectedPair.value?.symbol,
      store.state.auth.account?.id,
    ].join(),
  () => resetForm(),
);

const total = computed(() => calculateTotal(price.value, size.value));
const marginRequirement = computed(() =>
  calculateMarginRequirement(
    price.value,
    size.value,
    store.state.auth.account.leverage,
  ),
);

const { slExpectedReturn, tpExpectedReturn, slPrice, tpPrice } = useOrderForm({
  tpAsPercentage,
  slAsPercentage,
  tpOrRoe: takeProfit,
  slOrRoe: stopLoss,
  price,
  size,
  side: toRef(props, 'side'),
  margin: marginRequirement,
});

const { tpMax, tpMin, slMax, slMin, validateSL, validateTP } =
  useTPSLValidation({
    side: toRef(props, 'side'),
    price,
    tpAsPercentage,
    slAsPercentage,
  });
</script>

<template>
  <div v-if="selectedPair">
    <MarketOrderBalance
      v-if="$store.state.auth.account && $store.state.auth.isAuthenticated"
      :balance="$store.state.auth.account?.balance"
      :precision="$store.state.auth.account?.precision ?? 2"
      :currency="$store.state.auth.account?.currency"
      class="mt-4"
    />

    <KitNumberField
      v-if="isStopOrder"
      v-model="stopPrice"
      :label="$t('marketOrder.stopPrice')"
      :suffix="selectedPair.pair.name.quote"
      :invalid="errors.stopPrice"
      :max-fraction="selectedPair.info?.precision"
      :placeholder="fractionsToPlaceholder(selectedPair.info?.precision)"
      :step="fractionsToStep(selectedPair.info?.precision)"
      class="mt-4"
      data-test-id="marketOrderStopPrice"
      :disabled="!selectedPair.info"
      :initial-value-on-arrow="$store.state.orderBook.lastPrice"
    />

    <KitNumberField
      v-if="isLimitOrder"
      v-model="limitPrice"
      :label="$t('marketOrder.limitPrice')"
      :suffix="selectedPair.pair.name.quote"
      :invalid="errors.limitPrice"
      :max-fraction="selectedPair.info?.precision"
      :placeholder="fractionsToPlaceholder(selectedPair.info?.precision)"
      :step="fractionsToStep(selectedPair.info?.precision)"
      class="mt-4"
      data-test-id="marketOrderLimitPrice"
      :disabled="!selectedPair.info"
      :initial-value-on-arrow="$store.state.orderBook.lastPrice"
    />

    <div
      v-if="props.type === OrderType.Market"
      class="mt-4 flex items-center justify-between text-sm font-normal text-m-gray"
    >
      <span>{{ $t('marketOrder.marketPrice') }}</span>
      <div class="flex items-center justify-between gap-x-1">
        <span>≈</span>
        <span class="font-medium">{{
          $num(
            $store.state.orderBook.lastPrice ?? selectedPair.detail?.lastPrice,
            {
              minimumFractionDigits: selectedPair.info?.precision,
              maximumFractionDigits: selectedPair.info?.precision,
            },
          )
        }}</span>
        <span>{{ selectedPair.pair.quote }}</span>
      </div>
    </div>

    <KitNumberField
      v-model="size"
      :label="$t('marketOrder.size')"
      :placeholder="fractionsToPlaceholder(maxFraction)"
      :invalid="errors.size"
      :suffix="selectedPair.pair.name.base"
      :min="minSize"
      :step="selectedPair.info?.tradeAmountStep ?? 0.1"
      :class="props.type === OrderType.Market ? 'mt-2' : 'mt-4'"
      data-test-id="marketOrderSize"
      :disabled="!selectedPair.info"
    />

    <MarketOrderFieldTotal
      v-model="total"
      :margin-requirement="marginRequirement"
      :pair="selectedPair.pair"
      class="mt-4"
      data-test-id="marketOrderTotal"
    />

    <hr class="mt-4 border-m-gray-600" />

    <MarketOrderFieldExpiration
      v-model="expirationType"
      class="mt-4"
      data-test-id="marketOrderExpirationType"
      :disabled="!selectedPair.info"
    />

    <KitCheckbox
      v-model="showTPSL"
      :label="$t('marketOrder.showTPSL')"
      class="mt-4"
      data-test-id="marketOrderTpSlShow"
    />

    <MarketOrderFieldTPSL
      v-if="showTPSL"
      v-model:takeProfit="takeProfit"
      v-model:tpAsPercentage="tpAsPercentage"
      v-model:stopLoss="stopLoss"
      v-model:slAsPercentage="slAsPercentage"
      :invalid-tp="errors.takeProfit"
      :invalid-sl="errors.stopLoss"
      :actual-tp="tpPrice"
      :actual-sl="slPrice"
      :min-tp="tpMin"
      :max-tp="tpMax"
      :max-sl="slMax"
      :min-sl="slMin"
      :pair="selectedPair.pair"
      :precision="selectedPair.info?.precision"
      class="mt-4"
      data-test-id="marketOrderTpSl"
      :take-profit-expected-return="tpExpectedReturn"
      :stop-loss-expected-return="slExpectedReturn"
      :disabled="!selectedPair.info"
      :show-roe="showRoe"
    />

    <MarketOrderActions
      :is-authenticated="$store.state.auth.isAuthenticated"
      :side="side"
      :pair="selectedPair.pair"
      :disabled="!meta.valid"
      :loading="!selectedPair.info || processing"
      data-test-id="marketOrderAction"
      @process="requireConfirmation"
    />

    <hr class="mt-4 border-m-gray-600" />

    <MarketOrderDetail
      class="mt-4"
      :account="$store.state.auth.account"
      :show-value="$store.state.auth.isAuthenticated"
      :total-unrealized-pnl="$store.getters['positions/totalUnrealizedPnL']"
      data-test-id="marketOrderDetail"
    />

    <DialogConfirmOrder
      v-if="showConfirmation"
      v-model="showConfirmation"
      :side="side"
      :type="type"
      :limit-price="limitPrice"
      :stop-price="stopPrice"
      :order-size="size"
      :pair="selectedPair.pair"
      :loading="processing"
      data-test-id="marketOrderDialogConfirmOrder"
      @confirm="processOrder"
    />
  </div>
</template>
