<script lang="ts" setup generic="T">
import { Option } from '@/margin/types';
import Dropdown from 'primevue/dropdown';
import { PropType, toRef } from 'vue';
import IconChevronDown from '../Icon/IconChevronDown.vue';
import KitDropdownOption from './KitDropdownOption.vue';
import { useSelectedDropdownOption } from '@/margin/composeables/useSelectedDropdownOption';

const props = defineProps({
  options: {
    type: Array as PropType<Option<T>[]>,
    required: true,
  },
  matchByKey: {
    type: String,
    default: null,
  },
  disabled: {
    type: Boolean,
    default: false,
  },
});

const model = defineModel({
  type: null as PropType<T>,
  required: true,
});

const options = toRef(props, 'options');
const matchByKey = toRef(props, 'matchByKey');
const selectedOption = useSelectedDropdownOption(model, options, matchByKey);
</script>

<template>
  <Dropdown
    v-model="model"
    option-label="label"
    option-value="value"
    :options="options"
    :pt="{
      root: `h-9 pr-2 flex items-center justify-between cursor-pointer`,
      input: 'text-sm pr-1 pl-2.5 outline-none',
      item: 'cursor-pointer hover:bg-m-gray-600',
      panel: 'bg-m-black border-2 bg-m-gray-800 border-m-gray-600',
      list: '',
      trigger: `flex items-center justify-center shrink-0 bg-transparent rounded-none`,
    }"
    :disabled="disabled"
  >
    <template #dropdownicon>
      <IconChevronDown class="h-3.5 w-3.5 text-m-gray" />
    </template>

    <template #option="{ option }">
      <KitDropdownOption
        :option="option"
        :selected="selectedOption === option"
      />
    </template>
  </Dropdown>
</template>
