const TW_TO_API = {
  '1S': 'S1',
  S: 'S1',
  '10S': 'S10',
  '1': 'M1',
  '5': 'M5',
  '15': 'M15',
  '30': 'M30',
  '60': 'H1',
  '240': 'H4',
  '1D': 'D1',
  D: 'D1',
  '1W': 'W1',
  W: 'W1',
  '1M': 'MN1',
  M: 'MN1',
};
const TW_TO_MS = {
  '1S': 1000,
  S: 1000,
  '10S': 10000,
  '1': 60000,
  '5': 300000,
  '15': 900000,
  '30': 1800000,
  '60': 3600000,
  '240': 14400000,
  '1D': 86400000,
  D: 86400000,
  '1W': 604800000,
  W: 604800000,
  '1M': null, // calculate dynamically
  M: null, // calculate dynamically
};

export const TWResolutionToAPI = (resolution: string) => TW_TO_API[resolution];

export const roundToClosestTime = (time: number, resolution: string) => {
  if (resolution === '1M' || resolution === 'M') {
    const date = new Date(time);
    const month = date.getMonth();
    const year = date.getFullYear();
    return new Date(year, month, 1).getTime();
  }

  const resolutionInMs = TW_TO_MS[resolution];
  return Math.floor(time / resolutionInMs) * resolutionInMs;
};
