import { getAccountInfo, getUserAccounts, setToken } from '@/margin/api';
import { Account, AccountInfo, MarginSettings } from '@/margin/types';
import { Module } from 'vuex';
import { StoreState } from '.';
import { interval, removeInterval } from '@/margin/utils/interval.ts';
import { Interval, UPDATE_ACCOUNT_INTERVAL } from '@/margin/const.ts';
import { i18n } from '@/margin/i18n.ts';
import { getUserSelectedPair } from '@/margin/store/currencyPairModule.ts';
import { captureException } from '@sentry/vue';

const PREFER_DEFAULT_ACCOUNT = 'USDT';

const PUBLIC_ACCOUNT: AccountInfo = {
  id: null,
  currency: PREFER_DEFAULT_ACCOUNT,
  marginLevel: null,
  margin: null,
  equity: null,
  balance: null,
  leverage: null,
  profit: null,
  precision: 2,
  isFiatOrStable: true,
};

const PUBLIC_ACCOUNTS: Account[] = [
  {
    id: null,
    currency: PREFER_DEFAULT_ACCOUNT,
    info: PUBLIC_ACCOUNT,
  },
];

export interface AuthState {
  token: string;
  locale: string;
  isAuthenticated: boolean;
  accounts: Account[];
  account: AccountInfo;
}

export const AuthModule: Module<AuthState, StoreState> = {
  namespaced: true,
  state: {
    token: null,
    locale: 'en',
    isAuthenticated: false,
    accounts: [],
    account: null,
  },
  mutations: {
    SET_USER(state, token: string) {
      state.token = token;
      state.isAuthenticated = !!token;
    },
    SET_LOCALE(state, locale: string) {
      state.locale = locale;
    },
    SET_ACCOUNTS(state, accounts: Account[]) {
      state.accounts = accounts;
    },
    SET_ACCOUNT(state, account: AccountInfo) {
      state.account = account;
      state.accounts = state.accounts.map((acc) =>
        acc.id === account?.id ? { ...acc, info: account } : acc,
      );
    },
  },
  actions: {
    setLocale({ commit }, locale: string) {
      if (!locale) {
        locale = 'en';
      }

      commit('SET_LOCALE', locale);
      i18n.global.locale.value = locale as typeof i18n.global.locale.value;
    },
    async setUser({ commit, dispatch }, settings: MarginSettings) {
      commit('SET_USER', settings?.token);
      setToken(settings?.token);

      if (settings?.locale) {
        dispatch('setLocale', settings.locale);
      }

      if (!settings?.token) {
        // Initialize public account
        commit('SET_ACCOUNTS', PUBLIC_ACCOUNTS);
        await dispatch('setAccount', null);
        this.dispatch('currencyPair/initializePairs');
        return;
      }

      try {
        const accounts = await getUserAccounts();

        let infos: AccountInfo[] = [];

        try {
          infos = await Promise.all(
            accounts.map((account) => getAccountInfo(account.id)),
          );
        } catch (e) {
          // ignore
          captureException(e);
        }

        const accountsWithInfo = accounts.map<Account>((account) => ({
          id: account.id,
          currency: account.currency,
          info: infos.find((info) => info.id === account.id),
        }));

        commit('SET_ACCOUNTS', accountsWithInfo);

        const lastlyOpenedAccountId = getUserSelectedPair()?.accountId;

        const defaultAccount =
          accounts.find(
            ({ currency }) => currency === settings?.preferAccount,
          ) ??
          accounts.find(({ id }) => id === lastlyOpenedAccountId) ??
          accounts.find(
            ({ currency }) => currency === PREFER_DEFAULT_ACCOUNT,
          ) ??
          accounts[0];

        await dispatch('setAccount', defaultAccount?.id);
      } catch (e) {
        captureException(e);
        commit('SET_ACCOUNTS', []);
      }

      this.dispatch('currencyPair/initializePairs');
    },
    async setAccount({ commit }, accountId: number) {
      removeInterval(Interval.AccountInfo);

      if (!accountId) {
        return commit('SET_ACCOUNT', PUBLIC_ACCOUNT);
      }

      const setAccount = async () => {
        const account = await getAccountInfo(accountId);

        if (account) {
          commit('SET_ACCOUNT', account);
        }
      };

      try {
        await setAccount();

        // Update info in interval to update balance, equity, ...
        interval(setAccount, UPDATE_ACCOUNT_INTERVAL, Interval.AccountInfo);
      } catch (e) {
        captureException(e);
        commit('SET_ACCOUNT', null);
      }
    },
    async refreshAccount({ state, commit }) {
      if (!state.account?.id) return;

      try {
        const account = await getAccountInfo(state.account.id);
        commit('SET_ACCOUNT', account);
      } catch (e) {
        captureException(e);
      }
    },
  },
};
