<script setup lang="ts">
import KitDialog from '@/margin/components/Kit/KitDialog.vue';
import KitRow from '@/margin/components/Kit/KitRow.vue';
import KitBadge from '@/margin/components/Kit/KitBadge.vue';
import KitCoin from '@/margin/components/Kit/KitCoin.vue';
import KitNumberField from '@/margin/components/Kit/KitNumberField.vue';
import KitDropdown from '@/margin/components/Kit/KitDropdown.vue';
import KitButton from '@/margin/components/Kit/KitButton.vue';
import { computed, onMounted, PropType, ref, toRef } from 'vue';
import {
  EditOrderForm,
  ExpirationType,
  Option,
  Order,
  OrderSide,
  OrderType,
  SymbolInfo,
} from '@/margin/types';
import { toOrderTypeName } from '../../utils/helpers.ts';
import { useI18n } from 'vue-i18n';
import MarketOrderFieldTPSL from '@/margin/components/MarketOrder/Field/MarketOrderFieldTPSL.vue';
import { boolean, number, object } from 'yup';
import {
  fractionsToPlaceholder,
  fractionsToStep,
  getFractions,
  toFixedFractions,
} from '@/margin/utils';
import { useField, useForm } from 'vee-validate';
import { calculateMarginRequirement } from '@/margin/utils/orderForm.ts';
import { getSymbolInfo } from '@/margin/api';
import KitLastPrice from '../Kit/KitLastPrice.vue';
import { useLastPrice } from '@/margin/composeables/useLastPrice';
import { useStore } from '@/margin/store';
import { useOrderForm } from '@/margin/composeables/useOrderForm.ts';

const { t } = useI18n();
const store = useStore();

const props = defineProps({
  selectedOrder: {
    type: Object as PropType<Order>,
    required: true,
  },
  loading: {
    type: Boolean,
    required: false,
  },
});

const emit = defineEmits<{
  (
    e: 'confirm',
    limitPrice: number,
    stopPrice: number,
    size: number,
    takeProfit: number,
    stopLoss: number,
  ): void;
}>();

const visible = defineModel({
  type: Boolean,
  required: true,
});

const expirationOptions: Option<ExpirationType>[] = [
  {
    label: t('marketOrder.goodTillCanceled'),
    value: ExpirationType.GoodTillCancel,
  },
];

const symbolInfo = ref<SymbolInfo>();

const symbol = computed(() => props.selectedOrder.pair?.symbol);
const { lastPrice } = useLastPrice(symbol);

const loadSymbolInfo = async () => {
  symbolInfo.value = await getSymbolInfo(props.selectedOrder.pair.symbol);
};

onMounted(() => {
  loadSymbolInfo();
});

const isLimitOrder = computed(() =>
  [OrderType.Limit, OrderType.StopLimit].includes(props.selectedOrder.type),
);

const isStopOrder = computed(() =>
  [OrderType.Stop, OrderType.StopLimit].includes(props.selectedOrder.type),
);

const maxFraction = computed(() =>
  getFractions(symbolInfo.value?.tradeAmountStep ?? 0),
);

const price = computed(() => {
  switch (props.selectedOrder.type) {
    case OrderType.Limit:
    case OrderType.StopLimit:
      return limitPrice?.value ?? 0;
    case OrderType.Market:
      return lastPrice.value ?? 0;
    case OrderType.Stop:
      return stopPrice?.value ?? 0;
    default:
      return 0;
  }
});

const marginRequirement = computed(() =>
  calculateMarginRequirement(
    price.value,
    size.value,
    store.state.auth.account.leverage,
  ),
);

const validationSchema = computed(() => ({
  size: number()
    .required()
    .min(symbolInfo?.value?.minTradeAmount)
    .max(symbolInfo?.value?.maxTradeAmount),
  expirationType: number(),
  takeProfit: object({
    value: number().nullable(),
    asPercentage: boolean(),
  }),
  stopLoss: object({
    value: number().nullable(),
    asPercentage: boolean(),
  }),
}));

const { errors, handleSubmit } = useForm<EditOrderForm>({
  validationSchema,
  initialValues: {
    limitPrice: props.selectedOrder?.price,
    stopPrice: props.selectedOrder?.stopPrice,
    size: props.selectedOrder?.size,
    expirationType: ExpirationType.GoodTillCancel,
    takeProfit: {
      value: props.selectedOrder?.takeProfit,
      asPercentage: false,
    },
    stopLoss: {
      value: props.selectedOrder?.stopLoss,
      asPercentage: false,
    },
  },
});

const { value: limitPrice } = useField<number>('limitPrice');
const { value: stopPrice } = useField<number>('stopPrice');
const { value: size } = useField<number>('size');
const { value: expirationType } = useField<ExpirationType>('expirationType');
const { value: takeProfit } = useField<number>('takeProfit.value');
const { value: stopLoss } = useField<number>('stopLoss.value');
const { value: tpAsPercentage } = useField<boolean>('takeProfit.asPercentage');
const { value: slAsPercentage } = useField<boolean>('stopLoss.asPercentage');

const submit = handleSubmit(async (data) => {
  emit(
    'confirm',
    data.limitPrice,
    data.stopPrice,
    data.size,
    tpPrice.value > 0
      ? toFixedFractions(tpPrice.value, symbolInfo?.value?.precision)
      : 0,
    slPrice.value > 0
      ? toFixedFractions(slPrice.value, symbolInfo?.value?.precision)
      : 0,
  );
});

const { slExpectedReturn, tpExpectedReturn, slPrice, tpPrice } = useOrderForm({
  tpAsPercentage,
  slAsPercentage,
  tpOrRoe: takeProfit,
  slOrRoe: stopLoss,
  price,
  size,
  side: toRef(props.selectedOrder, 'side'),
  margin: marginRequirement,
});
</script>

<template>
  <KitDialog
    v-model="visible"
    :title="$t('dialog.modifyOrder')"
    data-test-id="modifyOrdersDialog"
  >
    <KitRow :label="$t('dialog.id')" data-test-id="modifyOrdersDialogPositionId"
      ><b>{{ selectedOrder.id }}</b></KitRow
    >
    <KitRow
      :label="$t('dialog.orderType')"
      data-test-id="modifyOrdersDialogOrderType"
    >
      <KitBadge
        :variant="selectedOrder.side === OrderSide.Buy ? 'success' : 'danger'"
        >{{ toOrderTypeName(selectedOrder.type, selectedOrder.side) }}</KitBadge
      >
    </KitRow>
    <KitRow
      :label="$t('dialog.symbol')"
      data-test-id="modifyOrdersDialogSymbol"
    >
      <KitCoin :pair="selectedOrder.pair" />
    </KitRow>
    <KitRow
      :label="$t('dialog.currentPrice')"
      data-test-id="modifyOrdersDialogCurrentPrice"
    >
      <KitLastPrice
        class="font-bold"
        :symbol="selectedOrder.pair.symbol"
        :precision="symbolInfo?.precision ?? 2"
        :fallback-price="lastPrice"
        :suffix="selectedOrder.pair.name.quote"
      />
    </KitRow>

    <div class="mt-8">
      <KitNumberField
        v-if="isLimitOrder"
        v-model="limitPrice"
        :label="$t('dialog.limitPrice')"
        data-test-id="modifyOrdersDialogLimitPrice"
        :suffix="selectedOrder.pair?.name.quote"
        :max-fraction="symbolInfo?.precision"
        :placeholder="fractionsToPlaceholder(symbolInfo?.precision)"
        :step="fractionsToStep(symbolInfo?.precision)"
        :initial-value-on-arrow="lastPrice"
      />

      <KitNumberField
        v-if="isStopOrder"
        v-model="stopPrice"
        class="mt-4"
        :label="$t('dialog.stopPrice')"
        data-test-id="modifyOrdersDialogStopPrice"
        :suffix="selectedOrder.pair?.name.quote"
        :placeholder="fractionsToPlaceholder(symbolInfo?.precision)"
        :step="fractionsToStep(symbolInfo?.precision)"
        :initial-value-on-arrow="lastPrice"
      />

      <KitNumberField
        v-model="size"
        class="mt-4"
        :label="$t('dialog.size')"
        data-test-id="modifyOrdersDialogSize"
        :placeholder="fractionsToPlaceholder(maxFraction)"
        :suffix="selectedOrder.pair?.name.base"
        :invalid="errors.size"
        :min="symbolInfo?.minTradeAmount"
        :step="symbolInfo?.tradeAmountStep ?? 0.1"
        :max-fraction="maxFraction"
      />

      <KitDropdown
        v-model="expirationType"
        :options="expirationOptions"
        :label="$t('dialog.expirationDate')"
        data-test-id="modifyOrdersDialogExpirationDate"
        class="mt-4"
        variant="secondary"
      />

      <MarketOrderFieldTPSL
        v-model:takeProfit="takeProfit"
        v-model:tpAsPercentage="tpAsPercentage"
        v-model:stopLoss="stopLoss"
        v-model:slAsPercentage="slAsPercentage"
        :pair="selectedOrder?.pair"
        :precision="symbolInfo?.precision"
        :actual-sl="slPrice"
        :actual-tp="tpPrice"
        class="mt-4"
        data-test-id="modifyOrdersDialogTpSl"
        :take-profit-expected-return="tpExpectedReturn"
        :stop-loss-expected-return="slExpectedReturn"
      />

      <div>
        <div class="mt-8 flex gap-6">
          <KitButton
            :disabled="loading"
            data-test-id="modifyOrdersDialogCancelButton"
            @click="visible = false"
            >{{ $t('dialog.cancel') }}</KitButton
          >
          <KitButton
            variant="primary"
            visible-arrow-right
            :loading="loading"
            data-test-id="modifyOrdersDialogConfirmButton"
            @click="submit()"
            >{{ $t('dialog.confirm') }}</KitButton
          >
        </div>
      </div>
    </div>
  </KitDialog>
</template>
