import { captureException } from '@sentry/vue';

const intervalStore: { [key: string]: number } = {};

export const interval = (cb: Function, ms: number, key: string) => {
  removeInterval(key);

  let locked = false;

  intervalStore[key] = setInterval(async () => {
    if (locked) return;

    locked = true;

    try {
      await cb();
    } catch (e) {
      captureException(e);
    }

    locked = false;
  }, ms);
};

export const removeInterval = (key: string) => {
  if (intervalStore[key]) {
    clearInterval(intervalStore[key]);
    delete intervalStore[key];
  }
};
