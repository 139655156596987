<script lang="ts" setup>
import KitChange from '@/margin/components/Kit/KitChange.vue';
import KitNumberField from '@/margin/components/Kit/KitNumberField.vue';
import KitSelectField from '@/margin/components/Kit/KitSelectField.vue';
import { computed, PropType } from 'vue';
import { Pair } from '@/margin/utils/trading.ts';
import { useI18n } from 'vue-i18n';
import { fractionsToPlaceholder, fractionsToStep } from '@/margin/utils';

const { t } = useI18n();

const props = defineProps({
  pair: {
    type: Object as PropType<Pair>,
    required: true,
  },
  precision: {
    type: Number,
    required: true,
  },
  disabled: {
    type: Boolean,
    default: false,
  },
  showRoe: {
    type: Boolean,
    default: true,
  },
  takeProfitExpectedReturn: {
    type: Number,
    required: true,
  },
  stopLossExpectedReturn: {
    type: Number,
    required: true,
  },
  actualTp: {
    type: Number,
    required: true,
  },
  actualSl: {
    type: Number,
    required: true,
  },
  invalidTp: {
    type: [Boolean, String],
    default: null,
  },
  invalidSl: {
    type: [Boolean, String],
    default: null,
  },
  minTp: {
    type: Number,
    default: null,
  },
  minSl: {
    type: Number,
    default: null,
  },
  maxTp: {
    type: Number,
    default: null,
  },
  maxSl: {
    type: Number,
    default: null,
  },
});

const takeProfit = defineModel('takeProfit', {
  type: Number,
  required: true,
});

const tpAsPercentage = defineModel('tpAsPercentage', {
  type: Boolean,
  required: true,
});

const stopLoss = defineModel('stopLoss', {
  type: Number,
  required: true,
});

const slAsPercentage = defineModel('slAsPercentage', {
  type: Boolean,
  required: true,
});

const options = computed(() =>
  [
    {
      label: props.pair?.name.quote,
      value: false,
    },
    props.showRoe ? { label: t('marketOrder.percentROE'), value: true } : null,
  ].filter(Boolean),
);
</script>

<template>
  <div>
    <KitNumberField
      v-model="takeProfit"
      :max-fraction="tpAsPercentage ? 2 : precision"
      :label="$t('marketOrder.takeProfit')"
      :placeholder="fractionsToPlaceholder(tpAsPercentage ? 2 : precision)"
      :step="fractionsToStep(tpAsPercentage ? 2 : precision)"
      data-test-id="takeProfit"
      :disabled="disabled"
      :invalid="invalidTp"
      :min="minTp"
      :max="maxTp"
    >
      <template #suffix>
        <KitSelectField
          v-model="tpAsPercentage"
          class="w-fit whitespace-nowrap font-medium"
          :options="options"
          data-test-id="selectFieldTakeProfit"
          :disabled="disabled"
        />
      </template>
    </KitNumberField>

    <div class="mt-1 flex justify-between text-xs text-m-gray">
      <div>
        <span v-if="tpAsPercentage"
          >{{ $num(actualTp, precision) }} {{ props.pair?.name.quote }}</span
        >
      </div>
      <div class="text-right">
        {{ $t('marketOrder.expectedReturn') }}:
        <KitChange
          :value="takeProfitExpectedReturn"
          :number-style="precision"
          data-test-id="takeProfitExpectedReturn"
          :suffix="` ${pair?.name.quote}`"
        />
      </div>
    </div>

    <KitNumberField
      v-model="stopLoss"
      class="mt-4"
      :max-fraction="slAsPercentage ? 2 : precision"
      :label="$t('marketOrder.stopLoss')"
      :placeholder="fractionsToPlaceholder(slAsPercentage ? 2 : precision)"
      :step="fractionsToStep(slAsPercentage ? 2 : precision)"
      data-test-id="stopLoss"
      :disabled="disabled"
      :invalid="invalidSl"
      :min="minSl"
      :max="maxSl"
    >
      <template #suffix>
        <KitSelectField
          v-model="slAsPercentage"
          class="w-fit whitespace-nowrap font-medium"
          :placeholder="$t('marketOrder.percentROE')"
          :options="options"
          data-test-id="selectFieldStopLoss"
          :disabled="disabled"
        />
      </template>
    </KitNumberField>

    <div class="mt-1 flex justify-between text-xs text-m-gray">
      <div>
        <span v-if="slAsPercentage"
          >{{ $num(actualSl, precision) }} {{ props.pair?.name.quote }}</span
        >
      </div>
      <div class="text-right">
        {{ $t('marketOrder.expectedReturn') }}:
        <KitChange
          :value="stopLossExpectedReturn"
          :number-style="precision"
          :suffix="` ${pair?.name.quote}`"
        />
      </div>
    </div>
  </div>
</template>
