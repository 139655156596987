import { EditOrderParams, Order } from '@/margin/types';
import { Module } from 'vuex';
import { closeOrder, editOrder, getOrderList } from '@/margin/api';
import { StoreState } from '@/margin/store/index.ts';
import { Interval, UPDATE_ORDERS_INTERVAL } from '@/margin/const.ts';
import { interval, removeInterval } from '@/margin/utils/interval.ts';
import { captureException } from '@sentry/vue';
import { toChunks } from '../utils';

export interface OrdersState {
  orders: Order[];
  loading: boolean;
}

export const OrdersModule: Module<OrdersState, StoreState> = {
  namespaced: true,
  state: {
    orders: [],
    loading: true,
  },
  mutations: {
    SET_ORDERS(state, orders: Order[]) {
      state.orders = orders;
    },
    SET_LOADING(state, loading: boolean) {
      state.loading = loading;
    },
  },
  actions: {
    async loadOrders({ commit }, ignoreLoading = false) {
      const accountId = this.state.auth.account?.id;

      removeInterval(Interval.Positions);

      if (!accountId) return;

      if (!ignoreLoading) {
        commit('SET_LOADING', true);
      }

      const setOrders = async () =>
        commit('SET_ORDERS', await getOrderList(accountId));

      try {
        await setOrders();
        interval(setOrders, UPDATE_ORDERS_INTERVAL, Interval.Orders);
      } catch (e) {
        commit('SET_ORDERS', []);
        captureException(e);
      }

      if (!ignoreLoading) {
        commit('SET_LOADING', false);
      }
    },
    clearOrders({ commit }) {
      commit('SET_ORDERS', []);
    },
    async closeAllOrders({ state }) {
      const accountId = this.state.auth.account?.id;

      if (!accountId) return;

      let lastError: Error;

      for (const orders of toChunks(state.orders, 10)) {
        try {
          await Promise.all(
            orders.map((order) => closeOrder(accountId, order.id)),
          );
        } catch (e) {
          lastError = e;
          captureException(e);
        }
      }

      this.dispatch('orders/loadOrders', true);

      if (lastError) throw lastError;
    },
    async closeOrder(_, id: number) {
      const accountId = this.state.auth.account?.id;

      if (!accountId) return;

      await closeOrder(accountId, id);

      this.dispatch('orders/loadOrders', true);
    },
    async editOrder(_, order: EditOrderParams) {
      const accountId = this.state.auth.account?.id;

      if (!accountId) return;

      await editOrder(accountId, {
        id: order.id,
        limitPrice: order.limitPrice,
        stopPrice: order.stopPrice,
        size: order.size,
        takeProfit: order.takeProfit,
        stopLoss: order.stopLoss,
      });

      this.dispatch('orders/loadOrders', true);
    },
  },
};
