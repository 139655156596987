<script setup lang="ts">
import Calendar, { CalendarDateSlotOptions } from 'primevue/calendar';
import IconCalendar from '../Icon/IconCalendar.vue';
import { ref } from 'vue';

defineProps({
  label: {
    type: String,
    required: true,
  },
});

const model = defineModel<Date>({
  type: Date,
  required: true,
});

const wrapper = ref<HTMLElement>();
const focused = ref(false);

const focus = () => {
  wrapper.value?.querySelector('input')?.focus();
};

const isDaySelected = (data: CalendarDateSlotOptions) => {
  const date = model.value;

  if (!date) return false;

  return (
    date.getDate() === data.day &&
    date.getMonth() === data.month &&
    date.getFullYear() === data.year
  );
};
</script>
<template>
  <div
    ref="wrapper"
    :class="[
      'cursor-pointer p-[1px] font-archivo text-sm',
      {
        'bg-gradient-to-r from-m-primary-400 to-m-primary-300': focused,
        'bg-m-gray-500': !focused,
      },
    ]"
    @click="focus"
  >
    <div
      class="flex w-full items-center justify-between bg-m-gray-500 px-2 py-[5px]"
    >
      <div class="flex w-full items-center gap-1">
        <span class="whitespace-nowrap font-light text-m-gray-400">{{
          label
        }}</span>

        <Calendar
          v-model="model"
          :pt="{
            panel: 'bg-m-gray-700 px-[5px]',
            previousIcon: 'w-[10px]',
            nextIcon: 'w-[10px]',
            previousButton: '',
            nextButton: '',
            title: '',
            input: 'bg-transparent h-full w-full outline-none',
            root: 'w-full',
            header:
              'flex items-center justify-between border-b border-m-gray-600 pb-2.5 pt-2 px-2',
            monthTitle: 'text-sm font-medium mr-1',
            decadeTitle: 'text-sm font-medium',
            yearTitle: 'text-sm font-medium',
            tableHeaderCell: 'pb-2',
            day: 'text-center group',
            dayLabel: 'w-[34px] h-[34px] text-center block outline-none',
            weekday: 'uppercase text-[0.625rem] text-m-gray-200 font-normal',
          }"
          @focus="focused = true"
          @blur="focused = false"
        >
          <template #date="{ date }">
            <div
              :class="[
                'flex h-full w-full items-center rounded-full p-0.5',
                {
                  'cursor-pointer group-hover:bg-m-gray-500': date.selectable,
                  'bg-gradient-to-r from-m-primary-400 to-m-primary-300':
                    date.selectable && isDaySelected(date),
                },
              ]"
            >
              <div v-if="date.selectable" class="w-full">{{ date.day }}</div>
            </div>
          </template>
        </Calendar>
      </div>

      <IconCalendar class="h-3.5 w-3.5 shrink-0" />
    </div>
  </div>
</template>
