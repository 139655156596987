export const redirectFromParent = (path: string) => {
  window.parent.postMessage({ type: 'redirect', path }, '*');
};

export const redirectToTransfer = () =>
  redirectFromParent(import.meta.env.VITE_BITMARKET_TRANSFER_PATH);
export const redirectToLogin = () =>
  redirectFromParent(import.meta.env.VITE_BITMARKET_LOGIN_PATH);
export const redirectToRegister = () =>
  redirectFromParent(import.meta.env.VITE_BITMARKET_REGISTER_PATH);
