import { Module } from 'vuex';
import { StoreState } from '.';
import { toJSON } from '@/margin/utils';

export interface SettingsState {
  showConfirmAction: boolean;
  showOrderOnChart: boolean;
  showTPSLOnChart: boolean;
  showPositionsOnChart: boolean;
}

export const DEFAULT_SETTINGS: SettingsState = {
  showConfirmAction: true,
  showOrderOnChart: true,
  showTPSLOnChart: true,
  showPositionsOnChart: true,
};

const SETTINGS_STORAGE_KEY = 'settings';

const getPredefinedSettings = () => {
  const preference = localStorage.getItem(SETTINGS_STORAGE_KEY);

  return {
    ...DEFAULT_SETTINGS,
    ...(toJSON<SettingsState>(preference) ?? {}),
  };
};

export const SettingsModule: Module<SettingsState, StoreState> = {
  namespaced: true,
  state: getPredefinedSettings(),
  mutations: {
    SET_SETTINGS(state, settings: SettingsState) {
      state.showConfirmAction = settings.showConfirmAction;
      state.showOrderOnChart = settings.showOrderOnChart;
      state.showTPSLOnChart = settings.showTPSLOnChart;
      state.showPositionsOnChart = settings.showPositionsOnChart;
    },
    SET_SHOW_CONFIRMATION_ACTION(state, showConfirmAction: boolean) {
      state.showConfirmAction = showConfirmAction;
    },
  },
  actions: {
    setSettings({ commit }, settings: SettingsState) {
      commit('SET_SETTINGS', { ...settings });
      localStorage.setItem(SETTINGS_STORAGE_KEY, JSON.stringify(settings));
    },
    resetSettings({ commit }) {
      commit('SET_SETTINGS', { ...DEFAULT_SETTINGS });
      localStorage.removeItem(SETTINGS_STORAGE_KEY);
    },
    setShowConfirmationAction({ commit }, showConfirmAction: boolean) {
      commit('SET_SHOW_CONFIRMATION_ACTION', showConfirmAction);
    },
  },
  getters: {
    isChanged(state) {
      return Object.entries(DEFAULT_SETTINGS).some(
        ([key, value]) => state[key] !== value,
      );
    },
  },
};
