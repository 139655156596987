<template>
  <svg
    width="12"
    height="12"
    viewBox="0 0 12 12"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M10.4515 11.0713L6.64148 7.2606C4.9466 8.46557 2.61111 8.17083 1.26873 6.58255C-0.0736446 4.99427 0.0249224 2.64232 1.49548 1.17193C2.96566 -0.299104 5.31783 -0.398097 6.90641 0.944209C8.49498 2.28651 8.78989 4.62223 7.58482 6.31727L11.3948 10.1279L10.4522 11.0706L10.4515 11.0713ZM4.32348 1.33326C3.05928 1.33297 1.96861 2.22038 1.7118 3.45822C1.45499 4.69606 2.10262 5.94411 3.2626 6.44676C4.42258 6.9494 5.77608 6.56849 6.50364 5.53463C7.23121 4.50078 7.13286 3.09814 6.26815 2.17593L6.67148 2.57593L6.21682 2.1226L6.20882 2.1146C5.71001 1.61272 5.03108 1.33135 4.32348 1.33326Z"
      fill="currentColor"
    />
  </svg>
</template>
