<script lang="ts" setup>
import { computed } from 'vue';
import { formatNumber } from '@/margin/i18n.ts';
import { useI18n } from 'vue-i18n';
import { PropType } from 'vue';
import { AccountInfo } from '@/margin/types';
import KitTooltip from '../Kit/KitTooltip.vue';
import IconInfo from '../Icon/IconInfo.vue';

const { t } = useI18n();

const props = defineProps({
  account: {
    type: Object as PropType<AccountInfo>,
    required: true,
  },
  showValue: {
    type: Boolean,
    default: true,
  },
  totalUnrealizedPnl: {
    type: Number,
    default: 0,
  },
});

const rows = computed(() => {
  const currency = props.account?.currency;

  return [
    {
      name: t('marketOrder.equity'),
      value: props.showValue
        ? `${formatNumber(props.account?.equity, props.account?.precision ?? 'auto')} ${currency}`
        : '-',
      tooltip: t('marketOrder.equity.tooltip'),
    },
    {
      name: t('marketOrder.margin'),
      value: props.showValue
        ? `${formatNumber(props.account?.margin, props.account?.precision ?? 'auto')} ${currency}`
        : '-',
      tooltip: t('marketOrder.margin.tooltip'),
    },
    {
      name: t('marketOrder.unrealizedPnl'),
      value: props.showValue
        ? `${formatNumber(props.totalUnrealizedPnl, props.account?.precision ?? 'auto')} ${currency}`
        : '-',
      tooltip: t('marketOrder.unrealizedPnl.tooltip'),
    },
    {
      name: t('marketOrder.freeMargin'),
      value: props.showValue
        ? `${formatNumber(props.account?.equity - props.account?.margin, props.account?.precision ?? 'auto')} ${currency}`
        : '-',
      tooltip: t('marketOrder.freeMargin.tooltip'),
    },
    {
      name: t('marketOrder.marginLevel'),
      value: props.showValue
        ? `${formatNumber(props.account.marginLevel, 'percent')}%`
        : '-',
      tooltip: t('marketOrder.marginLevel.tooltip'),
    },
  ];
});
</script>

<template>
  <div class="flex flex-col gap-2">
    <div
      v-for="(row, index) in rows"
      :key="index"
      class="flex justify-between gap-2 text-xs"
    >
      <div class="flex items-center">
        <KitTooltip :text="row.tooltip">
          <IconInfo class="mr-1 h-3 w-3" />
        </KitTooltip>
        <span>{{ row.name }}:</span>
      </div>
      <span class="text-right font-medium">{{ row.value }}</span>
    </div>
  </div>
</template>
