<script lang="ts" setup>
import KitSelectField from '@/margin/components/Kit/KitSelectField.vue';
import { ExpirationType, Option } from '@/margin/types';
import { PropType } from 'vue';
import { useI18n } from 'vue-i18n';

const { t } = useI18n();

defineProps({
  disabled: {
    type: Boolean,
    default: false,
  },
});

const model = defineModel({
  type: Number as PropType<ExpirationType>,
  required: true,
});

const expirationOptions: Option<ExpirationType>[] = [
  {
    label: t('marketOrder.goodTillCanceled'),
    value: ExpirationType.GoodTillCancel,
  },
];
</script>

<template>
  <div class="flex items-center justify-between">
    <div class="w-full text-sm">{{ $t('dialog.expirationDate') }}</div>
    <KitSelectField
      v-model="model"
      class="whitespace-nowrap font-medium"
      :options="expirationOptions"
      :disabled="disabled"
    />
  </div>
</template>
