<script setup lang="ts">
import { PropType, ref } from 'vue';
import KitOverlay from '../Kit/KitOverlay.vue';
import TickerTable from './TickerTable.vue';
import { CurrencyPair } from '@/margin/types';
import IconChevronDown from '@/margin/components/Icon/IconChevronDown.vue';
import KitStar from '@/margin/components/Kit/KitStar.vue';

defineProps({
  pair: {
    type: Object as PropType<CurrencyPair>,
    required: true,
  },
  favorite: {
    type: Boolean,
    required: true,
  },
});

const emit = defineEmits<{
  (e: 'favorite', favorite: boolean);
  (e: 'select', symbol: string);
}>();

const overlay = ref<InstanceType<typeof KitOverlay>>();

const onSelect = (symbol: string) => {
  emit('select', symbol);
  overlay.value.hide();
};
</script>

<template>
  <div
    class="relative mr-4 flex w-full max-w-[200px] cursor-pointer items-center justify-between gap-1 bg-m-gray-700 px-2 py-1"
    @mouseenter="overlay.toggle($event)"
    @click="overlay.toggle($event)"
  >
    <div class="flex w-full items-center gap-2">
      <KitStar
        v-if="$store.state.auth.isAuthenticated"
        :selected="favorite"
        size="large"
        @toggle="$emit('favorite', $event)"
      />

      <div class="size-[30px] shrink-0 overflow-hidden rounded-full bg-m-gray-600">
        <img
          v-if="pair"
          :src="`/crypto/${pair.pair.base.toLowerCase()}.svg`"
          :alt="pair.pair.name.base"
          class="object-fit h-full w-full"
        />
      </div>

      <div class="flex flex-col text-sm overflow-hidden">
        <div class="font-bold">
          {{ pair?.pair.name.base }}/{{ pair?.pair.name.quote }}
        </div>
        <div class="overflow-hidden text-ellipsis">{{ pair?.coin?.name ?? '-' }}</div>
      </div>
    </div>

    <IconChevronDown
      :class="`transform transition-transform ${overlay?.isVisible ? 'rotate-180' : ''}`"
    />
  </div>
  <KitOverlay
    ref="overlay"
    class="flex max-h-[500px] w-[90%] max-w-[500px]"
    @mouseleave="overlay.hide()"
  >
    <TickerTable @select="onSelect" />
  </KitOverlay>
</template>
