<script lang="ts" setup>
import IconStarEmpty from '../Icon/IconStarEmpty.vue';
import IconStarFull from '../Icon/IconStarFull.vue';
import { PropType } from 'vue';

defineProps({
  size: {
    type: String as PropType<'base' | 'large'>,
    default: 'base',
  },
  selected: {
    type: Boolean,
    default: false,
  },
});

defineEmits<{
  (e: 'toggle', selected: boolean): void;
}>();

const sizes = {
  base: 'size-3',
  large: 'size-4',
};
</script>

<template>
  <div class="cursor-pointer" @click.stop="$emit('toggle', !selected)">
    <IconStarFull v-if="selected" :class="sizes[size]" />
    <IconStarEmpty v-else :class="sizes[size]" />
  </div>
</template>
