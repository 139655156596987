<template>
  <div id="margin" class="font-encode">
    <MarginLayout />
    <TheNotifications />
  </div>
</template>

<script setup lang="ts">
import MarginLayout from '@/margin/components/MarginLayout.vue';
import { PropType, watch } from 'vue';
import { useStore } from './store';
import TheNotifications from '@/margin/components/TheNotifications.vue';
import { MarginSettings } from './types';

const props = defineProps({
  settings: {
    type: Object as PropType<MarginSettings>,
    required: true,
  },
});

defineExpose({
  changeLocale: (locale: string) => {
    store.dispatch('auth/setLocale', locale);
  },
});

const store = useStore();

watch(
  () => store.state.auth.account?.id,
  (accountId: number) => {
    if (accountId) {
      store.dispatch('favorite/loadFavoriteSymbols');
    }
  },
);

watch(
  () => props.settings,
  (settings) => {
    store.dispatch('auth/setUser', settings);
  },
);
</script>
