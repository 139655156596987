<template>
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clip-path="url(#clip0_653_4947)">
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M7.99967 0.666504C8.36786 0.666504 8.66634 0.964981 8.66634 1.33317V3.99984C8.66634 4.36803 8.36786 4.6665 7.99967 4.6665C7.63148 4.6665 7.33301 4.36803 7.33301 3.99984V1.33317C7.33301 0.964981 7.63148 0.666504 7.99967 0.666504Z"
        fill="currentColor"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M7.99967 11.333C8.36786 11.333 8.66634 11.6315 8.66634 11.9997V14.6663C8.66634 15.0345 8.36786 15.333 7.99967 15.333C7.63148 15.333 7.33301 15.0345 7.33301 14.6663V11.9997C7.33301 11.6315 7.63148 11.333 7.99967 11.333Z"
        fill="currentColor"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M2.81489 2.81489C3.07524 2.55454 3.49735 2.55454 3.7577 2.81489L5.64437 4.70156C5.90472 4.96191 5.90472 5.38402 5.64437 5.64437C5.38402 5.90472 4.96191 5.90472 4.70156 5.64437L2.81489 3.7577C2.55454 3.49735 2.55454 3.07524 2.81489 2.81489Z"
        fill="currentColor"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M10.3549 10.3549C10.6153 10.0946 11.0374 10.0946 11.2977 10.3549L13.1844 12.2416C13.4448 12.5019 13.4448 12.9241 13.1844 13.1844C12.9241 13.4448 12.5019 13.4448 12.2416 13.1844L10.3549 11.2977C10.0946 11.0374 10.0946 10.6153 10.3549 10.3549Z"
        fill="currentColor"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M0.666504 7.99967C0.666504 7.63148 0.964981 7.33301 1.33317 7.33301H3.99984C4.36803 7.33301 4.6665 7.63148 4.6665 7.99967C4.6665 8.36786 4.36803 8.66634 3.99984 8.66634H1.33317C0.964981 8.66634 0.666504 8.36786 0.666504 7.99967Z"
        fill="currentColor"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M11.333 7.99967C11.333 7.63148 11.6315 7.33301 11.9997 7.33301H14.6663C15.0345 7.33301 15.333 7.63148 15.333 7.99967C15.333 8.36786 15.0345 8.66634 14.6663 8.66634H11.9997C11.6315 8.66634 11.333 8.36786 11.333 7.99967Z"
        fill="currentColor"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M5.64437 10.3549C5.90472 10.6153 5.90472 11.0374 5.64437 11.2977L3.7577 13.1844C3.49735 13.4448 3.07524 13.4448 2.81489 13.1844C2.55454 12.9241 2.55454 12.5019 2.81489 12.2416L4.70156 10.3549C4.96191 10.0946 5.38402 10.0946 5.64437 10.3549Z"
        fill="currentColor"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M13.1844 2.81489C13.4448 3.07524 13.4448 3.49735 13.1844 3.7577L11.2977 5.64437C11.0374 5.90472 10.6153 5.90472 10.3549 5.64437C10.0946 5.38402 10.0946 4.96191 10.3549 4.70156L12.2416 2.81489C12.5019 2.55454 12.9241 2.55454 13.1844 2.81489Z"
        fill="currentColor"
      />
    </g>
    <defs>
      <clipPath id="clip0_653_4947">
        <rect width="16" height="16" fill="currentColor" />
      </clipPath>
    </defs>
  </svg>
</template>
