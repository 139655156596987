<script setup lang="ts">
import { OrderSide, TableColumn } from '@/margin/types';
import KitTable from '../Kit/KitTable.vue';
import KitBadge from '../Kit/KitBadge.vue';
import OrdersFiltersHistory from '@/margin/components/Orders/OrdersFiltersHistory.vue';
import { computed, onMounted, reactive, watch } from 'vue';
import { DateRangeType, getDateRange } from '@/margin/utils/dateRange.ts';
import { useDebounceFn } from '@vueuse/core';
import { useStore } from '@/margin/store';
import { toOrderTypeName } from '../../utils/helpers.ts';
import KitCoin from '@/margin/components/Kit/KitCoin.vue';
import { toSearchText } from '@/margin/utils';
import { formatDate } from '@/margin/i18n.ts';
import { useI18n } from 'vue-i18n';
import KitChange from '../Kit/KitChange.vue';
import KitPrice from '../Kit/KitPrice.vue';

const store = useStore();

const { t } = useI18n();

const DEFAULT_RANGE = DateRangeType.LastWeek;

const defaultRange = getDateRange(DEFAULT_RANGE);
const DEFAULT_FILTERS = {
  search: '',
  range: DEFAULT_RANGE,
  from: defaultRange.from,
  to: defaultRange.to,
};

const filters = reactive({ ...DEFAULT_FILTERS });

const filterChanged = computed(() => {
  return (
    filters.search !== DEFAULT_FILTERS.search ||
    filters.range !== DEFAULT_FILTERS.range ||
    filters.from?.toISOString() !== DEFAULT_FILTERS.from?.toISOString() ||
    filters.to?.toISOString() !== DEFAULT_FILTERS.to?.toISOString()
  );
});

const loadData = () => {
  store.dispatch('history/loadHistory', {
    from: filters.from,
    to: filters.to,
  });
};

const onChange = useDebounceFn(loadData, 500);
onMounted(loadData);
watch(() => store.state.auth.account?.id, loadData);

const history = computed(() => {
  const search = toSearchText(filters.search);

  return store.state.history.history.filter((entry) => {
    if (!search) return true;

    const text = [
      entry.pair.base,
      entry.pair.quote,
      entry.side,
      formatDate(entry.date, true),
    ].join(' ');

    return toSearchText(text).includes(search);
  });
});

const columns: TableColumn[] = [
  { field: 'id', header: t('orders.tableHistory.header.id'), sortable: true },
  {
    field: 'date',
    header: t('orders.tableHistory.header.date'),
    sortable: true,
  },
  {
    field: 'symbol',
    header: t('orders.tableHistory.header.symbol'),
    sortable: true,
    sortField: 'pair.symbol',
  },
  {
    field: 'type',
    header: t('orders.tableHistory.header.type'),
    sortable: true,
    sortField: 'side',
  },
  {
    field: 'openPrice',
    header: t('orders.tableHistory.header.openPrice'),
    align: 'right',
    sortable: true,
  },
  {
    field: 'size',
    header: t('orders.tableHistory.header.size'),
    align: 'right',
    sortable: true,
  },
  {
    field: 'closePrice',
    header: t('orders.tableHistory.header.closePrice'),
    align: 'right',
    sortable: true,
  },
  {
    field: 'commission',
    header: t('orders.tableHistory.header.commission'),
    align: 'right',
    sortable: true,
  },
  {
    field: 'financingRate',
    header: t('orders.tableHistory.header.financingRate'),
    align: 'right',
    sortable: true,
  },
  {
    field: 'profit',
    header: t('orders.tableHistory.header.profit'),
    align: 'right',
    sortable: true,
  },
];

const resetFilters = () => {
  const range = getDateRange(DEFAULT_RANGE);
  filters.search = '';
  filters.range = DEFAULT_RANGE;
  filters.from = range.from;
  filters.to = range.to;
};

watch(() => [filters.from, filters.to], onChange);
</script>

<template>
  <div class="flex h-full flex-col gap-4">
    <OrdersFiltersHistory
      v-model:search="filters.search"
      v-model:range="filters.range"
      v-model:from="filters.from"
      v-model:to="filters.to"
      :filter-changed="filterChanged"
      @reset="resetFilters"
    />
    <KitTable
      :data="history"
      :columns="columns"
      sort-field="date"
      :sort-order="-1"
      :loading="$store.state.history.loading"
      class="h-full"
      pagination
      :rows="20"
      :loading-rows="20"
      :auto-hide-pagination="false"
    >
      <template #field-date="{ data }">
        {{ $date(data.date, true) }}
      </template>

      <template #field-type="{ data }">
        <KitBadge
          :variant="data.side === OrderSide.Buy ? 'success' : 'danger'"
          >{{ toOrderTypeName(null, data.side) }}</KitBadge
        >
      </template>

      <template #field-symbol="{ data }">
        <KitCoin :pair="data.pair" />
      </template>

      <template #field-openPrice="{ data }">
        <KitPrice :symbol="data.pair.symbol" :value="data.openPrice" />
        <span v-if="data.openPrice" class="ml-1 text-m-gray">{{
          data.pair.name.quote
        }}</span>
      </template>

      <template #field-size="{ data }">
        {{ $num(data.size, 'auto') }}
        <span v-if="data.size" class="text-m-gray">{{
          data.pair.name.base
        }}</span>
      </template>

      <template #field-closePrice="{ data }">
        <KitPrice :symbol="data.pair.symbol" :value="data.closePrice" />
        <span v-if="data.closePrice" class="ml-1 text-m-gray">{{
          data.pair.name.quote
        }}</span>
      </template>

      <template #field-commission="{ data }">
        {{
          $num(
            data.commission,
            $store.state.auth.account?.isFiatOrStable ? 4 : 8,
          )
        }}
        <span v-if="data.commission" class="ml-1 text-m-gray">{{
          $store.state.auth.account?.currency
        }}</span>
      </template>

      <template #field-financingRate="{ data }">
        {{
          $num(
            data.swap || null,
            $store.state.auth.account?.isFiatOrStable ? 4 : 8,
          )
        }}
        <span v-if="data.swap" class="ml-1 text-m-gray">{{
          $store.state.auth.account?.currency
        }}</span>
      </template>

      <template #field-profit="{ data }">
        <KitChange
          v-if="data.profit !== 0"
          :number-style="$store.state.auth.account?.isFiatOrStable ? 4 : 8"
          hide-plus
          :value="data.profit"
        />
        <span v-else>{{ data.profit }}</span>
      </template>
    </KitTable>
  </div>
</template>
