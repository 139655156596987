<script setup lang="ts">
import { ref } from 'vue';
import OverlayPanel from 'primevue/overlaypanel';

const panelRef = ref<InstanceType<typeof OverlayPanel>>();

const isVisible = ref(false);

defineExpose({
  toggle(event: MouseEvent) {
    panelRef.value.toggle(event);
    isVisible.value = !isVisible.value;
  },
  show(event: MouseEvent) {
    panelRef.value.show(event);
    isVisible.value = true;
  },
  hide() {
    panelRef.value.hide();
    isVisible.value = false;
  },
  isVisible,
});
</script>

<template>
  <OverlayPanel
    ref="panelRef"
    :pt="{
      root: 'bg-m-gray-600 my-0.5 max-h-[700px] overflow-hidden',
      content: 'w-full p-4',
    }"
    class="font-encode"
  >
    <slot></slot>
  </OverlayPanel>
</template>
