import { OrderBookLevel } from '@/margin/types';
import { Module } from 'vuex';
import { StoreState } from '@/margin/store/index.ts';
import { closeOrderBookSocket, connectToOrderBookSocket } from '@/margin/api';
import {
  calculateLastPrice,
  filterOutliers,
  toOrderBookLevels,
} from '@/margin/utils/orderBook.ts';
import { fractionsToPlaceholder } from '../utils';
import { getPairPrecision } from '../utils/coin';

const DEFAULT_GROUPING_OPTIONS = [0.01, 0.1, 0.5, 1, 5, 10, 50, 100];

const generateGroupingOptionsFromPrice = (fractions: number) => {
  const base = Number(fractionsToPlaceholder(fractions));

  return [
    base,
    base * 10,
    base * 50,
    base * 100,
    base * 500,
    base * 1000,
    base * 5000,
    base * 10000,
  ];
};

export interface OrderBookState {
  bids: OrderBookLevel[];
  asks: OrderBookLevel[];
  rawAsks: [number, number][];
  rawBids: [number, number][];
  groupingOptions: number[];
  grouping: number;
  lastPrice: number;
  previousPrice: number;
  symbol: string;
  loading: boolean;
}

export const OrderBookModule: Module<OrderBookState, StoreState> = {
  namespaced: true,
  state: {
    bids: [],
    asks: [],
    rawAsks: [],
    rawBids: [],
    groupingOptions: DEFAULT_GROUPING_OPTIONS,
    grouping: 0.01,
    lastPrice: null,
    previousPrice: null,
    symbol: null,
    loading: true,
  },
  mutations: {
    SET_GROUPING(state, grouping: number) {
      state.grouping = grouping;
    },
    SET_GROUPING_OPTIONS(state, groupingOptions: number[]) {
      state.groupingOptions = groupingOptions;
      state.grouping = state.groupingOptions[0];
    },
    SET_BIDS(state, bids: OrderBookLevel[]) {
      state.bids = bids;
    },
    SET_ASKS(state, asks: OrderBookLevel[]) {
      state.asks = asks;
    },
    SET_RAW_BIDS(state, bids: [number, number][]) {
      state.rawBids = bids;
    },
    SET_RAW_ASKS(state, asks: [number, number][]) {
      state.rawAsks = asks;
    },
    SET_LAST_PRICE(state, lastPrice: number) {
      if (state.lastPrice === lastPrice) return;

      state.previousPrice = state.lastPrice;
      state.lastPrice = lastPrice;
    },
    SET_SYMBOL(state, symbol: string) {
      state.symbol = symbol;
    },
    SET_LOADING(state, loading: boolean) {
      state.loading = loading;
    },
    RESET(state) {
      state.previousPrice = null;
      state.lastPrice = null;
      state.bids = [];
      state.asks = [];
      state.rawBids = [];
      state.rawAsks = [];
      state.symbol = null;
      state.loading = true;
      state.groupingOptions = DEFAULT_GROUPING_OPTIONS;
      state.grouping = DEFAULT_GROUPING_OPTIONS[0];
    },
  },
  actions: {
    async loadOrders({ commit, state }) {
      const symbol = this.state.currencyPair.selected?.symbol;

      closeOrderBookSocket();
      commit('RESET');

      if (!symbol) return;

      const precision = await getPairPrecision(symbol);

      connectToOrderBookSocket(symbol, (data) => {
        if (this.state.currencyPair.selected?.symbol !== data.symbol) return;

        const basePrice =
          this.getters['currencyPair/currentPair']?.detail?.lastPrice;

        const bids = data.bids.filter((level) =>
          filterOutliers(level, basePrice),
        );
        const asks = data.asks.filter((level) =>
          filterOutliers(level, basePrice),
        );

        const lastPrice = calculateLastPrice(bids, asks);

        if (state.loading) {
          commit(
            'SET_GROUPING_OPTIONS',
            generateGroupingOptionsFromPrice(precision.precision),
          );
        }

        commit('SET_LOADING', false);

        commit('SET_LAST_PRICE', lastPrice);
        commit('SET_RAW_BIDS', bids);
        commit('SET_RAW_ASKS', asks);
        commit('SET_BIDS', toOrderBookLevels(bids, state.grouping));
        commit('SET_ASKS', toOrderBookLevels(asks, state.grouping));
        commit('SET_SYMBOL', symbol);
      });
    },
    closeOrders({ commit }) {
      closeOrderBookSocket();
      commit('RESET');
    },
    setGrouping({ commit }, grouping: number) {
      commit('SET_GROUPING', grouping);
    },
  },
  getters: {
    percentage: (state) => {
      const totalAsks = state.rawAsks.reduce((acc, order) => acc + order[1], 0);
      const totalBids = state.rawBids.reduce((acc, order) => acc + order[1], 0);
      const total = totalAsks + totalBids;

      return {
        asks: total > 0 ? (totalAsks / total) * 100 : 0,
        bids: total > 0 ? (totalBids / total) * 100 : 0,
      };
    },
  },
};
