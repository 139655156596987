import { isNonZeroNumber, isNumber } from '@/margin/utils/index.ts';
import { OrderException } from '../api';
import { i18n } from '../i18n';
import { OrderSide } from '../types';

export const toTPSLReturn = (
  tpsl: number,
  price: number,
  size: number,
  side: OrderSide,
) => {
  if (!isNumber(tpsl) || !isNumber(price) || !isNumber(size)) {
    return null;
  }

  const delta = side === OrderSide.Buy ? 1 : -1;
  return (tpsl - price) * delta * size;
};

export const toTPSLRoeReturn = (
  roe: number,
  margin: number,
  isStopLoss: boolean,
) => {
  if (!isNumber(roe) || !isNumber(margin)) {
    return null;
  }

  const delta = isStopLoss ? -1 : 1;
  return margin * (roe / 100) * delta;
};

export const toTPSLPrice = (
  roe: number,
  price: number,
  margin: number,
  size: number,
  side: OrderSide,
  isStopLoss: boolean,
) => {
  if (!isNumber(roe) || !isNumber(price) || !isNonZeroNumber(size)) {
    return null;
  }

  const alpha = isStopLoss ? -1 : 1;
  const delta = side === OrderSide.Buy ? 1 : -1;

  const expectedReturn = margin * (roe / 100) * alpha;
  return price + (expectedReturn / size) * delta;
};

export const toTPSLRoe = (
  tpsl: number,
  price: number,
  margin: number,
  size: number,
  side: OrderSide,
  isStopLoss: boolean,
) => {
  if (
    !isNumber(tpsl) ||
    !isNumber(price) ||
    !isNumber(margin) ||
    !isNumber(size)
  ) {
    return null;
  }

  const alpha = isStopLoss ? -1 : 1;
  const delta = side === OrderSide.Buy ? 1 : -1;

  return (100 * (tpsl - price) * size) / (delta * margin * alpha);
};

export const canCalculateROE = (accountCurrency: string, pairQuote: string) => {
  const allowCurrency = ['USDT', 'USD'];

  return (
    (allowCurrency.includes(pairQuote) &&
      allowCurrency.includes(accountCurrency)) ||
    pairQuote === accountCurrency
  );
};

export const calculateTotal = (price: number, size: number) => {
  return (price ?? 0) * (size ?? 0);
};

export const calculateMarginRequirement = (
  price: number,
  size: number,
  leverage: number,
) => {
  const total = calculateTotal(price, size);

  return total / (leverage ?? 1);
};

export const getErrorMessage = (error: any, defaultMessage: string) => {
  const message = error?.response?.data?.detail;

  if (message === OrderException.BlockedAccount) {
    return i18n.global.t('marketOrder.blockedAccount');
  }

  if (message === OrderException.NotEnoughMoney) {
    return i18n.global.t('marketOrder.notEnoughMoney');
  }

  if (message === OrderException.InvalidCloseAmount) {
    return i18n.global.t('marketOrder.invalidCloseAmount');
  }

  return defaultMessage || i18n.global.t('marketOrder.failedPlaceOrder');
};
