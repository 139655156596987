import { OrderBook, OrderBookDTO, OrderBookLevel } from '@/margin/types';
import { Socket } from './socket';

const addTotalSums = (orders: number[][]) => {
  const totalSums: number[] = [];

  return orders.map((order, idx) => {
    const size = order[1];
    const updatedLevel = [...order];
    const totalSum = idx === 0 ? size : size + totalSums[idx - 1];
    updatedLevel[2] = totalSum;
    totalSums.push(totalSum);
    return updatedLevel;
  });
};

const addDepths = (orders: number[][], maxTotal: number) =>
  orders.map((order) => {
    const calculatedTotal = order[2];
    const depth = (calculatedTotal / maxTotal) * 100;
    const updatedOrder = [...order];
    updatedOrder[3] = depth;
    return updatedOrder;
  });

const getMaxTotalSum = (orders: number[][]) =>
  Math.max(...orders.map((order) => order[2]));

const roundToNearest = (value: number, interval: number) =>
  Math.floor(value / interval) * interval;

const groupLevels = (levels: number[][], grouping: number) => {
  return levels
    .map((level) => [roundToNearest(level[0], grouping), level[1]])
    .map((level, idx) => {
      const nextLevel = levels[idx + 1];
      const prevLevel = levels[idx - 1];

      if (nextLevel && level[0] === nextLevel[0]) {
        return [level[0], level[1] + nextLevel[1]];
      }

      if (prevLevel && level[0] === prevLevel[0]) {
        return [];
      }

      return level;
    })
    .filter((level) => level.length > 0);
};

export const toOrderBookLevels = (levels: number[][], grouping: number) => {
  const orders = addTotalSums(groupLevels(levels, grouping));
  const maxTotalOrders = getMaxTotalSum(orders);
  return addDepths(orders, maxTotalOrders).map(
    (level): OrderBookLevel => ({
      price: level[0],
      size: level[1],
      total: level[2],
      depth: level[3],
    }),
  );
};

export const calculateLastPrice = (bids: number[][], asks: number[][]) => {
  const prices = [...bids, ...asks].map((order) => order[0]);

  return prices.length
    ? prices.reduce((acc, price) => acc + price, 0) / prices.length
    : 0;
};

const OUTLIER_THRESHOLD_PERCENTAGE = 90;

export const filterOutliers = (level: number[], basePrice: number) => {
  if (!basePrice) return true;

  const onePercent = 100 / basePrice;
  return Math.abs(onePercent * level[0] - 100) <= OUTLIER_THRESHOLD_PERCENTAGE;
};

export type OrderBookCallback = (data: OrderBook) => void;

class OrderBookSocket {
  listeners: { [symbol: string]: OrderBookCallback[] } = {};
  sockets: { [symbol: string]: Socket<OrderBookDTO> } = {};

  on(symbol: string, callback: OrderBookCallback) {
    const listeners = this.listeners[symbol] ?? [];
    listeners.push(callback);

    this.listeners[symbol] = listeners;

    if (!this.sockets[symbol]) {
      this.connect(symbol);
    }
  }

  off(symbol: string, callback: OrderBookCallback) {
    this.listeners[symbol] = (this.listeners[symbol] ?? []).filter(
      (cb) => cb !== callback,
    );

    if (!this.listeners[symbol].length) {
      this.close(symbol);
    }
  }

  private close(symbol: string) {
    this.sockets[symbol]?.close();
    delete this.sockets[symbol];
  }

  private connect(symbol: string) {
    this.sockets[symbol]?.close();

    const socket = new Socket<OrderBookDTO>(import.meta.env.VITE_MARGIN_SOCKET);
    this.sockets[symbol] = socket;

    socket.onOpen(() => {
      socket.send({
        token: null,
        account_id: null,
        symbol,
      });
    });

    socket.onData((data) => {
      if (data.symbol === symbol) {
        const asks = data.asks?.map((ask) => [ask.price, ask.volume]) ?? [];
        const bids = data.bids?.map((bid) => [bid.price, bid.volume]) ?? [];

        this.listeners[symbol]?.forEach((cb) =>
          cb({
            symbol: data.symbol,
            bids: bids,
            asks: asks,
            lastPrice: calculateLastPrice(bids, asks),
          }),
        );
      }
    });
  }
}

export const orderBook = new OrderBookSocket();
