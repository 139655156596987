<script setup lang="ts">
import { Order, OrderSide, TableColumn } from '@/margin/types';
import KitTable from '../Kit/KitTable.vue';
import KitBadge from '../Kit/KitBadge.vue';
import IconEdit from '../Icon/IconEdit.vue';
import IconTrashBin from '../Icon/IconTrashBin.vue';
import OrdersFilters from '@/margin/components/Orders/OrdersFilters.vue';
import { computed, reactive, ref } from 'vue';
import { useStore } from '@/margin/store';
import KitCoin from '@/margin/components/Kit/KitCoin.vue';
import { toOrderTypeName } from '@/margin/utils/helpers.ts';
import DialogCloseAll from '@/margin/components/Dialog/DialogCloseAll.vue';
import DialogClose from '@/margin/components/Dialog/DialogClose.vue';
import DialogModifyOrder from '@/margin/components/Dialog/DialogModifyOrder.vue';
import { useI18n } from 'vue-i18n';
import { useNotification } from '@/margin/composeables/useNotification.ts';
import { getErrorMessage } from '@/margin/utils/orderForm';
import KitPrice from '../Kit/KitPrice.vue';

const store = useStore();

const { t } = useI18n();

const notification = useNotification();

const columns: TableColumn[] = [
  { field: 'id', header: t('orders.tableOrders.header.id'), sortable: true },
  {
    field: 'type',
    header: t('orders.tableOrders.header.type'),
    sortable: true,
    sortField: (row: Order) => row.side + row.type,
  },
  {
    field: 'symbol',
    header: t('orders.tableOrders.header.symbol'),
    sortable: true,
    sortField: 'pair.symbol',
  },
  {
    field: 'size',
    header: t('orders.tableOrders.header.size'),
    align: 'right',
    sortable: true,
  },
  {
    field: 'filledAmount',
    header: t('orders.tableOrders.header.filledAmount'),
    align: 'right',
    sortable: true,
  },
  {
    field: 'price',
    header: t('orders.tableOrders.header.price'),
    align: 'right',
    sortable: true,
  },
  {
    field: 'stopPrice',
    header: t('orders.tableOrders.header.stopPrice'),
    align: 'right',
    sortable: true,
  },
  {
    field: 'margin',
    header: t('orders.tableOrders.header.margin'),
    align: 'right',
    sortable: true,
  },
  {
    field: 'stopLoss',
    header: t('orders.tableOrders.header.sl'),
    align: 'right',
    sortable: true,
  },
  {
    field: 'takeProfit',
    header: t('orders.tableOrders.header.tp'),
    align: 'right',
    sortable: true,
  },
  {
    field: 'time',
    header: t('orders.tableOrders.header.time'),
    align: 'right',
    sortable: true,
  },
  {
    field: 'actions',
    header: t('orders.tableOrders.header.actions'),
    align: 'center',
    class: 'w-[60px]',
  },
];

const filters = reactive({
  buy: false,
  sell: false,
  sl: null,
  tp: null,
});

const closeAllLoading = ref(false);
const closeAllDialog = ref(false);

const closeLoading = ref(false);
const closeDialog = ref(false);

const editDialog = ref(false);
const editLoading = ref(false);

const selectedOrder = ref<Order | null>(null);

const orders = computed(() =>
  store.state.orders.orders.filter((order) => {
    if (filters.buy && !filters.sell && order.side !== OrderSide.Buy) {
      return false;
    }

    if (filters.sell && !filters.buy && order.side !== OrderSide.Sell) {
      return false;
    }

    // NOTE: sl and tp are nullable that's why we need to check for true or false directly
    if (filters.sl === true && !order.stopLoss) return false;
    if (filters.sl === false && order.stopLoss) return false;
    if (filters.tp === true && !order.takeProfit) return false;
    if (filters.tp === false && order.takeProfit) return false;

    return true;
  }),
);

const closeTitle = computed(
  () =>
    `${t('orders.tableOrders.closeTitle.question')} ${toOrderTypeName(selectedOrder.value?.type)} ${t('orders.tableOrders.closeTitle.order')} #${selectedOrder.value?.id}?`,
);

const closeAll = () => {
  closeAllLoading.value = true;
  store
    .dispatch('orders/closeAllOrders')
    .then(() => notification.showSuccess(t('dialog.allOrdersClose')))
    .catch((error) =>
      notification.showError(
        getErrorMessage(error, t('dialog.ordersUnableClose')),
      ),
    )
    .finally(() => {
      closeAllDialog.value = false;
      closeAllLoading.value = false;
    });
};

const close = (orderId: number) => {
  closeLoading.value = true;
  store
    .dispatch('orders/closeOrder', orderId)
    .then(() => notification.showSuccess(t('dialog.orderClose')))
    .catch((error) =>
      notification.showError(
        getErrorMessage(error, t('dialog.failedCloseOrder')),
      ),
    )
    .finally(() => {
      closeDialog.value = false;
      closeLoading.value = false;
      selectedOrder.value = null;
    });
};

const edit = (
  limitPrice: number,
  stopPrice: number,
  size: number,
  takeProfit: number,
  stopLoss: number,
) => {
  editLoading.value = true;
  store
    .dispatch('orders/editOrder', {
      id: selectedOrder.value.id,
      limitPrice: limitPrice,
      stopPrice: stopPrice,
      size: size,
      takeProfit: takeProfit,
      stopLoss: stopLoss,
    })
    .then(() => notification.showSuccess(t('dialog.orderEdited')))
    .catch((error) =>
      notification.showError(
        getErrorMessage(error, t('dialog.failedEditOrder')),
      ),
    )
    .finally(() => {
      editDialog.value = false;
      editLoading.value = false;
      selectedOrder.value = null;
    });
};

const openCloseDialog = (order: Order) => {
  closeDialog.value = true;
  selectedOrder.value = order;
};

const openEditDialog = (order: Order) => {
  editDialog.value = true;
  selectedOrder.value = order;
};
</script>

<template>
  <div class="flex h-full flex-col gap-4">
    <OrdersFilters
      v-model:buy="filters.buy"
      v-model:sell="filters.sell"
      v-model:sl="filters.sl"
      v-model:tp="filters.tp"
      :disabled-close-all="!orders.length"
      @close-all="closeAllDialog = true"
    />
    <KitTable
      :data="orders"
      :columns="columns"
      sort-field="id"
      :sort-order="-1"
      :loading="$store.state.orders.loading"
      :loading-rows="20"
      :rows="100"
      data-key="id"
      pagination
      auto-hide-pagination
      prevent-page-reset-on-data-change
      class="h-full overflow-auto"
    >
      <template #field-type="{ data }">
        <KitBadge
          :variant="data.side === OrderSide.Buy ? 'success' : 'danger'"
          >{{ toOrderTypeName(data.type, data.side) }}</KitBadge
        >
      </template>

      <template #field-symbol="{ data }">
        <KitCoin :pair="data.pair" />
      </template>

      <template #field-size="{ data }">
        {{ $num(data.size, 'auto') }}
        <span v-if="data.size" class="text-m-gray">{{
          data.pair.name.base
        }}</span>
      </template>

      <template #field-filledAmount="{ data }">
        {{ $num(data.filledAmount, 'auto') }}
        <span class="text-m-gray">{{ data.pair.name.base }}</span>
      </template>

      <template #field-price="{ data }">
        <KitPrice :value="data.price" :symbol="data.pair.symbol" />
        <span v-if="data.price" class="ml-1 text-m-gray">{{
          data.pair.name.quote
        }}</span>
      </template>

      <template #field-stopPrice="{ data }">
        <KitPrice :value="data.stopPrice" :symbol="data.pair.symbol" />
        <span v-if="data.stopPrice" class="ml-1 text-m-gray">{{
          data.pair.name.quote
        }}</span>
      </template>

      <template #field-margin="{ data }">
        {{ $num(data.margin, 'auto') }}
        <span v-if="data.margin" class="ml-1 text-m-gray">{{
          $store.state.auth.account?.currency
        }}</span>
      </template>

      <template #field-stopLoss="{ data }">
        <KitPrice :value="data.stopLoss" :symbol="data.pair.symbol" />
        <span v-if="data.stopLoss" class="ml-1 text-m-gray">{{
          data.pair.name.quote
        }}</span>
      </template>

      <template #field-takeProfit="{ data }">
        <KitPrice :value="data.takeProfit" :symbol="data.pair.symbol" />
        <span v-if="data.takeProfit" class="ml-1 text-m-gray">{{
          data.pair.name.quote
        }}</span>
      </template>

      <template #field-time="{ data }">
        {{ $date(data.time, true) }}
      </template>

      <template #field-actions="{ data }">
        <div
          class="flex h-full items-center justify-end gap-2 border-l border-m-gray-500 pl-2"
        >
          <IconEdit
            class="h-4 w-4 cursor-pointer"
            @click="openEditDialog(data)"
          />
          <IconTrashBin
            class="h-4 w-4 cursor-pointer"
            @click="openCloseDialog(data)"
          />
        </div>
      </template>
    </KitTable>

    <DialogCloseAll
      v-model="closeAllDialog"
      :loading="closeAllLoading"
      :title="$t('orders.tableOrders.dialogCloseAll')"
      @close-all="closeAll"
    />

    <DialogClose
      v-model="closeDialog"
      :loading="closeLoading"
      :title="closeTitle"
      :type="selectedOrder?.type"
      :side="selectedOrder?.side"
      :pair="selectedOrder?.pair"
      :size="selectedOrder?.size"
      @close="close(selectedOrder?.id)"
    />

    <DialogModifyOrder
      v-if="editDialog && selectedOrder"
      v-model="editDialog"
      :loading="editLoading"
      :selected-order="selectedOrder"
      @confirm="
        (limitPrice, stopPrice, size, takeProfit, stopLoss) =>
          edit(limitPrice, stopPrice, size, takeProfit, stopLoss)
      "
    />
  </div>
</template>
