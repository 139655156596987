import { captureException } from '@sentry/vue';
import { getFractions, isNumber, toJSON } from '.';
import { getSymbolInfo } from '../api';
import { SymbolInfo } from '../types';

export interface Precision {
  precision: number;
  sizePrecision: number;
}

const PRECISION_STORAGE_KEY = 'coin-precisions';

const precisions: { [currency: string]: Precision } =
  toJSON(localStorage.getItem(PRECISION_STORAGE_KEY)) || {};

const FALLBACK_PRECISION = 2;

export const symbolInfoToPrecision = (info: SymbolInfo): Precision => {
  return {
    precision: info.precision,
    sizePrecision:
      getFractions(info.tradeAmountStep ?? 0) || FALLBACK_PRECISION,
  };
};

export const cachePrecision = (symbol: string, precision: Precision) => {
  precisions[symbol] = precision;
  localStorage.setItem(PRECISION_STORAGE_KEY, JSON.stringify(precisions));
};

const getCachedPrecision = (currency: string) => {
  return precisions[currency];
};

export const getPairPrecision = async (symbol: string): Promise<Precision> => {
  const precision = getCachedPrecision(symbol);

  if (isNumber(precision)) return precision;

  try {
    const info = await getSymbolInfo(symbol);

    if (isNumber(info?.precision)) {
      const precision = symbolInfoToPrecision(info);

      cachePrecision(symbol, precision);

      return precision;
    }
  } catch (e) {
    captureException(e);
  }

  return { precision: FALLBACK_PRECISION, sizePrecision: FALLBACK_PRECISION };
};
