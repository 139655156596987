import { getSymbolNames } from '../api';

export interface Pair {
  base: string;
  quote: string;
  symbol: string;
  name: {
    symbol: string;
    base: string;
    quote: string;
  };
}

export interface BasePair {
  base: string;
  quote: string;
  symbol: string;
}

const SEPARATOR = '/';

const QUOTE_CURRENCIES = [
  'USD',
  'BTC',
  'ETH',
  'USDT',
  'BNB',
  'TUSD',
  'PAX',
  'USDC',
  'XRP',
  'USDS',
  'TRX',
  'BUSD',
  'NGN',
  'RUB',
  'TRY',
  'EUR',
  'ZAR',
  'BKRW',
  'IDRT',
  'GBP',
  'UAH',
  'BIDR',
  'AUD',
  'DAI',
  'BRL',
  'BVND',
  'VAI',
  'USDP',
  'DOGE',
  'UST',
  'DOT',
  'PLN',
  'RON',
  'ARS',
  'JPY',
];

export const symbolToPair = (symbol: string): BasePair => {
  if (!symbol) {
    return { base: null, quote: null, symbol };
  }

  if (symbol.includes(SEPARATOR)) {
    const [base, quote] = symbol.split(SEPARATOR);

    return {
      base: base ?? symbol,
      quote: quote ?? symbol,
      symbol,
    };
  }

  const pattern = new RegExp(`(\\w+)(${QUOTE_CURRENCIES.join('|')})$`);
  const data = pattern.exec(symbol);

  if ((data?.length ?? 0) < 3) {
    console.error('Invalid symbol', symbol);
    return { base: symbol, quote: symbol, symbol };
  }

  return { base: data[1], quote: data[2], symbol };
};

const createPair = (symbol: string, name: string): Pair => {
  const pair = symbolToPair(symbol);
  const namePair = symbolToPair(name ?? symbol);

  return {
    base: pair.base,
    quote: pair.quote,
    symbol: pair.symbol,
    name: {
      symbol: namePair.symbol,
      base: namePair.base,
      quote: namePair.quote,
    },
  };
};

export const toPairs = async (
  symbols: string[],
): Promise<{ [key: string]: Pair }> => {
  const names = await getSymbolNames();

  return Object.fromEntries(
    symbols?.map((symbol) => [symbol, createPair(symbol, names?.[symbol])]) ??
      [],
  );
};

export const toPair = async (symbol: string): Promise<Pair> => {
  return (await toPairs([symbol]))[symbol];
};
