<template>
  <svg
    width="12"
    height="12"
    viewBox="0 0 12 12"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M6 0.666626C6.20761 0.666626 6.39722 0.776273 6.48911 0.949468L8.04777 3.88716L11.5334 4.36115C11.7388 4.38908 11.9094 4.52305 11.9734 4.70675C12.0374 4.89045 11.9838 5.09204 11.8351 5.22678L9.31336 7.51185L9.9085 10.7401C9.9436 10.9304 9.85947 11.1229 9.69148 11.2364C9.52349 11.3499 9.30079 11.3649 9.11702 11.275L6 9.74996L2.88298 11.275C2.69921 11.3649 2.47651 11.3499 2.30852 11.2364C2.14053 11.1229 2.0564 10.9304 2.0915 10.7401L2.68663 7.51185L0.164888 5.22678C0.0161881 5.09204 -0.0374151 4.89045 0.0266089 4.70675C0.0906329 4.52305 0.261185 4.38908 0.466581 4.36115L3.95223 3.88716L5.51089 0.949468C5.60278 0.776273 5.79239 0.666626 6 0.666626ZM6 2.32054L4.80366 4.57535C4.72428 4.72497 4.5709 4.82872 4.39344 4.85285L1.71733 5.21676L3.65331 6.97104C3.78197 7.08762 3.8407 7.25566 3.81034 7.42034L3.35356 9.89805L5.74612 8.72748C5.90505 8.64972 6.09495 8.64972 6.25388 8.72748L8.64644 9.89805L8.18966 7.42034C8.1593 7.25566 8.21803 7.08762 8.34668 6.97104L10.2827 5.21676L7.60656 4.85285C7.4291 4.82872 7.27572 4.72497 7.19634 4.57535L6 2.32054Z"
      fill="currentColor"
    />
  </svg>
</template>
