<template>
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M5.21996 7.33333L7.60663 4.94L6.66663 4L2.66663 8L6.66663 12L7.60663 11.06L5.21996 8.66667H13.3333V7.33333H5.21996Z"
      fill="currentColor"
    />
  </svg>
</template>
