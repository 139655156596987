import { captureException } from '@sentry/vue';

export const isNumber = (value: unknown) => {
  return typeof value === 'number' && isFinite(value) && !isNaN(value);
};

export const isNonZeroNumber = (value: unknown) => {
  return isNumber(value) && value !== 0;
};

export const minmax = (value: number, min: number, max: number) => {
  return Math.max(Math.min(value, max), min);
};

export const percentage = (value: number, total: number) => {
  if (!total) return 0;

  return (value / total) * 100;
};

export const priceChangeSymbol = (price: number) => {
  if (price === 0) return '';

  return price > 0 ? '+' : '-';
};

export const getFractions = (number: number, minFractions = 1) => {
  if (number % 1 === 0 || !number) {
    return Math.max(0, minFractions);
  }

  // Handle case with scientific notation
  if (number.toString().includes('e')) {
    number = number + 1;
  }

  return Math.max(number.toString().split('.')[1]?.length ?? 0, minFractions);
};

export const fractionsToPlaceholder = (fractions: number) => {
  if (!isNumber(fractions)) return '';

  if (fractions <= 0) return '1';

  return '0.' + '0'.repeat(fractions - 1) + '1';
};

export const fractionsToStep = (fractions: number) => {
  const placeholder = fractionsToPlaceholder(fractions);

  if (!placeholder) return 1;

  const step = Number(placeholder);

  if (!isNumber(step)) return 1;

  return step;
};

export const toFixedFractions = (number: number, fractions: number) => {
  if (!isNumber(number)) return null;

  return Number(number.toFixed(fractions ?? 0));
};

export const getProgress = (
  from: number,
  to: number,
  value: number,
  outside = false,
) => {
  const max = to - from;
  const progress = max === 0 ? 0 : (value - from) / max;

  return outside ? progress : Math.max(Math.min(progress, 1), 0);
};

export const toJSON = <D = unknown>(json: string): D | null => {
  if (!json) return null;

  try {
    return JSON.parse(json);
  } catch (e) {
    captureException(e);
    return null;
  }
};

export const toSearchText = (text: string) => {
  return text.toLowerCase().trim();
};

export const wait = (ms: number) =>
  new Promise((resolve) => setTimeout(resolve, ms));

export const toChunks = <D>(data: D[], perChunk = 100) => {
  return data.reduce((chunks, items, index) => {
    const chunkIndex = Math.floor(index / perChunk);

    chunks[chunkIndex] = ([] as D[]).concat(chunks[chunkIndex] ?? [], items);

    return chunks;
  }, [] as D[][]);
};
