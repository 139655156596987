import { Plugin, defineAsyncComponent } from 'vue';
import { formatDate, formatNumber, i18n } from './i18n';
import PrimeVue from 'primevue/config';
import Lara from './presets/lara';
import Tooltip from 'primevue/tooltip';
import { MARGIN_STORE_INJECTION_KEY, store } from '@/margin/store';

export const createMargin = (): Plugin => {
  return {
    install(app) {
      app.use(store, MARGIN_STORE_INJECTION_KEY);
      app.use(i18n);
      app.use(PrimeVue, {
        unstyled: true,
        pt: Lara,
      });
      app.directive('tooltip', Tooltip);

      app.config.globalProperties.$num = formatNumber;
      app.config.globalProperties.$date = formatDate;

      app.component(
        'VueApexCharts',
        defineAsyncComponent(() => import('vue3-apexcharts')),
      );

      console.info(`%c Version ${VERSION} ${BUILD}`, 'color:#2196f3;');
    },
  };
};
