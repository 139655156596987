import { post } from '@/margin/api';
import { CoinInfo, CoinInfoDTO } from '@/margin/types';
import { EXPIRE_AFTER_HOUR } from '@/margin/utils/cache.ts';

export const specialSymbolMapping = {
  RENDER: 'RNDR', // TODO: Move it to the backend, this should not be handled by the frontend
};

export const getCoinInfo = async (symbol: string): Promise<CoinInfo> => {
  const data = await post<CoinInfoDTO>(
    '/coin/info/',
    {
      symbol: specialSymbolMapping[symbol] ?? symbol,
    },
    {
      retryCount: 1,
      cache: {
        dependencies: [symbol],
        expiration: EXPIRE_AFTER_HOUR,
      },
    },
  );

  return {
    symbol: data.symbol,
    name: data.name,
    rank: data.rank,
    marketCap: data.market_cap,
    marketShare: data.market_share,
    maxSupply: data.max_supply === -1 ? null : data.max_supply,
    totalSupply: data.total_supply === -1 ? null : data.total_supply,
    circulatingSupply:
      data.circulating_supply === -1 ? null : data.circulating_supply,
    listingDate: data.listing_date,
    website: data.website,
    whitepaper: data.whitepaper,
    description: data.description,
  };
};
