<template>
  <svg
    fill="none"
    height="24"
    viewBox="0 0 24 24"
    width="24"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path fill="currentColor" d="M12 14.5L17 9.5H7L12 14.5Z" />
  </svg>
</template>
