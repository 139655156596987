<template>
  <svg
    width="14"
    height="14"
    viewBox="0 0 14 14"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M11.0833 12.8333H2.91667C2.27233 12.8333 1.75 12.311 1.75 11.6667V3.49999C1.75 2.85566 2.27233 2.33332 2.91667 2.33332H4.08333V1.16666H5.25V2.33332H8.75V1.16666H9.91667V2.33332H11.0833C11.7277 2.33332 12.25 2.85566 12.25 3.49999V11.6667C12.25 12.311 11.7277 12.8333 11.0833 12.8333ZM2.91667 5.83332V11.6667H11.0833V5.83332H2.91667ZM2.91667 3.49999V4.66666H11.0833V3.49999H2.91667ZM9.91667 10.5H8.75V9.33332H9.91667V10.5ZM7.58333 10.5H6.41667V9.33332H7.58333V10.5ZM5.25 10.5H4.08333V9.33332H5.25V10.5ZM9.91667 8.16666H8.75V6.99999H9.91667V8.16666ZM7.58333 8.16666H6.41667V6.99999H7.58333V8.16666ZM5.25 8.16666H4.08333V6.99999H5.25V8.16666Z"
      fill="currentColor"
    />
  </svg>
</template>
