import { Module } from 'vuex';
import { StoreState } from '@/margin/store/index.ts';
import { BaseCurrencyPair, CurrencyPair } from '@/margin/types';
import { getPairDetail, getSymbolInfo } from '@/margin/api/currencyPair.ts';
import { toPair } from '@/margin/utils/trading.ts';
import { interval, removeInterval } from '@/margin/utils/interval.ts';
import { Interval, UPDATE_PAIR_INTERVAL } from '@/margin/const.ts';
import { toJSON } from '@/margin/utils';
import { getCoinInfo } from '@/margin/api';
import { captureException } from '@sentry/vue';

export const DEFAULT_PAIR_SYMBOL = 'BTCUSDT';
const USER_PAIRS_STORAGE_KEY = 'user-pairs';
const USER_SELECTED_PAIR_STORAGE_KEY = 'user-selected-pair';

const updateUserPairs = (pairs: CurrencyPair[]) => {
  localStorage.setItem(
    USER_PAIRS_STORAGE_KEY,
    JSON.stringify(
      pairs.map<BaseCurrencyPair>(({ symbol, accountId }) => ({
        symbol,
        accountId,
      })),
    ),
  );
};

const getUserPairs = () => {
  const userPairs = toJSON<BaseCurrencyPair[]>(
    localStorage.getItem(USER_PAIRS_STORAGE_KEY),
  );

  return userPairs?.length ? userPairs : [];
};

const setUserSelectedPair = (pair: BaseCurrencyPair) => {
  localStorage.setItem(USER_SELECTED_PAIR_STORAGE_KEY, JSON.stringify(pair));
};

export const getUserSelectedPair = () => {
  return toJSON<BaseCurrencyPair>(
    localStorage.getItem(USER_SELECTED_PAIR_STORAGE_KEY),
  );
};

export interface CurrencyPairState {
  pairs: CurrencyPair[];
  selected: BaseCurrencyPair;
}

export const arePairsSame = (
  current: BaseCurrencyPair,
  compareWith: BaseCurrencyPair,
) => {
  if (!current || !compareWith) return false;

  return (
    current?.symbol === compareWith?.symbol &&
    current?.accountId === compareWith?.accountId
  );
};

export const CurrencyPairModule: Module<CurrencyPairState, StoreState> = {
  namespaced: true,
  state: {
    pairs: [],
    selected: null,
  },
  mutations: {
    ADD_PAIR(state, pair: CurrencyPair) {
      state.pairs = [...state.pairs, pair];
    },
    SELECT_PAIR(state, selected: BaseCurrencyPair) {
      state.selected = selected;
    },
    REMOVE_PAIR(state, { symbol, accountId }: BaseCurrencyPair) {
      state.pairs = state.pairs.filter(
        (pair) => pair.symbol !== symbol || pair.accountId !== accountId,
      );
    },
    UPDATE_PAIR(
      state,
      {
        symbol,
        accountId,
        partial,
      }: { symbol: string; accountId: number; partial: Partial<CurrencyPair> },
    ) {
      state.pairs = state.pairs.map((pair) =>
        arePairsSame(pair, { symbol, accountId })
          ? { ...pair, ...partial }
          : pair,
      );
    },
  },
  actions: {
    initializePairs({ dispatch, state, commit }) {
      const accountId = this.state.auth.account?.id;
      const availableAccountIds = this.state.auth.accounts.map(({ id }) => id);

      // Remove pairs for accounts that are not available for current user
      const invalidPairs = state.pairs.filter(
        ({ accountId }) => !availableAccountIds.includes(accountId),
      );

      for (const pair of invalidPairs) {
        removeInterval(Interval.Pair + pair.symbol + pair.accountId);
        commit('REMOVE_PAIR', pair);

        if (arePairsSame(state.selected, pair)) {
          commit('SELECT_PAIR', null);
        }

        updateUserPairs(state.pairs);
      }

      if (state.pairs.length) {
        // Select pair in case no pair is selected after removing invalid pairs
        if (!state.selected) {
          dispatch('selectPair', state.pairs[0]);
        }
      } else {
        // Initialize pairs when no pairs are available

        const userPairs = getUserPairs().filter(({ accountId }) =>
          availableAccountIds.includes(accountId),
        );

        const pairs = userPairs.length
          ? userPairs
          : [{ symbol: DEFAULT_PAIR_SYMBOL, accountId }];

        for (const pair of pairs) {
          dispatch('addPair', pair);
        }

        if (!pairs.some((pair) => pair.accountId === accountId)) {
          const pair: BaseCurrencyPair = {
            symbol: DEFAULT_PAIR_SYMBOL,
            accountId,
          };

          dispatch('addPair', pair);
          dispatch('selectPair', pair);
        } else {
          const userSelectedPair = getUserSelectedPair();

          const selectedPair =
            userSelectedPair?.accountId === accountId &&
            pairs.some((pair) => arePairsSame(pair, userSelectedPair))
              ? userSelectedPair
              : pairs.find((pair) => pair.accountId === accountId) ?? pairs[0];

          if (selectedPair) {
            dispatch('selectPair', selectedPair);
          }
        }
      }
    },
    async addPair({ commit, state }, { symbol, accountId }: BaseCurrencyPair) {
      if (!symbol) return;

      const alreadyExists = state.pairs.some((pair) =>
        arePairsSame(pair, { symbol, accountId }),
      );

      if (alreadyExists) return;

      const pair = await toPair(symbol);
      commit('ADD_PAIR', { symbol, accountId, pair });

      updateUserPairs(state.pairs);

      const updateDetail = async () => {
        try {
        const detail = await getPairDetail(symbol, true);
        commit('UPDATE_PAIR', { symbol, accountId, partial: { detail } });
        } catch (e) {
          captureException(e)
        }
      };

      const updateCoin = async () => {
        try {
          const coin = await getCoinInfo(pair.base);
          commit('UPDATE_PAIR', { symbol, accountId, partial: { coin } });

        } catch (e) {
          captureException(e)
        }
      };

      await updateDetail();
      await updateCoin();
      interval(
        updateDetail,
        UPDATE_PAIR_INTERVAL,
        Interval.Pair + symbol + accountId,
      );

      const info = await getSymbolInfo(symbol);
      commit('UPDATE_PAIR', { symbol, accountId, partial: { info } });
    },
    removePair(
      { commit, state, dispatch },
      { symbol, accountId }: BaseCurrencyPair,
    ) {
      removeInterval(Interval.Pair + symbol + accountId);
      commit('REMOVE_PAIR', { symbol, accountId });

      updateUserPairs(state.pairs);

      if (arePairsSame(state.selected, { symbol, accountId })) {
        dispatch('selectPair', state.pairs[0]);
      }
    },
    selectPair({ commit }, { symbol, accountId }: BaseCurrencyPair) {
      commit('SELECT_PAIR', { symbol, accountId });

      setUserSelectedPair({ symbol, accountId });

      this.dispatch('auth/setAccount', accountId);
    },
  },

  getters: {
    currentPair(state) {
      return state.pairs.find((pair) => arePairsSame(state.selected, pair));
    },
  },
};
