import axios from 'axios';
import { cache, EXPIRE_AFTER_HOUR } from '@/margin/utils/cache.ts';
import { wait } from '../utils';

interface APIOptions {
  cache?: {
    dependencies: unknown[];
    expiration?: number;
    ignoreRetrievingCache?: boolean;
  };
  retryCount?: number;
}

const RETRY_DELAY = 2_000;

const apiBaseUrl = import.meta.env.VITE_MARGIN_API;

export const api = axios.create({
  baseURL: apiBaseUrl,
});

export const getToken = () => localStorage.getItem('token') ?? undefined;
export const setToken = (token: string) =>
  token
    ? localStorage.setItem('token', token)
    : localStorage.removeItem('token');

const postRequest = async <D>(
  url: string,
  data: unknown,
  options?: APIOptions,
) => {
  const cacheKey = options?.cache
    ? cache.generateKey(url, options?.cache?.dependencies)
    : null;

  if (cacheKey && !options?.cache?.ignoreRetrievingCache) {
    const cachedData = cache.get<D>(cacheKey);

    if (cachedData) {
      return cachedData;
    }
  }

  const response = await api.post<D>(url, data);

  if (cacheKey && response.data) {
    cache.set(cacheKey, response.data, {
      expiration: options?.cache?.expiration ?? EXPIRE_AFTER_HOUR,
    });
  }

  return response.data;
};

export const post = async <D>(
  url: string,
  data: unknown,
  options?: APIOptions,
): Promise<D> => {
  try {
    return await postRequest<D>(url, data, options);
  } catch (error) {
    if (options?.retryCount > 0) {
      await wait(RETRY_DELAY);

      return await post<D>(url, data, {
        ...(options ?? {}),
        retryCount: options.retryCount - 1,
      });
    }

    throw error;
  }
};

export * from './account';
export * from './coinfInfo';
export * from './currencyPair';
export * from './favorite';
export * from './orderBook';
export * from './orders';
export * from './tradingView';
