<template>
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M8 10.4754L12.0067 6.46872L11.0647 5.52539L8 8.59206L4.936 5.52539L3.99333 6.46806L8 10.4754Z"
      fill="currentColor"
    />
  </svg>
</template>
