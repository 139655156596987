<template>
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clip-path="url(#clip0_869_1718)">
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M10.0002 2.5C5.85803 2.5 2.50016 5.85786 2.50016 10C2.50016 14.1421 5.85803 17.5 10.0002 17.5C14.1423 17.5 17.5002 14.1421 17.5002 10C17.5002 5.85786 14.1423 2.5 10.0002 2.5ZM0.833496 10C0.833496 4.93739 4.93755 0.833332 10.0002 0.833332C15.0628 0.833332 19.1668 4.93739 19.1668 10C19.1668 15.0626 15.0628 19.1667 10.0002 19.1667C4.93755 19.1667 0.833496 15.0626 0.833496 10Z"
        fill="currentColor"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M10.0002 9.16666C10.4604 9.16666 10.8335 9.53976 10.8335 10V13.3333C10.8335 13.7936 10.4604 14.1667 10.0002 14.1667C9.53992 14.1667 9.16683 13.7936 9.16683 13.3333V10C9.16683 9.53976 9.53992 9.16666 10.0002 9.16666Z"
        fill="currentColor"
      />
      <path
        d="M10.8335 6.66667C10.8335 7.1269 10.4604 7.5 10.0002 7.5C9.53992 7.5 9.16683 7.1269 9.16683 6.66667C9.16683 6.20643 9.53992 5.83333 10.0002 5.83333C10.4604 5.83333 10.8335 6.20643 10.8335 6.66667Z"
        fill="currentColor"
      />
    </g>
    <defs>
      <clipPath id="clip0_869_1718">
        <rect width="20" height="20" fill="currentColor" />
      </clipPath>
    </defs>
  </svg>
</template>
