<script setup lang="ts">
import { ref, computed, onMounted, onUnmounted } from 'vue';
import IconArrowShort from '../Icon/IconArrowShort.vue';
import OrderBookList from '../OrderBook/OrderBookList.vue';
import OrderBookPercentageBar from '../OrderBook/OrderBookPercentageBar.vue';
import { useStore } from '@/margin/store';
import KitDropdownFilter from '../Kit/KitDropdownFilter.vue';
import { Option, OrderBookSortOptions, OrderBookType } from '@/margin/types';
import { CurrencyPair } from '@/margin/types';
import { useI18n } from 'vue-i18n';
import { formatNumber } from '@/margin/i18n';
import { watchDebounced } from '@vueuse/core';
import { GlobalEvents, useGlobalEvent } from '@/margin/composeables/useEvent';
import { isNumber } from '@/margin/utils';

const store = useStore();
const { t } = useI18n();
const globalEvent = useGlobalEvent();

const selectedSortOption = ref(OrderBookSortOptions.All);

const sortOptions: Option<OrderBookSortOptions>[] = [
  { value: OrderBookSortOptions.All, label: t('orderBook.filter.all') },
  { value: OrderBookSortOptions.Bids, label: t('orderBook.filter.buy') },
  { value: OrderBookSortOptions.Asks, label: t('orderBook.filter.sell') },
];

const groupingOptions = computed(() =>
  store.state.orderBook.groupingOptions.map<Option<number>>((value) => ({
    value,
    label: formatNumber(value),
  })),
);

const grouping = computed({
  get: () => store.state.orderBook.grouping,
  set: (grouping) => store.dispatch('orderBook/setGrouping', grouping),
});

const percentage = computed(() => store.getters['orderBook/percentage']);

watchDebounced(
  () => store.state.currencyPair.selected?.symbol,
  () => {
    store.dispatch('orderBook/loadOrders');
  },
  { debounce: 500 },
);

onMounted(() => {
  store.dispatch('orderBook/loadOrders');
});

onUnmounted(() => {
  store.dispatch('orderBook/closeOrders');
});

const selectedPair = computed<CurrencyPair>(
  () => store.getters['currencyPair/currentPair'],
);

const priceColor = computed(() => {
  if (store.state.orderBook.lastPrice > store.state.orderBook.previousPrice) {
    return 'text-m-success';
  }

  if (store.state.orderBook.lastPrice < store.state.orderBook.previousPrice) {
    return 'text-m-danger';
  }

  return 'text-gray-500';
});

const onPriceClick = (price: number) => {
  if (!isNumber(price)) return;

  globalEvent.emit(GlobalEvents.LimitPrice, price);
};
</script>

<template>
  <div class="flex h-full flex-col p-2">
    <div class="-mt-0.5 text-sm">{{ $t('orderBook.title') }}</div>
    <hr class="-mx-2 my-2 border-m-gray-600" />

    <div class="flex justify-between gap-1">
      <KitDropdownFilter
        v-model="selectedSortOption"
        :options="sortOptions"
        :label="$t('orderBook.sort')"
      />
      <KitDropdownFilter
        v-model="grouping"
        :options="groupingOptions"
        :label="$t('orderBook.grouping')"
        class="overflow-hidden"
        :title="$num(grouping)"
      />
    </div>

    <OrderBookPercentageBar
      :asks-percentage="percentage.asks"
      :bids-percentage="percentage.bids"
    />
    <OrderBookList
      v-if="selectedSortOption !== OrderBookSortOptions.Bids"
      class="h-full"
      :orders="$store.state.orderBook.asks"
      :pair="selectedPair?.pair"
      :reverse-orders="true"
      :type="OrderBookType.Asks"
      :precision="selectedPair?.info?.precision ?? 2"
    />
    <div
      class="my-2 flex items-center gap-1 text-xs last:gap-2"
      @click="onPriceClick($store.state.orderBook.lastPrice)"
    >
      <IconArrowShort
        :class="[
          priceColor,
          {
            'rotate-180':
              $store.state.orderBook.lastPrice <
              $store.state.orderBook.previousPrice,
          },
        ]"
      />
      <span :class="priceColor" class="font-semibold">
        {{
          $num($store.state.orderBook.lastPrice, {
            maximumFractionDigits: selectedPair?.info?.precision ?? 2,
            minimumFractionDigits: selectedPair?.info?.precision ?? 2,
          })
        }}
      </span>
      <span class="text-gray-500">
        {{
          $num($store.state.orderBook.previousPrice, {
            maximumFractionDigits: selectedPair?.info?.precision ?? 2,
            minimumFractionDigits: selectedPair?.info?.precision ?? 2,
          })
        }}
        {{ selectedPair?.pair?.name.quote }}
      </span>
    </div>
    <OrderBookList
      v-if="selectedSortOption !== OrderBookSortOptions.Asks"
      class="h-full"
      :orders="$store.state.orderBook.bids"
      :pair="selectedPair?.pair"
      :type="OrderBookType.Bids"
      :precision="selectedPair?.info?.precision ?? 2"
    />
  </div>
</template>
