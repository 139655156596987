<template>
  <svg
    fill="none"
    height="16"
    viewBox="0 0 16 16"
    width="16"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M12.1135 8.6665L10.3935 10.3932L11.3335 11.3332L14.6668 7.99984L11.3335 4.6665L10.3935 5.6065L12.1135 7.33317H1.3335V8.6665H12.1135Z"
      fill="currentColor"
    />
  </svg>
</template>
