import calendar from './calendar';
import datatable from './datatable';
import dialog from './dialog';
import dropdown from './dropdown';
import overlaypanel from './overlaypanel';
import sidebar from './sidebar';
import tooltip from './tooltip';

export default {
  directives: {
    tooltip,
  },
  dropdown,
  calendar,
  datatable,
  dialog,
  overlaypanel,
  sidebar,
};
