<template>
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M3.88683 7.33317L5.60683 5.6065L4.66683 4.6665L1.3335 7.99984L4.66683 11.3332L5.60683 10.3932L3.88683 8.6665H14.6668V7.33317H3.88683Z"
      fill="currentColor"
    />
  </svg>
</template>
