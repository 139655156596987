<script setup lang="ts">
import { OrderSide, Position, TableColumn } from '@/margin/types';
import KitTable from '../Kit/KitTable.vue';
import KitBadge from '../Kit/KitBadge.vue';
import IconEdit from '../Icon/IconEdit.vue';
import IconTrashBin from '../Icon/IconTrashBin.vue';
import OrdersFilters from '@/margin/components/Orders/OrdersFilters.vue';
import { computed, reactive, ref } from 'vue';
import { useStore } from '@/margin/store';
import KitCoin from '@/margin/components/Kit/KitCoin.vue';
import { toOrderTypeName } from '@/margin/utils/helpers.ts';
import DialogCloseAll from '@/margin/components/Dialog/DialogCloseAll.vue';
import DialogReverse from '@/margin/components/Dialog/DialogReverse.vue';
import DialogModifyPosition from '@/margin/components/Dialog/DialogModifyPosition.vue';
import { useI18n } from 'vue-i18n';
import { useNotification } from '@/margin/composeables/useNotification.ts';
import IconTransfer from '../Icon/IconTransfer.vue';
import KitChange from '../Kit/KitChange.vue';
import { getErrorMessage } from '@/margin/utils/orderForm';
import KitPrice from '../Kit/KitPrice.vue';

const store = useStore();

const { t } = useI18n();

const notification = useNotification();

const columns: TableColumn[] = [
  { field: 'id', header: t('orders.tableOrders.header.id'), sortable: true },
  {
    field: 'filledDate',
    header: t('orders.tableOrders.header.filledDate'),
    sortable: true,
  },
  {
    field: 'type',
    header: t('orders.tableOrders.header.type'),
    sortable: true,
    sortField: (row: Position) => row.side + row.type,
  },
  {
    field: 'symbol',
    header: t('orders.tableOrders.header.symbol'),
    sortable: true,
    sortField: 'pair.symbol',
  },
  {
    field: 'size',
    header: t('orders.tableOrders.header.size'),
    align: 'right',
    sortable: true,
  },
  {
    field: 'entryPrice',
    header: t('orders.tableOrders.header.entryPrice'),
    align: 'right',
    sortable: true,
  },
  {
    field: 'currentPrice',
    header: t('orders.tableOrders.header.currentPrice'),
    align: 'right',
    sortable: true,
  },
  {
    field: 'commission',
    header: t('orders.tableOrders.header.commission'),
    align: 'right',
    sortable: true,
  },
  {
    field: 'financingRate',
    header: t('orders.tableOrders.header.financingRate'),
    align: 'right',
    sortable: true,
  },
  {
    field: 'unrealizedPnL',
    header: t('orders.tableOrders.header.profit'),
    align: 'right',
    sortable: true,
  },
  {
    field: 'margin',
    header: t('orders.tableOrders.header.margin'),
    align: 'right',
    sortable: true,
  },
  {
    field: 'stopLoss',
    header: t('orders.tableOrders.header.sl'),
    align: 'right',
    sortable: true,
  },
  {
    field: 'takeProfit',
    header: t('orders.tableOrders.header.tp'),
    align: 'right',
    sortable: true,
  },
  {
    field: 'actions',
    header: t('orders.tableOrders.header.actions'),
    align: 'center',
    class: 'w-[60px]',
  },
];

const filters = reactive({
  buy: false,
  sell: false,
  sl: null,
  tp: null,
});

const closeAllLoading = ref(false);
const closeAllDialog = ref(false);

const reverseLoading = ref(false);
const reverseDialog = ref(false);

const editDialog = ref(false);
const editLoading = ref(false);
const isModify = ref(true);

const selectedPosition = ref<Position | null>(null);

const positions = computed(() =>
  store.state.positions.positions.filter((position) => {
    if (filters.buy && !filters.sell && position.side !== OrderSide.Buy) {
      return false;
    }

    if (filters.sell && !filters.buy && position.side !== OrderSide.Sell) {
      return false;
    }

    // NOTE: sl and tp are nullable that's why we need to check for true or false directly
    if (filters.sl === true && !position.stopLoss) return false;
    if (filters.sl === false && position.stopLoss) return false;
    if (filters.tp === true && !position.takeProfit) return false;
    if (filters.tp === false && position.takeProfit) return false;

    return true;
  }),
);

const closeAll = () => {
  closeAllLoading.value = true;
  store
    .dispatch('positions/closeAllPositions')
    .then(() => notification.showSuccess(t('dialog.allPositionsClose')))
    .catch((error) =>
      notification.showError(
        getErrorMessage(error, t('dialog.positionsUnableClose')),
      ),
    )
    .finally(() => {
      closeAllDialog.value = false;
      closeAllLoading.value = false;
    });
};

const close = (orderId: number, size?: number) => {
  editLoading.value = true;
  store
    .dispatch('positions/closePosition', { orderId, size })
    .then(() => notification.showSuccess(t('dialog.positionClose')))
    .catch((error) =>
      notification.showError(
        getErrorMessage(error, t('dialog.failedClosePosition')),
      ),
    )
    .finally(() => {
      editDialog.value = false;
      editLoading.value = false;
      selectedPosition.value = null;
    });
};

const reverse = (positionId: number) => {
  reverseLoading.value = true;
  store
    .dispatch('positions/reversePosition', positionId)
    .then(() => notification.showSuccess(t('dialog.positionReversed')))
    .catch((error) =>
      notification.showError(
        getErrorMessage(error, t('dialog.failedPositionReverse')),
      ),
    )
    .finally(() => {
      reverseDialog.value = false;
      reverseLoading.value = false;
      selectedPosition.value = null;
    });
};

const reverseTitle = computed(
  () => `${t('dialog.positionReverse')} #${selectedPosition.value?.id}?`,
);

const edit = (takeProfit: number, stopLoss: number) => {
  editLoading.value = true;
  store
    .dispatch('positions/editPosition', {
      id: selectedPosition.value.id,
      takeProfit: takeProfit,
      stopLoss: stopLoss,
    })
    .then(() => notification.showSuccess(t('dialog.positionEdited')))
    .catch((error) =>
      notification.showError(
        getErrorMessage(error, t('dialog.failedEditPosition')),
      ),
    )
    .finally(() => {
      editDialog.value = false;
      editLoading.value = false;
      selectedPosition.value = null;
    });
};

const openCloseDialog = (position: Position) => {
  isModify.value = false;
  editDialog.value = true;
  selectedPosition.value = position;
};

const openEditDialog = (position: Position) => {
  isModify.value = true;
  editDialog.value = true;
  selectedPosition.value = position;
};

const openReverseDialog = (position: Position) => {
  reverseDialog.value = true;
  selectedPosition.value = position;
};
</script>

<template>
  <div class="flex h-full flex-col gap-4">
    <OrdersFilters
      v-model:buy="filters.buy"
      v-model:sell="filters.sell"
      v-model:sl="filters.sl"
      v-model:tp="filters.tp"
      :disabled-close-all="!positions.length"
      @close-all="closeAllDialog = true"
    />
    <KitTable
      :data="positions"
      :columns="columns"
      sort-field="id"
      :sort-order="-1"
      :loading="$store.state.orders.loading"
      :loading-rows="20"
      :rows="100"
      data-key="id"
      pagination
      auto-hide-pagination
      prevent-page-reset-on-data-change
      class="h-full overflow-auto"
    >
      <template #field-filledDate="{ data }">
        {{ $date(data.filledDate, true) }}
      </template>

      <template #field-type="{ data }">
        <KitBadge
          :variant="data.side === OrderSide.Buy ? 'success' : 'danger'"
          >{{ toOrderTypeName(data.type, data.side) }}</KitBadge
        >
      </template>

      <template #field-symbol="{ data }">
        <KitCoin :pair="data.pair" />
      </template>

      <template #field-size="{ data }">
        {{ $num(data.size, data.sizePrecision) }}
        <span v-if="data.size" class="text-m-gray">{{
          data.pair.name.base
        }}</span>
      </template>

      <template #field-entryPrice="{ data }">
        <KitPrice :value="data.entryPrice" :symbol="data.pair.symbol" />
        <span v-if="data.entryPrice" class="ml-1 text-m-gray">{{
          data.pair.name.quote
        }}</span>
      </template>

      <template #field-currentPrice="{ data }">
        <KitPrice :value="data.currentPrice" :symbol="data.pair.symbol" />
        <span v-if="data.currentPrice" class="ml-1 text-m-gray">{{
          data.pair.name.quote
        }}</span>
      </template>

      <template #field-commission="{ data }">
        {{
          $num(
            data.commission,
            $store.state.auth.account?.isFiatOrStable ? 4 : 8,
          )
        }}
        <span v-if="data.commission" class="ml-1 text-m-gray">{{
          $store.state.auth.account?.currency
        }}</span>
      </template>

      <template #field-financingRate="{ data }">
        {{ $num(data.swap, $store.state.auth.account?.isFiatOrStable ? 4 : 8) }}
        <span v-if="data.swap" class="ml-1 text-m-gray">{{
          $store.state.auth.account?.currency
        }}</span>
      </template>

      <template #field-unrealizedPnL="{ data }">
        <KitChange
          v-if="data.unrealizedPnL !== 0"
          :number-style="$store.state.auth.account?.isFiatOrStable ? 4 : 8"
          hide-plus
          :value="data.unrealizedPnL"
          :suffix="` (${$num(data.profitROE, 'decimal')}%)`"
        />
        <span v-else>{{ data.unrealizedPnL }}</span>
      </template>

      <template #field-margin="{ data }">
        {{ $num(data.margin, 'auto') }}
        <span v-if="data.margin" class="ml-1 text-m-gray">{{
          $store.state.auth.account?.currency
        }}</span>
      </template>

      <template #field-stopLoss="{ data }">
        <KitPrice :value="data.stopLoss" :symbol="data.pair.symbol" />
        <span v-if="data.stopLoss" class="ml-1 text-m-gray">{{
          data.pair.name.quote
        }}</span>
      </template>

      <template #field-takeProfit="{ data }">
        <KitPrice :value="data.takeProfit" :symbol="data.pair.symbol" />
        <span v-if="data.takeProfit" class="ml-1 text-m-gray">{{
          data.pair.name.quote
        }}</span>
      </template>

      <template #field-actions="{ data }">
        <div
          class="flex h-full items-center justify-end gap-2 border-l border-m-gray-500 pl-2"
        >
          <IconTransfer
            :gradient="false"
            class="h-4 w-4 rotate-90 cursor-pointer"
            @click="openReverseDialog(data)"
          />
          <IconEdit
            class="h-4 w-4 cursor-pointer"
            @click="openEditDialog(data)"
          />
          <IconTrashBin
            class="h-4 w-4 cursor-pointer"
            @click="openCloseDialog(data)"
          />
        </div>
      </template>
    </KitTable>

    <DialogCloseAll
      v-model="closeAllDialog"
      :loading="closeAllLoading"
      :title="$t('orders.tablePosition.dialogCloseAll')"
      @close-all="closeAll"
    />

    <DialogReverse
      v-if="reverseDialog && selectedPosition"
      v-model="reverseDialog"
      :loading="reverseLoading"
      :title="reverseTitle"
      :type="selectedPosition?.type"
      :side="selectedPosition?.side"
      :pair="selectedPosition?.pair"
      :size="selectedPosition?.size"
      @reverse="reverse(selectedPosition?.id)"
    />

    <DialogModifyPosition
      v-if="editDialog && selectedPosition"
      v-model="editDialog"
      :loading="editLoading"
      :selected-position="selectedPosition"
      :is-modify="isModify"
      @edit="(takeProfit, stopLoss) => edit(takeProfit, stopLoss)"
      @close="close(selectedPosition.id, $event)"
    />
  </div>
</template>
