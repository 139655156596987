import { ref } from 'vue';
import { useStore } from '@/margin/store';

export const useOrderFormDialog = (onConfirmed: () => Promise<unknown>) => {
  const showConfirmation = ref(false);
  const store = useStore();

  const requireConfirmation = async () => {
    if (store.state.settings.showConfirmAction) {
      showConfirmation.value = true;
    } else {
      await onConfirmed();
    }
  };

  const processOrder = async (dontShowConfirmationAction?: boolean) => {
    if (dontShowConfirmationAction) {
      await store.dispatch('settings/setShowConfirmationAction', false);
    }

    await onConfirmed();

    showConfirmation.value = false;
  };

  return {
    showConfirmation,
    processOrder,
    requireConfirmation,
  };
};
